module.exports = {
    createEmptyPromise: function () {
        return new Promise(function (resolve) {
            resolve();
        });
    },

    // Function to validate emails
    validateEmail: function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(email.toLowerCase());
    },

    isElementInViewport: function (el, threshold) {
        if (typeof jQuery === 'function' && el instanceof jQuery) {
            el = el[0];
        }

        threshold = threshold || 0;

        var rect = el.getBoundingClientRect();

        return (
            rect.bottom + threshold > 0 &&
            rect.right > 0 &&
            rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
            rect.top - threshold < (window.innerHeight || document.documentElement.clientHeight)
        );
    },

    loadScript: function (url) {
        return new Promise(function (resolve, reject) {
            var script = document.createElement('script');
            script.onload = resolve;
            script.onerror = reject;
            script.src = url;
            document.getElementsByTagName('body')[0].appendChild(script);
        });
    }
};
