var Backbone = require('backbone');

var Page = require('front/pages/Page/Page');
var TopNav = require('front/components/TopNav/TopNav');
var Slider = require('front/components/Slider/Slider');
var Notify = require('front/components/Notify/Notify');
require('front/components/PostItem/PostItem');
require('front/components/ViewCount/ViewCount');

require('./Index.less');

module.exports = Page.extend({
    template: require('./Index.jinja'),

    el: '.Index',

    // apiUrl: '/api/index/',

    title:  '',

    initialize: function (options) {
        this.options = options || {};
        this.data = this.options.data;
        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        this.indexNav = new TopNav({
            type: 'index'
        });

        this.floatNav = new TopNav({
            el: $('.TopNav--floatNav'),
            type: 'floatNav'
        });

        this.slider = new Slider({
            el: this.$('.Slider')
        });

        this.notify = new Notify({
            el: $('.Notify')
        });

        this.setTitle();

        $('.Index-indexNav').append(this.indexNav.render().el);
        this.indexNav.activate();
        this.floatNav.activate();
        this.slider.activate();

        return Page.prototype.activate.call(this, params);
    },

    render: function () {
        this.setElement(
            this.template.render({
                slides: window.app.data.indexData.slides,
                postsPrimary: window.app.data.indexData.postsPrimary,
                postsSecondary: window.app.data.indexData.postsSecondary,
                info: window.app.data.indexData.info,
                notify: window.app.data.indexData.notify,
                LANGUAGE_CODE: window.app.data.lang
            })
        );

        return this;
    }
});
