function getScrollAnchor(params) {
    var elemSize = params.el.getBoundingClientRect();
    var scrollTop = (document.scrollingElement || document.documentElement).scrollTop;
    var viewportHeight = window.innerHeight || window.outerHeight;

    var y = 0;

    if (params.viewportAnchor === 'top') y -= 0;
    if (params.viewportAnchor === 'middle') y -= viewportHeight / 2;
    if (params.viewportAnchor === 'bottom') y -= viewportHeight;

    if (params.elemAnchor === 'top') y += (elemSize.top + scrollTop);
    if (params.elemAnchor === 'middle') y += (elemSize.top + scrollTop) + elemSize.height / 2;
    if (params.elemAnchor === 'bottom') y += (elemSize.top + scrollTop) + elemSize.height;

    if (params.shift) {
        y += params.shift;
    }

    return y;
}

module.exports = getScrollAnchor;
