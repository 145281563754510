var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/Slider/SliderVariantCaption.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["slide"], 
[], 
function (l_slide, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("slide", l_slide);
var t_2 = "";t_2 += "\n<div class=\"Slider-captionValue\">\n    <div class=\"Slider-captionTitle\">";
if(runtime.memberLookup((l_slide),"title") != "None") {
t_2 += runtime.suppressValue(runtime.memberLookup((l_slide),"title"), env.opts.autoescape);
;
}
t_2 += "</div>\n</div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("SliderVariantCaption");
context.setVariable("SliderVariantCaption", macro_t_1);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n";
output += runtime.suppressValue((lineno = 7, colno = 23, runtime.callWrap(macro_t_1, "SliderVariantCaption", context, [runtime.makeKeywordArgs({"slide": runtime.contextOrFrameLookup(context, frame, "slide")})])), env.opts.autoescape);
output += "\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/Slider/SliderVariantCaption.jinja"] , dependencies)