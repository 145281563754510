var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/TopNav/TopNav.jinja"] = require( "front/components/TopNav/TopNav.jinja" );
dependencies["front/components/CareerVacancyApply/CareerVacancyApplyStep1.jinja"] = require( "front/components/CareerVacancyApply/CareerVacancyApplyStep1.jinja" );
dependencies["front/components/CareerVacancyApply/CareerVacancyApplyStep2.jinja"] = require( "front/components/CareerVacancyApply/CareerVacancyApplyStep2.jinja" );
dependencies["front/components/CareerVacancyApply/CareerVacancyApplyStep3.jinja"] = require( "front/components/CareerVacancyApply/CareerVacancyApplyStep3.jinja" );
dependencies["front/components/CareerVacancyApply/CareerVacancyApplyStep4.jinja"] = require( "front/components/CareerVacancyApply/CareerVacancyApplyStep4.jinja" );
dependencies["front/components/CareerVacancyApply/CareerVacancyApplyStep5.jinja"] = require( "front/components/CareerVacancyApply/CareerVacancyApplyStep5.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/CareerVacancyApply/CareerVacancyApply.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "svgSprite")) {
var t_10 = t_7.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_10);
output += "\n";
env.getTemplate("front/components/TopNav/TopNav.jinja", false, "assets/app/front/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(Object.prototype.hasOwnProperty.call(t_11, "TopNav")) {
var t_14 = t_11.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_14);
output += "\n\n";
env.getTemplate("front/components/CareerVacancyApply/CareerVacancyApplyStep1.jinja", false, "assets/app/front/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
t_15.getExported(function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
if(Object.prototype.hasOwnProperty.call(t_15, "CareerVacancyApplyStep1")) {
var t_18 = t_15.CareerVacancyApplyStep1;
} else {
cb(new Error("cannot import 'CareerVacancyApplyStep1'")); return;
}
context.setVariable("CareerVacancyApplyStep1", t_18);
output += "\n";
env.getTemplate("front/components/CareerVacancyApply/CareerVacancyApplyStep2.jinja", false, "assets/app/front/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
t_19.getExported(function(t_21,t_19) {
if(t_21) { cb(t_21); return; }
if(Object.prototype.hasOwnProperty.call(t_19, "CareerVacancyApplyStep2")) {
var t_22 = t_19.CareerVacancyApplyStep2;
} else {
cb(new Error("cannot import 'CareerVacancyApplyStep2'")); return;
}
context.setVariable("CareerVacancyApplyStep2", t_22);
output += "\n";
env.getTemplate("front/components/CareerVacancyApply/CareerVacancyApplyStep3.jinja", false, "assets/app/front/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
t_23.getExported(function(t_25,t_23) {
if(t_25) { cb(t_25); return; }
if(Object.prototype.hasOwnProperty.call(t_23, "CareerVacancyApplyStep3")) {
var t_26 = t_23.CareerVacancyApplyStep3;
} else {
cb(new Error("cannot import 'CareerVacancyApplyStep3'")); return;
}
context.setVariable("CareerVacancyApplyStep3", t_26);
output += "\n";
env.getTemplate("front/components/CareerVacancyApply/CareerVacancyApplyStep4.jinja", false, "assets/app/front/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_28,t_27) {
if(t_28) { cb(t_28); return; }
t_27.getExported(function(t_29,t_27) {
if(t_29) { cb(t_29); return; }
if(Object.prototype.hasOwnProperty.call(t_27, "CareerVacancyApplyStep4")) {
var t_30 = t_27.CareerVacancyApplyStep4;
} else {
cb(new Error("cannot import 'CareerVacancyApplyStep4'")); return;
}
context.setVariable("CareerVacancyApplyStep4", t_30);
output += "\n";
env.getTemplate("front/components/CareerVacancyApply/CareerVacancyApplyStep5.jinja", false, "assets/app/front/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_32,t_31) {
if(t_32) { cb(t_32); return; }
t_31.getExported(function(t_33,t_31) {
if(t_33) { cb(t_33); return; }
if(Object.prototype.hasOwnProperty.call(t_31, "CareerVacancyApplyStep5")) {
var t_34 = t_31.CareerVacancyApplyStep5;
} else {
cb(new Error("cannot import 'CareerVacancyApplyStep5'")); return;
}
context.setVariable("CareerVacancyApplyStep5", t_34);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_36,t_35) {
if(t_36) { cb(t_36); return; }
output += t_35;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_38,t_37) {
if(t_38) { cb(t_38); return; }
output += t_37;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_40,t_39) {
if(t_40) { cb(t_40); return; }
output += t_39;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_42,t_41) {
if(t_42) { cb(t_42); return; }
output += t_41;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 11, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 12;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 12, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 14;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n";
output += runtime.suppressValue((lineno = 15, colno = 9, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--career TopNav--fixed"})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 18;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"Career\">\n    <div class=\"Career-floatNav\"></div>\n    <div class=\"Career-main\">\n        <div class=\"CareerVacancyApply\">\n            <div class=\"CareerVacancyApply-header\">\n                <div class=\"Career-subtitle\">\n                    <span class=\"Career-subtitleStroke\"></span>\n                    <div class=\"Career-subtitleText\">";
output += runtime.suppressValue((lineno = 26, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
output += "</div>\n                </div>\n                <h1 class=\"Career-title\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vacancy")),"name"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vacancy")),"city")),"text"), env.opts.autoescape);
output += "\n                </h1>\n            </div>\n            <div class=\"CareerVacancyApplyFormWrapper\">\n                <ol class=\"CareerVacancyApply-stepsNav\">\n                    <li class=\"isCurrentStep\">\n                        <span class=\"CareerVacancyApply-stepsNavItem\">Основные <br>данные</span>\n                    </li>\n                    <li>\n                        <span class=\"CareerVacancyApply-stepsNavItem\">Образование</span>\n                    </li>\n                    <li>\n                        <span class=\"CareerVacancyApply-stepsNavItem\">Опыт работы</span>\n                    </li>\n                    <li>\n                        <span class=\"CareerVacancyApply-stepsNavItem\">Ключевые навыки</span>\n                    </li>\n                    <li>\n                        <span class=\"CareerVacancyApply-stepsNavItem\">Сопроводительное <br>письмо</span>\n                    </li>\n                </ol>\n                <form class=\"CareerVacancyApplyForm CareerResumeForm\" action=\"/api/career-vacancy-apply/\" method=\"post\">\n                    <input type=\"hidden\" name=\"vacancy-id\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vacancy")),"id"), env.opts.autoescape);
output += "\">\n                    <div class=\"CareerVacancyApplyForm-step isCurrentStep\">\n                        ";
output += runtime.suppressValue((lineno = 53, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerVacancyApplyStep1"), "CareerVacancyApplyStep1", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_43 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_44 = "";;
frame = frame.pop();
return new runtime.SafeString(t_44);
});
return macro_t_43;})()})])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"CareerVacancyApplyForm-step\">\n                        ";
output += runtime.suppressValue((lineno = 56, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerVacancyApplyStep2"), "CareerVacancyApplyStep2", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_45 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_46 = "";;
frame = frame.pop();
return new runtime.SafeString(t_46);
});
return macro_t_45;})()})])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"CareerVacancyApplyForm-step\">\n                        ";
output += runtime.suppressValue((lineno = 59, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerVacancyApplyStep3"), "CareerVacancyApplyStep3", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_47 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_48 = "";;
frame = frame.pop();
return new runtime.SafeString(t_48);
});
return macro_t_47;})()})])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"CareerVacancyApplyForm-step\">\n                        ";
output += runtime.suppressValue((lineno = 62, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerVacancyApplyStep4"), "CareerVacancyApplyStep4", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_49 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_50 = "";;
frame = frame.pop();
return new runtime.SafeString(t_50);
});
return macro_t_49;})()})])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"CareerVacancyApplyForm-step\">\n                        ";
output += runtime.suppressValue((lineno = 65, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerVacancyApplyStep5"), "CareerVacancyApplyStep5", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_51 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_52 = "";;
frame = frame.pop();
return new runtime.SafeString(t_52);
});
return macro_t_51;})()})])), env.opts.autoescape);
output += "\n                    </div>\n                </form>\n            </div>\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/CareerVacancyApply/CareerVacancyApply.jinja"] , dependencies)