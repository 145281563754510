var Backbone = require('backbone');
var Subscribe = require('front/components/Subscribe/Subscribe');
require('./Footer.less');

module.exports = Backbone.View.extend({
    template: require('./Footer.jinja'),

    el: '.Footer',

    initialize: function () {
        var subscribe = new Subscribe({
            el: this.$('.Subscribe')
        });
    }
});
