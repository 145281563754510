var Backbone = require('backbone');

require('./Share.less');

module.exports = Backbone.View.extend({
    template: require('./Share.jinja'),

    el: '.Share',

    events: {
        'click .Share-button': 'onShowSocial',
        'click .Share-close': 'onCloseSocial',

        'click .Share-item--facebook': 'onShareFacebookClick',
        'click .Share-item--twitter': 'onShareTwitterClick',
        'click .Share-item--telegram': 'onShareTelegramClick'
    },

    initialize: function (options) {
        this.data = options.data;
    },

    updateView: function (data) {
        this.data = data;
    },

    onShowSocial: function (e) {
        e.preventDefault();

        this.$el.addClass('isActive');
    },

    onCloseSocial: function (e) {
        e.preventDefault();

        this.$el.removeClass('isActive');
    },

    getShareData: function () {
        var title = this.data.title || document.title;
        var link = this.data.link || window.location.href;

        var data = {
            title: title,
            link: (link[0] === '/' ? window.location.origin : '') + link
        };

        return data;
    },

    onShareFacebookClick: function () {
        this.shareFacebook(this.getShareData());
    },

    onShareTwitterClick: function () {
        this.shareTwitter(this.getShareData());
    },

    onShareTelegramClick: function () {
        this.shareTelegram(this.getShareData());
    },

    shareFacebook: function (data) {
        window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(data.link),
            'facebooksharedialog',
            'width=626,height=436'
        );
    },

    shareTwitter: function (data) {
        var tweetUrl =
            'https://twitter.com/share' +
            '?url=' +
            encodeURIComponent(data.link) +
            '&text=' +
            encodeURIComponent(data.title);

        window.open(tweetUrl, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
    },

    shareTelegram: function (data) {
        var telegramUrl =
            'https://t.me/share/url' +
            '?url=' +
            encodeURIComponent(data.link) +
            '&text=' +
            encodeURIComponent(data.title);

        window.open(telegramUrl, 'width=626,height=436');
    }
});
