var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');

var CareerResumeForm = require('front/components/CareerResumeForm/CareerResumeForm');
var CareerVacancyCard = require('front/components/CareerVacancyCard/CareerVacancyCard');

var CommonForm = require('front/components/CommonNewForm/CommonNewForm');

require('./CareerCategory.less');

module.exports = Page.extend({
    template: require('./CareerCategory.jinja'),

    el: '.Career',

    events: {},

    initialize: function (options) {
        this.form = new CareerResumeForm();
        this.vacancy = new CareerVacancyCard();

        this.$filtersForm = $('.js-filters');
        this.filters = new CommonForm(this.$filtersForm);

        this.$filtersForm.on('change', 'select', this.filterVacancies.bind(this));

        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        if (window.location.search) {
            const queryParams = new URLSearchParams(window.location.search);

            for (let [k, v] of queryParams.entries()) {
                let $select = this.$filtersForm.find('select[name="' + k + '"]');
                let $selectOption = $select.find('option[value="' + v + '"]');

                $select.val(v);
                $select.parent().find('.CommonNewForm-customSelectValue').html($selectOption.text());
            }

            this.filterVacancies();
        }

        return Page.prototype.activate.call(this, params);
    },

    filterVacancies: function () {
        const $form = this.$filtersForm;

        const queryParams = new URLSearchParams(new FormData($form[0]));

        const bodyDataRaw = {
            area: [queryParams.get('area')].filter(Boolean),
            location: [queryParams.get('location')].filter(Boolean),
            experience: [queryParams.get('experience')].filter(Boolean)
        };

        const bodyData = Object.fromEntries(Object.entries(bodyDataRaw).filter(([_, v]) => v.length > 0));

        const newUrlParams = new URLSearchParams(bodyData);
        const queryString = newUrlParams.toString();
        const newUrl = `${window.location.pathname}${queryString ? '?' + queryString : ''}`;
        history.pushState({}, '', newUrl);

        $.ajax({
            cache: false,
            contentType: 'application/json',
            processData: false,
            data: JSON.stringify(bodyData),
            type: $form.attr('method'),
            url: $form.attr('action'),
            error: function (err) {
                const errorResponse = err.responseJSON;

                $('#CareerVacanciesList').html(
                    '<p class="Career-sectionText">' +
                        '<b>К сожалению, нам не удалось найти вакансии подходящие под выбранные критерии.</b><br>' +
                        'Попробуйте повторить поиск, используя другие фильтры подбора' +
                    '</p>'
                    // '<p class="Career-sectionText">' + errorResponse.message + '</p>'
                );
            },
            success: function (response) {
                const template = [];

                for (const vacancy of response) {
                    template.push(
                        '<div class="CareerVacancyCard">' +
                            '<a class="CareerVacancyCard-link" href="/career/vacancy/' + vacancy.id + '/">' +
                                '<h3 class="CareerVacancyCard-title">' + vacancy.name + '</h3>' +
                                '<div class="CareerVacancyCard-info">' +
                                    '<div class="CareerVacancyCard-description">Опыт: ' + vacancy.experience + '' + (vacancy.city ? ', Город: ' + vacancy.city : '') + '</div>' +
                                    '<div class="CareerVacancyCard-linkMore">' +
                                        '<span class="CareerVacancyCard-linkMoreText">Подробнее</span>' +
                                        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 12" class="CareerVacancyCard-linkMoreIcon" width="19" height="12"><path fill="#202020" d="m19 6.001-5.045 5.043-1.414-1.414L15.17 7H.57V5h14.6l-2.63-2.63L13.955.956 19 6.001Z"/></svg>' +
                                    '</div>' +
                                '</div>' +
                            '</a>' +
                        '</div>'
                    );
                }

                $('#CareerVacanciesList').html(template.join(''));
            }
        });
    }
});
