var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');

var CommonForm = require('front/components/CommonNewForm/CommonNewForm');

require('front/components/CareerResumeForm/CareerResumeForm.less');
require('./CareerVacancyApply.less');

module.exports = Page.extend({
    template: require('./CareerVacancyApply.jinja'),

    el: '.CareerVacancyApply',

    events: {
        'submit .CareerVacancyApplyForm': 'submitForm',
        'click .js-careerVacancyChangeStep': 'changeStep',

        'click .js-addEducationFields': 'addEducationFields',
        'click .js-addWorkFields': 'addWorkFields',
        'change .js-careerVacancyApplyFormNoWorkExperience': 'changeWorkExperience',
        'change .js-careerVacancyApplyFormTillCurrent': 'changeTillCurrent',

        'change .js-careerResumeFileLink': 'changeResume',
    },

    initialize: function (options) {
        this.$careerVacancyApplyForm = $('.CareerVacancyApplyForm');
        // this.$careerVacancyApplyForm.on('change', '.js-careerVacancyApplyFormTillCurrent', this.changeTillCurrent);

        this.form = new CommonForm(this.$careerVacancyApplyForm);

        this.$careerVacancyApplyForm.on('click', '.js-removeFile', this.changeResume);

        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        return Page.prototype.activate.call(this, params);
    },

    isCurrentStepValid: function () {
        const $currentStep = $('.CareerVacancyApplyForm-step.isCurrentStep');

        var _this = this;

        $currentStep.find('[data-required="true"]').each(function(key, field) {
            _this.form.validateField(field);
        });

        return $currentStep.find('.isInvalid').length === 0;
    },

    changeResume: function () {
        var isResumeFileOrLinkNotEmpty = false;

        $('.js-careerResumeFileLink').each(function(key, field) {
            if ($(field).val() !== '') {
                isResumeFileOrLinkNotEmpty = true;
            }
        });

        if (isResumeFileOrLinkNotEmpty) {
            $('.js-careerVacancyChangeStep[data-step="1"]')
                .attr('data-submit', true)
                .html('Отправить отклик');
        } else {
            $('.js-careerVacancyChangeStep[data-step="1"]')
                .attr('data-submit', false)
                .html('Далее');
        }
    },

    changeWorkExperience: function (e) {
        const isChecked = $(e.currentTarget).is(':checked');

        if (isChecked) {
            const template =
                `<div class="CommonNewForm-fieldRow">
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-about-me">О себе</label>
                        <textarea class="CommonNewForm-field CommonNewForm-field--textarea" name="about-me" id="CareerVacancyApplyForm-about-me" placeholder="Расскажите о себе в свободной форме"></textarea>
                    </div>
                </div>`;

            $('.CareerVacancyApplyForm-workFieldsWrapper').html(template);
            $('.js-addWorkFields').parent().hide();
        } else {
            $('.CareerVacancyApplyForm-workFieldsWrapper').html('');
            this.addWorkFields();

            $('.js-addWorkFields').parent().show();
        }
    },

    changeTillCurrent: function (e) {
        const isChecked = $(e.currentTarget).is(':checked');
        $(e.currentTarget).parents('.CommonNewForm-fieldGroup').find('input[type="text"]').attr('disabled', isChecked);
    },

    addWorkFields: function () {
        const index = $('.CareerVacancyApplyForm-workFields').length;

        const template =
            `<div class="CareerVacancyApplyForm-workFields">
                <div class="CommonNewForm-fieldRow">
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-company-name-${index}">Название компании</label>
                        <input class="CommonNewForm-field" type="text" name="company-name[${index}]" id="CareerVacancyApplyForm-company-name-${index}" placeholder="Название компании">
                    </div>
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-company-post-${index}">Должность</label>
                        <input class="CommonNewForm-field" type="text" name="company-post[${index}]" id="CareerVacancyApplyForm-company-post-${index}" placeholder="Название должности">
                    </div>
                </div>

                <div class="CommonNewForm-fieldRow">
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-company-begin-${index}">Дата начала работы</label>
                        <input class="CommonNewForm-field" type="text" name="company-year-begin[${index}]" id="CareerVacancyApplyForm-year-begin-${index}" placeholder="ГГГГ">
                    </div>
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-year-end-${index}">Дата окончания работы</label>
                        <input class="CommonNewForm-field" type="text" name="company-year-end[${index}]" id="CareerVacancyApplyForm-company-year-end-${index}" placeholder="ГГГГ">

                        <div class="CommonNewForm-fieldGroupCheckbox">
                            <input class="CommonNewForm-checkbox js-careerVacancyApplyFormTillCurrent" type="checkbox" name="company-year-end-present[${index}]" id="CareerVacancyApplyForm-company-year-end-present-${index}">
                            <label class="CommonNewForm-checkboxLabel" for="CareerVacancyApplyForm-company-year-end-present-${index}">
                                По настоящее время
                            </label>
                        </div>
                    </div>
                </div>

                <div class="CommonNewForm-fieldRow">
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-company-responsibilities-${index}">Должностные обязанности</label>
                        <input class="CommonNewForm-field" type="text" name="company-company-responsibilities[${index}]" id="CareerVacancyApplyForm-company-responsibilities-${index}" placeholder="Напишите о своих должностных обязанностях">
                    </div>
                </div>
            </div>`;

        $('.CareerVacancyApplyForm-workFieldsWrapper').append(template);
    },

    addEducationFields: function () {
        const index = $('.CareerVacancyApplyForm-educationFields').length;

        const template =
            `<div class="CareerVacancyApplyForm-educationFields">
                <div class="CommonNewForm-fieldRow">
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-university-${index}">Название заведения</label>
                        <input class="CommonNewForm-field" type="text" name="university[${index}]" id="CareerVacancyApplyForm-university-${index}" placeholder="Название учебного заведения">
                    </div>
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-faculty-${index}">Факультет</label>
                        <input class="CommonNewForm-field" type="text" name="faculty[${index}]" id="CareerVacancyApplyForm-faculty-${index}" placeholder="Название факультета">
                    </div>
                </div>

                <div class="CommonNewForm-fieldRow">
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-speciality-${index}">Специальность</label>
                        <input class="CommonNewForm-field" type="text" name="speciality[${index}]" id="CareerVacancyApplyForm-speciality-${index}" placeholder="Название специальности">
                    </div>
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-education-level-${index}">Уровень образования</label>
                        <input class="CommonNewForm-field" type="text" name="education-level[${index}]" id="CareerVacancyApplyForm-education-level-${index}" placeholder="Бакалавр, магистр, …">
                    </div>
                </div>

                <div class="CommonNewForm-fieldRow">
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-year-begin-${index}">Год начала обучения</label>
                        <input class="CommonNewForm-field" type="text" name="education-year-begin[${index}]" id="CareerVacancyApplyForm-year-begin-${index}" placeholder="ГГГГ">
                    </div>
                    <div class="CommonNewForm-fieldGroup">
                        <label class="CommonNewForm-label" for="CareerVacancyApplyForm-year-end-${index}">Год окончания обучения</label>
                        <input class="CommonNewForm-field" type="text" name="education-year-end[${index}]" id="CareerVacancyApplyForm-education-year-end-${index}" placeholder="ГГГГ">

                        <div class="CommonNewForm-fieldGroupCheckbox">
                            <input class="CommonNewForm-checkbox js-careerVacancyApplyFormTillCurrent" type="checkbox" name="education-year-end-present[${index}]" id="CareerVacancyApplyForm-education-year-end-present-${index}">
                            <label class="CommonNewForm-checkboxLabel" for="CareerVacancyApplyForm-education-year-end-present-${index}">
                                По настоящее время
                            </label>
                        </div>
                    </div>
                </div>
            </div>`;

        $('.CareerVacancyApplyForm-educationFieldsWrapper').append(template);
    },

    changeStep: function (e) {
        if (this.isCurrentStepValid()) {
            const nextStepValue = $(e.currentTarget).data('step');

            if (nextStepValue === 1 && $(e.currentTarget).data('submit') === true) {
                this.submitForm(e);

                return;
            } else {
                $('.CareerVacancyApplyForm-step.isCurrentStep').removeClass('isCurrentStep');
                $('.CareerVacancyApplyForm-step:eq(' + nextStepValue + ')').addClass('isCurrentStep');

                $('.CareerVacancyApply-stepsNav .isCurrentStep').removeClass('isCurrentStep');
                $('.CareerVacancyApply-stepsNav').attr('data-step', nextStepValue);
                $('.CareerVacancyApply-stepsNav li:lt(' + nextStepValue + ')').addClass('isPastStep');
                $('.CareerVacancyApply-stepsNav li:eq(' + nextStepValue + ')').addClass('isCurrentStep');
            }
        }
    },

    submitForm: function (e) {
        e.preventDefault();

        var successCallback = function(response) {
            var msg = `
                <div class="CareerVacancyApplyForm-successMessage"><b>Спасибо!</b> Мы&nbsp;получили ваше резюме и&nbsp;скоро свяжемся с&nbsp;вами!</div>
                <a class="Button" href="/career/#vacancies">Вернуться к вакансиям</a>
            `;
            $('.CareerVacancyApplyFormWrapper').html(msg);
        }

        var errorCallback = function(response) {
            console.warn('error response CareerVacancyApplyForm');
            console.log(response);
        }

        this.form.submit(e, successCallback, errorCallback);
    },
});
