var Backbone = require('backbone/backbone');

require('./LegalMenuLeft.less');

module.exports = Backbone.View.extend({
    el: '.LegalMenuLeft',

    initialize: function () {

    }
});
