var Backbone = require('backbone/backbone');

require('./UtilGrid.less');

var ALT_CODE = 18;
var G_CODE = 71;

module.exports = Backbone.View.extend({
    template: require('./UtilGrid.jinja'),

    initialize: function () {
        this.state = {
            visible: false
        };

        var isAlt = false;

        // Catching key combination Alt+G
        $(document)
            .keyup(function (e) {
                
                if (e.which === ALT_CODE) {
                    isAlt = false;
                }
            })
            .keydown(
                function (e) {
                    if (e.which === ALT_CODE) {
                        isAlt = true;
                    }
                    if (e.which === G_CODE && isAlt === true) {
                        if (this.state.visible) {
                            this.$el.fadeToggle('fast', function () {
                                $(this).remove();
                            });
                            this.state.visible = false;
                        } else {
                            $('body').append(this.render().$el.fadeToggle('fast'));
                            this.state.visible = true;
                        }

                        return false;
                    }
                }.bind(this)
            );
    },

    render: function () {
        var $html = $(this.template.render());
        this.setElement($html);

        return this;
    }
});
