var Backbone = require('backbone/backbone');

require('./QualityForm.less');

var CommonForm = require('front/components/CommonNewForm/CommonNewForm');

module.exports = Backbone.View.extend({
    el: '.QualityForm',

    events: {
        'submit': 'submitForm',
    },

    initialize: function () {
        var _this = this;

        this.$qualityForm = $('.QualityForm');
        this.form = new CommonForm(this.$qualityForm, {
            name: 'files',
            id: 'QualityForm-uploadFiles',
        });
    },

    submitForm: function (e) {
        e.preventDefault();

        var successCallback = function(response) {}

        var errorCallback = function(response) {
            console.warn(response);
        }

        this.form.submit(e, successCallback, errorCallback);
    },
});
