var Backbone = require('backbone');
var _ = require('underscore');

var Index = require('front/pages/Index/Index');
var People = require('front/pages/People/People');
var About = require('front/pages/About/About');
var History = require('front/pages/History/History');
var Responsible = require('front/pages/Responsible/Responsible');
var Brands = require('front/pages/Brands/Brands');
var Career = require('front/pages/Career/Career');
var CareerCategory = require('front/pages/CareerCategory/CareerCategory');
var CareerVacancyApply = require('front/pages/CareerVacancyApply/CareerVacancyApply');
var Brand = require('front/pages/Brand/Brand');
var Production = require('front/pages/Production/Production');
var Principles = require('front/pages/Principles/Principles');
var Excursion = require('front/pages/Excursion/Excursion');
var LocationsPopup = require('front/pages/LocationsPopup/LocationsPopup');
var Contact = require('front/pages/Contact/Contact');
var NotFound = require('front/pages/NotFound/NotFound');
var PostList = require('front/pages/PostList/PostList');
var Post = require('front/pages/Post/Post');

var Legal = require('front/pages/Legal/Legal');
var LegalDocuments = require('front/pages/LegalDocuments/LegalDocuments');
var LegalRestaurants = require('front/pages/LegalRestaurants/LegalRestaurants');
var LegalRetail = require('front/pages/LegalRetail/LegalRetail');

var Confidential = require('front/pages/Confidential/Confidential');

var MediaSubscribe = require('front/pages/MediaSubscribe/MediaSubscribe');

var langs = '';

module.exports = Backbone.Router.extend({
    routes: {
        '(:lang/)about/': 'about',
        '(:lang/)people/': 'people',
        '(:lang/)history/': 'history',
        '(:lang/)responsobility/': 'responsible',
        '(:lang/)brands/:category/': 'brands',
        '(:lang/)career/': 'career',
        '(:lang/)career/:category/': 'careerCategory',
        '(:lang/)career/:category/:slug/': 'careerCategory',
        '(:lang/)career/vacancy/:id/apply/': 'careerVacancyApply',
        // '(:lang/)brand/:id/': 'brand',
        '(:lang/)production/': 'production',
        '(:lang/)excursion/': 'excursion',
        '(:lang/)principles/': 'principles',
        '(:lang/)locations/': 'locations',
        '(:lang/)posts/': 'postList',
        '(:lang/)posts/subscribe/': 'mediaSubscribe',
        '(:lang/)posts/:section/': 'postList',
        // '(:lang/)post/:id/': 'post',
        '(:lang/)contact/': 'contact',
        '(:lang/)legal/partners/': 'legal',
        '(:lang/)legal/restaurants/': 'legalRestaurants',
        '(:lang/)legal/retail/': 'legalRetail',
        '(:lang/)legal/docs/': 'legalDocuments',
        '(:lang/)confidential/': 'confidential',
        '(:lang/)404/': 'notFound'
    },

    initialize: function () {
        this.route(/^(en\/|zh-hans\/|zh-tw\/)?(\?.*)?$/, 'index');
        this.route(/^(en\/|zh-hans\/|zh-tw\/)?brands\/(\d+)\/(\?.*)?$/, 'brand');
        this.route(/^(en\/|zh-hans\/|zh-tw\/)?posts\/(\d+)\/(\?.*)?$/, 'post');
    },

    index: function () {
        app.state.oldUrl = Backbone.history.getFragment();

        var params = {
            data: {
                slides: window.app.data.indexData.slides,
                postsPrimary: window.app.data.indexData.postsPrimary,
                postsSecondary: window.app.data.indexData.postsSecondary,
                info: window.app.data.indexData.info,
                notify: window.app.data.indexData.notify
            }
        };
        this.activate(Index, params);
    },

    people: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(People);
    },

    about: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(About);
    },

    history: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(History);
    },

    responsible: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(Responsible);
    },

    brands: function (lang) {
        app.state.oldUrl = Backbone.history.getFragment();
        if (app.state.view && app.state.view instanceof Brands && !app.state.popup) {
            app.state.view.update();
        } else {
            this.activate(Brands);
        }
        // this.activate(Brands);
    },

    career: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(Career);
    },

    careerCategory: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(CareerCategory);
    },

    careerVacancyApply: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(CareerVacancyApply);
    },

    principles: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(Principles);
    },

    locations: function () {
        var params = {
            data: {
                brands: window.app.data.brands,
                beer: window.app.data.beer,
                ciders: window.app.data.ciders,
                locations: window.app.data.locations
            }
        };

        this.activate(LocationsPopup, params, true);
    },

    brand: function (lang, id) {
        app.state.oldUrl = Backbone.history.getFragment();
        if (app.state.view && app.state.view instanceof Brand && !app.state.popup) {
            app.state.view.switch(parseInt(id, 10));
        } else {
            this.activate(Brand);
        }
    },

    production: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(Production);
    },

    excursion: function () {
        var params = {
            data: {
                excursionData: window.app.data.excursionData
            }
        };

        this.activate(Excursion, params, true);
    },

    postList: function (lang, section) {
        app.state.oldUrl = Backbone.history.getFragment();

        if (app.state.view && app.state.view instanceof PostList && !app.state.popup) {
            app.state.view.updateSection(section);
        } else {
            var params = {
                data: {
                    sections: app.data.sections
                }
            };

            this.activate(PostList, params);
        }
    },

    post: function (lang, id) {

        if (app.state.popup && app.state.popup instanceof Post) {
            app.state.popup.updatePost(id);
        } else {

            var params = {
                id: id,
                data: {
                    post: window.app.data.post,
                    sections: window.app.data.sections
                }
            };
            this.activate(Post, params, true);
        }
    },

    contact: function () {
        this.activate(Contact);
    },

    legal: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(Legal);
    },

    legalRestaurants: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(LegalRestaurants);
    },

    legalRetail: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(LegalRetail);
    },

    legalDocuments: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(LegalDocuments);
    },

    confidential: function () {
        app.state.oldUrl = Backbone.history.getFragment();
        this.activate(Confidential);
    },

    mediaSubscribe: function () {
        this.activate(MediaSubscribe);
    },

    notFound: function () {
        this.activate(NotFound);
    },

    activate: function (view, params, isPopup) {
        params = params || {};

        // вьюха отрендерена на сервере
        if (!app.state.view) {
            params.server = true;
        }

        if (isPopup) {
            app.state.popup = new view(params);
            app.state.popup.playIn();
        } else {
            if (app.state.popup) {
                app.state.popup.playOut();

                if (!app.state.view) {
                    app.state.view = new view(params);
                    $('.Content-body').prepend(app.state.view.render().el);
                    app.state.view.setTitle();
                    app.state.view.activate();
                } else {
                    app.state.view.update();
                    app.state.view.setTitle();
                }
            } else {
                app.state.view = new view(params);
                app.state.view.setTitle();
                app.state.view.activate();
            }

            app.state.popup = null;
        }
    },

    start: function () {
        var is404 = app.els.$body.hasClass('Page404');
        var pushStateSupported = history && _.isFunction(history.pushState);
        Backbone.history.start({ pushState: pushStateSupported, silent: is404 });

        app.views.topNav.activate();

        if (is404) {
            this.notFound();
        }
    }
});
