var Backbone = require('backbone');
var _ = require('underscore');
var Stickyfill = require('stickyfilljs');
require('dotdotdot');
require('velocity-animate');
var Velocity = require('velocity-animate');

var Page = require('front/pages/Page/Page');
var TopNav = require('front/components/TopNav/TopNav');
var BrandsGrid = require('front/components/BrandsGrid/BrandsGrid');
var Locations = require('front/components/Locations/Locations');
var Share = require('front/components/Share/Share');
var BrandImageTemplate = require('./BrandImage.jinja');
var BrandDescriptionTemplate = require('./BrandDescription.jinja');
var BrandArrowsTemplate = require('./BrandArrows.jinja');

require('./Brand.less');

module.exports = Page.extend({
    template: require('./Brand.jinja'),

    el: '.Brand',

    events: {
        'click .Brand-buttonText': 'onShowText'

        // 'mouseenter .Brand-arrowLink': 'onEnterArrow',
        // 'mouseleave .Brand-arrowLink': 'onLeaveArrow',
    },

    initialize: function (options) {
        this.state = new Backbone.Model({
            next: null,
            prev: null,
            nextLoaded: false,
            prevLoaded: false
        });

        this.data = app.data.brand;

        this.topNav = new TopNav({
            el: $('.TopNav'),
            type: 'fixed'
        });

        this.brandsGrid = new BrandsGrid({
            type: 'brand'
        });

        this.$brandLocations = this.$('.Brand-locations');
        this.$locations = this.$('.Locations');

        if (this.$locations.size()) {
            this.locations = new Locations({
                type: 'brand',
                data: {
                    locations: this.data.locations
                }
            });
        }

        this.share = new Share({
            data: {
                title: window.gettext('Бренды') + ': ' + this.data.title,
                link: this.data.link
            }
        });

        this.title = window.gettext('Бренды') + ': ' +  app.data.brand.title;

        this.listenTo(this.state, 'change', this.onPreloadBrands);
        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        this.topNav.activate();

        if (this.locations) {
            this.locations.setElement(this.$locations);
            this.locations.initMap();
        }

        Stickyfill.add(this.$('.Brand-media'));

        this.$items = this.$('.BrandImage');

        this.owl = this.$items.owlCarousel({
            items: 1,
            autoplay: true,
            loop: true,
            nav: false,
            dots: true,
            mouseDrag: false,
            touchDrag: false,
            smartSpeed: 500
        });

        return Page.prototype.activate.call(this, params);
    },

    switch: function (id) {
        this.loadBrand(id).then(
            function (newBrand) {
                this.data = newBrand;

                var contentHeight = this.$('.Brand-content').height();
                this.$('.Brand-content').css({ height: contentHeight });

                this.switchImage(this.data);
                this.switchContent(this.data);
                this.switchArrows(this.data);
                this.switchOther(this.data);

                this.title = window.gettext('Бренды') + ': ' + this.data.title;
                this.setTitle();

                var newContentHeight = this.$('.Brand-descriptionInner')
                    .last()
                    .css('height');

                this.$('.Brand-content').css({
                    height: 'auto'
                    // height: Math.max(parseInt(newContentHeight, 10), window.innerHeight)
                });
                this.onResizeForAnimation();
            }.bind(this)
        );
    },

    onEnterArrow: function () {
        Velocity(this.$('.Brand-arrowContent')[0], 'stop');
        var width = this.$('.Brand-arrowContent').outerWidth();
        Velocity.hook(this.$('.Brand-arrowContent')[0], 'width', 0);
        Velocity.animate(this.$('.Brand-arrowContent')[0], { width: width + 'px' }, { duration: 300 });
    },

    onLeaveArrow: function () {
        Velocity(this.$('.Brand-arrowContent')[0], 'stop');
        Velocity.animate(this.$('.Brand-arrowContent')[0], { width: 0 }, { duration: 300 });
    },

    switchImage: function (brand) {
        var $entering = this.$('.Brand-img')
            .find('.BrandImage.isEntering')
            .removeClass('isEntering');
        $entering.velocity('stop', true);

        var $exiting = this.$('.Brand-img')
            .find('.BrandImage:not(.isExiting)')
            .addClass('isExiting');

        $exiting.velocity(
            {
                opacity: 0,
                translateX: '-100%'
            },
            {
                duration: 300,
                complete: () => {
                    console.log($exiting);
                    $exiting.remove();
                }
            }
        );

        var newBrandImage = BrandImageTemplate.render({
            render: true,
            brand: brand
        });

        $entering = $(newBrandImage)
            .addClass('isEntering')
            .appendTo(this.$('.Brand-img'))
            .velocity(
            {
                opacity: [1, 0],
                translateX: ['0%', '100%']
            },
            {
                duration: 300,
                complete: () => {
                    $entering.removeClass('isEntering');
                    this.$items = this.$('.BrandImage');

                    this.owl = this.$items.owlCarousel({
                        items: 1,
                        autoplay: true,
                        loop: true,
                        nav: false,
                        dots: true,
                        mouseDrag: false,
                        touchDrag: false,
                        smartSpeed: 500
                    });
                }
            }
            );
    },

    switchContent: function (brand) {
        var $entering = this.$('.Brand-descriptionInner.isEntering').removeClass('isEntering');
        $entering.velocity('stop', true);

        var $exiting = this.$('.Brand-descriptionInner:not(.isExiting)').addClass('isExiting');

        $exiting.velocity(
            {
                opacity: 0,
                translateX: '-20px'
            },
            {
                duration: 300,
                delay: 300,
                complete: function () {
                    $exiting.remove();
                }
            }
        );

        var newBrandDescription = BrandDescriptionTemplate.render({
            render: true,
            brand: brand
        });

        var $entering = $(newBrandDescription).addClass('isEntering');

        $.Velocity.hook($entering, 'opacity', 0);
        $.Velocity.hook($entering, 'translateX', -20);

        $entering.appendTo(this.$('.Brand-description')).velocity(
            {
                opacity: 1,
                translateX: 0
            },
            {
                delay: 600,
                duration: 300,
                complete: function () {
                    $entering.removeClass('isEntering');
                }
            }
        );

    },

    switchArrows: function (brand) {
        var $entering = this.$('.Brand-arrow.isEntering').removeClass('isEntering');
        $entering.velocity('stop');

        var $exiting = this.$('.Brand-arrow:not(.isExiting)').addClass('isExiting');
        $exiting.velocity(
            {
                opacity: 0
            },
            {
                duration: 300,
                complete: function () {
                    $exiting.remove();
                }
            }
        );

        var newArrows = BrandArrowsTemplate.render({
            render: true,
            brand: this.data
        });

        // var $entering = $(newArrows).addClass('isEntering');

        // $.Velocity.hook($entering, 'opacity', 0);

        // $entering.appendTo(this.$('.Brand-arrows')).velocity(
        //     {
        //         opacity: 1
        //     },
        //     {
        //         duration: 300,
        //         complete: function () {
        //             $entering.removeClass('isEntering');
        //         }
        //     }
        // );

        this.$('.Brand-arrows').html(newArrows);
    },

    switchOther: function (brand) {
        if (this.locations) {
            this.locations.updateView(brand);
            this.$brandLocations.toggleClass('Brand-locations--active', brand.locations.length !== 0);
        }

        this.share.setElement(this.$('.Share'));
        this.share.updateView({ title: window.gettext('Бренды') + ': ' + brand.title, link: brand.link });
    },

    loadBrand: function (id) {
        return new Promise(function (resolve) {
            var preload = $.ajax({
                url: '/api/brand/' + id + '/?lang=' + app.data.lang,
                dataType: 'json',
                success: function (data) {
                    resolve(data.brand);
                }.bind(this)
            });
        });
    },

    onPreloadBrands: function () {
        if (this.state.get('nextLoaded') && this.state.get('prevLoaded')) {
        }
    },

    onShowText: function () {

    },

    deactivate: function () {
        this.locations.deactivate();
        this.brandsGrid.deactivate();

        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }
});
