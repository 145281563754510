var Backbone = require('backbone');
var template = require('./BuildingCross.jinja');
require('./BuildingCross.less');

module.exports = Backbone.View.extend({
    el: '.BuildingCross',

    template: template,

    initialize: function (options) {}
});
