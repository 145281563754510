var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/LocationTooltip/LocationTooltip.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/LocationTooltip/LocationTooltip.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n\n";
var macro_t_5 = runtime.makeMacro(
[], 
["data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_6 = "";t_6 += "\n    <div class=\"LocationTooltip\">\n        <div class=\"LocationTooltip-title\">";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"t"), env.opts.autoescape);
t_6 += "</div>\n        <div class=\"LocationTooltip-type\">";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"d"), env.opts.autoescape);
t_6 += "</div>\n\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"a")) {
t_6 += "\n            <div class=\"LocationTooltip-address\">\n                <span class=\"LocationTooltip-addressIcon\">";
t_6 += runtime.suppressValue((lineno = 9, colno = 70, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["address"])), env.opts.autoescape);
t_6 += "</span>\n                ";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"a"), env.opts.autoescape);
t_6 += "\n            </div>\n        ";
;
}
t_6 += "\n\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"p")) {
t_6 += "\n            <div class=\"LocationTooltip-telephone\">\n                <span class=\"LocationTooltip-telephoneIcon\">";
t_6 += runtime.suppressValue((lineno = 16, colno = 72, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["telephone"])), env.opts.autoescape);
t_6 += "</span>\n                ";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"p"), env.opts.autoescape);
t_6 += "\n            </div>\n        ";
;
}
t_6 += "\n\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"l")) {
t_6 += "\n            <a class=\"LocationTooltip-website\" href=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"l"), env.opts.autoescape);
t_6 += "\" target=\"_blank\">\n                <span class=\"LocationTooltip-websiteIcon\">";
t_6 += runtime.suppressValue((lineno = 23, colno = 70, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["website"])), env.opts.autoescape);
t_6 += "</span>\n                <span class=\"LocationTooltip-text\">";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"l"), env.opts.autoescape);
t_6 += "</span>\n            </a>\n        ";
;
}
t_6 += "\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_6);
});
context.addExport("LocationTooltip");
context.setVariable("LocationTooltip", macro_t_5);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 31, colno = 22, runtime.callWrap(macro_t_5, "LocationTooltip", context, [runtime.makeKeywordArgs({"data": runtime.contextOrFrameLookup(context, frame, "data")})])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/LocationTooltip/LocationTooltip.jinja"] , dependencies)