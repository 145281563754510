var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/TopNav/TopNav.jinja"] = require( "front/components/TopNav/TopNav.jinja" );
dependencies["front/components/CareerResumeForm/CareerResumeForm.jinja"] = require( "front/components/CareerResumeForm/CareerResumeForm.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/CareerCategory/CareerCategory.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/CareerCategory/CareerCategory.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/CareerCategory/CareerCategory.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/CareerCategory/CareerCategory.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "svgSprite")) {
var t_10 = t_7.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_10);
output += "\n";
env.getTemplate("front/components/TopNav/TopNav.jinja", false, "assets/app/front/pages/CareerCategory/CareerCategory.jinja", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(Object.prototype.hasOwnProperty.call(t_11, "TopNav")) {
var t_14 = t_11.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_14);
output += "\n\n";
env.getTemplate("front/components/CareerResumeForm/CareerResumeForm.jinja", false, "assets/app/front/pages/CareerCategory/CareerCategory.jinja", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
t_15.getExported(function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
if(Object.prototype.hasOwnProperty.call(t_15, "CareerResumeForm")) {
var t_18 = t_15.CareerResumeForm;
} else {
cb(new Error("cannot import 'CareerResumeForm'")); return;
}
context.setVariable("CareerResumeForm", t_18);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
output += t_19;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_22,t_21) {
if(t_22) { cb(t_22); return; }
output += t_21;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
output += t_23;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_26,t_25) {
if(t_26) { cb(t_26); return; }
output += t_25;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 7;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 7, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 8, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 10;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n";
output += runtime.suppressValue((lineno = 11, colno = 9, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed"})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 14;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"Career\">\n    <div class=\"Career-floatNav\"></div>\n    <div class=\"Career-bg\">\n        <picture>\n            <source srcset=\"/static/img/front/career/bg.png 1x, /static/img/front/career/bg@2x.png 2x\" media=\"(min-width: 768px)\" />\n            <img\n                class=\"Career-bgImage\"\n                src=\"/static/img/front/career/bg-mobile.png\"\n                srcset=\"/static/img/front/career/bg-mobile.png 1x, /static/img/front/career/bg-mobile@2x.png 2x\"\n                alt=\"\"\n                decoding=\"async\"\n            />\n        </picture>\n    </div>\n    <div class=\"Career-main\">\n        <div class=\"Career-header\">\n            <div class=\"Career-subtitle\">\n                <span class=\"Career-subtitleStroke\"></span>\n                <div class=\"Career-subtitleText\">";
output += runtime.suppressValue((lineno = 33, colno = 53, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
output += "</div>\n            </div>\n            <h1 class=\"Career-title\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"title"), env.opts.autoescape);
output += "\n            </h1>\n            <div class=\"Career-text\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"description"), env.opts.autoescape);
output += "\n            </div>\n        </div>\n        <div class=\"Career-container\">\n            ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "careerVacancies")) > 0) {
output += "<div class=\"Career-section Career-section--vacancies\">\n                <div class=\"CareerFilters\">\n                    <form class=\"CommonNewForm js-filters\" action=\"/api/filter-vacancies/\" method=\"post\">\n                        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"filters")),"location")) {
output += "\n                            <div class=\"CommonNewForm-fieldGroup\">\n                                <label class=\"CommonNewForm-label\" for=\"location\">Локация</label>\n                                <div class=\"CommonNewForm-selectWrapper\">\n                                    <select class=\"CommonNewForm-field CommonNewForm-field--select\" name=\"location\" id=\"location\">\n                                        <option value=\"\">Не выбрано</option>\n                                        ";
frame = frame.push();
var t_29 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"locations");
if(t_29) {t_29 = runtime.fromIterator(t_29);
var t_28 = t_29.length;
for(var t_27=0; t_27 < t_29.length; t_27++) {
var t_30 = t_29[t_27];
frame.set("location", t_30);
frame.set("loop.index", t_27 + 1);
frame.set("loop.index0", t_27);
frame.set("loop.revindex", t_28 - t_27);
frame.set("loop.revindex0", t_28 - t_27 - 1);
frame.set("loop.first", t_27 === 0);
frame.set("loop.last", t_27 === t_28 - 1);
frame.set("loop.length", t_28);
output += "\n                                            <option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_30),"value"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_30),"text"), env.opts.autoescape);
output += "</option>\n                                        ";
;
}
}
frame = frame.pop();
output += "\n                                    </select>\n\n                                    <div class=\"CommonNewForm-customSelectWrapper\">\n                                        <div class=\"CommonNewForm-field CommonNewForm-field--select CommonNewForm-customSelectValue\">Не выбрано</div>\n                                        <ul class=\"CommonNewForm-customSelectList\">\n                                            <li><button class=\"js-customSelectOption\" type=\"button\" data-value=\"\" data-name=\"location\">Не выбрано</button></li>\n                                            ";
frame = frame.push();
var t_33 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"locations");
if(t_33) {t_33 = runtime.fromIterator(t_33);
var t_32 = t_33.length;
for(var t_31=0; t_31 < t_33.length; t_31++) {
var t_34 = t_33[t_31];
frame.set("location", t_34);
frame.set("loop.index", t_31 + 1);
frame.set("loop.index0", t_31);
frame.set("loop.revindex", t_32 - t_31);
frame.set("loop.revindex0", t_32 - t_31 - 1);
frame.set("loop.first", t_31 === 0);
frame.set("loop.last", t_31 === t_32 - 1);
frame.set("loop.length", t_32);
output += "\n                                                <li><button class=\"js-customSelectOption\" type=\"button\" data-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_34),"value"), env.opts.autoescape);
output += "\" data-name=\"location\">";
output += runtime.suppressValue(runtime.memberLookup((t_34),"text"), env.opts.autoescape);
output += "</button></li>\n                                            ";
;
}
}
frame = frame.pop();
output += "\n                                        </ul>\n                                    </div>\n                                </div>\n                            </div>\n                        ";
;
}
output += "\n                        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"filters")),"area")) {
output += "\n                            <div class=\"CommonNewForm-fieldGroup\">\n                                <label class=\"CommonNewForm-label\" for=\"area\">Направление</label>\n                                <div class=\"CommonNewForm-selectWrapper\">\n                                    <select class=\"CommonNewForm-field CommonNewForm-field--select\" name=\"area\" id=\"area\">\n                                        ";
frame = frame.push();
var t_37 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"areas");
if(t_37) {t_37 = runtime.fromIterator(t_37);
var t_36 = t_37.length;
for(var t_35=0; t_35 < t_37.length; t_35++) {
var t_38 = t_37[t_35];
frame.set("area", t_38);
frame.set("loop.index", t_35 + 1);
frame.set("loop.index0", t_35);
frame.set("loop.revindex", t_36 - t_35);
frame.set("loop.revindex0", t_36 - t_35 - 1);
frame.set("loop.first", t_35 === 0);
frame.set("loop.last", t_35 === t_36 - 1);
frame.set("loop.length", t_36);
output += "\n                                            <option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_38),"slug"), env.opts.autoescape);
output += "\" ";
if(runtime.memberLookup((t_38),"slug") == runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"currentArea")),"name")) {
output += " selected ";
;
}
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_38),"name"), env.opts.autoescape);
output += "</option>\n                                        ";
;
}
}
frame = frame.pop();
output += "\n                                    </select>\n\n                                    <div class=\"CommonNewForm-customSelectWrapper\">\n                                        <div class=\"CommonNewForm-field CommonNewForm-field--select CommonNewForm-customSelectValue\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"currentArea")),"name"), env.opts.autoescape);
output += "</div>\n                                        <ul class=\"CommonNewForm-customSelectList\">\n                                            ";
frame = frame.push();
var t_41 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"areas");
if(t_41) {t_41 = runtime.fromIterator(t_41);
var t_40 = t_41.length;
for(var t_39=0; t_39 < t_41.length; t_39++) {
var t_42 = t_41[t_39];
frame.set("area", t_42);
frame.set("loop.index", t_39 + 1);
frame.set("loop.index0", t_39);
frame.set("loop.revindex", t_40 - t_39);
frame.set("loop.revindex0", t_40 - t_39 - 1);
frame.set("loop.first", t_39 === 0);
frame.set("loop.last", t_39 === t_40 - 1);
frame.set("loop.length", t_40);
output += "\n                                                <li><button class=\"js-customSelectOption\" type=\"button\" data-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_42),"slug"), env.opts.autoescape);
output += "\" data-name=\"area\">";
output += runtime.suppressValue(runtime.memberLookup((t_42),"name"), env.opts.autoescape);
output += "</button></li>\n                                            ";
;
}
}
frame = frame.pop();
output += "\n                                        </ul>\n                                    </div>\n                                </div>\n                            </div>\n                        ";
;
}
output += "\n                        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"filters")),"experience")) {
output += "\n                            <div class=\"CommonNewForm-fieldGroup\">\n                                <label class=\"CommonNewForm-label\" for=\"experience\">Опыт работы</label>\n                                <div class=\"CommonNewForm-selectWrapper\">\n                                    <select class=\"CommonNewForm-field CommonNewForm-field--select\" name=\"experience\" id=\"experience\">\n                                        <option value=\"\">Не выбрано</option>\n                                        ";
frame = frame.push();
var t_45 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"experience");
if(t_45) {t_45 = runtime.fromIterator(t_45);
var t_44 = t_45.length;
for(var t_43=0; t_43 < t_45.length; t_43++) {
var t_46 = t_45[t_43];
frame.set("experience", t_46);
frame.set("loop.index", t_43 + 1);
frame.set("loop.index0", t_43);
frame.set("loop.revindex", t_44 - t_43);
frame.set("loop.revindex0", t_44 - t_43 - 1);
frame.set("loop.first", t_43 === 0);
frame.set("loop.last", t_43 === t_44 - 1);
frame.set("loop.length", t_44);
output += "\n                                            <option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_46),"value"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_46),"text"), env.opts.autoescape);
output += "</option>\n                                        ";
;
}
}
frame = frame.pop();
output += "\n                                    </select>\n\n                                <div class=\"CommonNewForm-customSelectWrapper\">\n                                    <div class=\"CommonNewForm-field CommonNewForm-field--select CommonNewForm-customSelectValue\">Не выбрано</div>\n                                    <ul class=\"CommonNewForm-customSelectList\">\n                                        <li><button class=\"js-customSelectOption\" type=\"button\" data-value=\"\" data-name=\"experience\">Не выбрано</button></li>\n                                        ";
frame = frame.push();
var t_49 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"experience");
if(t_49) {t_49 = runtime.fromIterator(t_49);
var t_48 = t_49.length;
for(var t_47=0; t_47 < t_49.length; t_47++) {
var t_50 = t_49[t_47];
frame.set("experience", t_50);
frame.set("loop.index", t_47 + 1);
frame.set("loop.index0", t_47);
frame.set("loop.revindex", t_48 - t_47);
frame.set("loop.revindex0", t_48 - t_47 - 1);
frame.set("loop.first", t_47 === 0);
frame.set("loop.last", t_47 === t_48 - 1);
frame.set("loop.length", t_48);
output += "\n                                            <li><button class=\"js-customSelectOption\" type=\"button\" data-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_50),"value"), env.opts.autoescape);
output += "\" data-name=\"experience\">";
output += runtime.suppressValue(runtime.memberLookup((t_50),"text"), env.opts.autoescape);
output += "</button></li>\n                                        ";
;
}
}
frame = frame.pop();
output += "\n                                    </ul>\n                                </div>\n                            </div>\n                        </div>\n                        ";
;
}
output += "\n                    </form>\n                </div>\n                <div class=\"Career-vacanciesList\" id=\"CareerVacanciesList\">\n                    ";
frame = frame.push();
var t_53 = runtime.contextOrFrameLookup(context, frame, "careerVacancies");
if(t_53) {t_53 = runtime.fromIterator(t_53);
var t_52 = t_53.length;
for(var t_51=0; t_51 < t_53.length; t_51++) {
var t_54 = t_53[t_51];
frame.set("vacancy", t_54);
frame.set("loop.index", t_51 + 1);
frame.set("loop.index0", t_51);
frame.set("loop.revindex", t_52 - t_51);
frame.set("loop.revindex0", t_52 - t_51 - 1);
frame.set("loop.first", t_51 === 0);
frame.set("loop.last", t_51 === t_52 - 1);
frame.set("loop.length", t_52);
output += "<div class=\"CareerVacancyCard\">\n                        <a class=\"CareerVacancyCard-link\" href=\"/career/vacancy/";
output += runtime.suppressValue(runtime.memberLookup((t_54),"id"), env.opts.autoescape);
output += "/\">\n                            <h3 class=\"CareerVacancyCard-title\">";
output += runtime.suppressValue(runtime.memberLookup((t_54),"name"), env.opts.autoescape);
output += "</h3>\n                            <div class=\"CareerVacancyCard-info\">\n                                <div class=\"CareerVacancyCard-description\">Опыт: ";
output += runtime.suppressValue(runtime.memberLookup((t_54),"experience"), env.opts.autoescape);
if(runtime.memberLookup((t_54),"city")) {
output += ", Город: ";
output += runtime.suppressValue(runtime.memberLookup((t_54),"city"), env.opts.autoescape);
;
}
output += "</div>\n                                <div class=\"CareerVacancyCard-linkMore\">\n                                    <span class=\"CareerVacancyCard-linkMoreText\">Подробнее</span>\n                                    <svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 19 12\" class=\"CareerVacancyCard-linkMoreIcon\" width=\"19\" height=\"12\"><path fill=\"#202020\" d=\"m19 6.001-5.045 5.043-1.414-1.414L15.17 7H.57V5h14.6l-2.63-2.63L13.955.956 19 6.001Z\"/></svg>\n                                </div>\n                            </div>\n                        </a>\n                    </div>\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n            </div>\n            ";
;
}
else {
output += "\n                ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "careerDirections")) > 0) {
output += "<section class=\"Career-section\">\n                    <h2 class=\"Career-sectionTitle\">Направления</h2>\n                    <ul class=\"CareerDirections CareerDirections--";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "careerCategory"), env.opts.autoescape);
output += "\">\n                        ";
frame = frame.push();
var t_57 = runtime.contextOrFrameLookup(context, frame, "careerDirections");
if(t_57) {t_57 = runtime.fromIterator(t_57);
var t_56 = t_57.length;
for(var t_55=0; t_55 < t_57.length; t_55++) {
var t_58 = t_57[t_55];
frame.set("item", t_58);
frame.set("loop.index", t_55 + 1);
frame.set("loop.index0", t_55);
frame.set("loop.revindex", t_56 - t_55);
frame.set("loop.revindex0", t_56 - t_55 - 1);
frame.set("loop.first", t_55 === 0);
frame.set("loop.last", t_55 === t_56 - 1);
frame.set("loop.length", t_56);
output += "<li class=\"CareerDirections-item\">\n                            <a class=\"CareerDirections-itemLink\" href=\"/career/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "careerCategory"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.memberLookup((t_58),"slug"), env.opts.autoescape);
output += "/\">\n                                <div class=\"CareerDirections-itemContent\">\n                                    <div class=\"CareerDirections-itemBg\">\n                                        <img\n                                            src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_58),"img")),"w370"), env.opts.autoescape);
output += "\"\n                                            srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_58),"img")),"w370"), env.opts.autoescape);
output += " 1x, ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_58),"img")),"w768"), env.opts.autoescape);
output += " 2x\"\n                                            alt=\"\"\n                                            loading=\"lazy\"\n                                            decoding=\"async\"\n                                        >\n                                    </div>\n                                    <h3 class=\"CareerDirections-itemTitle\">";
output += runtime.suppressValue(runtime.memberLookup((t_58),"name"), env.opts.autoescape);
output += "</h3>\n                                </div>\n                            </a>\n                            ";
if(runtime.memberLookup((t_58),"vacancies_count")) {
output += "\n                            <div class=\"CareerDirections-itemNote\">";
output += runtime.suppressValue(runtime.memberLookup((t_58),"vacancies_count"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(env.getFilter("pluralize").call(context, runtime.memberLookup((t_58),"vacancies_count"),"вакансия,вакансии,вакансий"), env.opts.autoescape);
output += "</div>\n                            ";
;
}
output += "\n                        </li>\n                        ";
;
}
}
frame = frame.pop();
output += "</ul>\n                </section>\n                ";
;
}
output += "\n            ";
;
}
output += "\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "careerCategory") != "internships") {
output += "\n            <section class=\"Career-section\">\n                <h2 class=\"Career-sectionTitle\">Нет подходящей вакансии?</h2>\n                <p class=\"Career-sectionText\">Оставьте свои данные, и&nbsp;мы&nbsp;попробуем подобрать варианты</p>\n\n                ";
output += runtime.suppressValue((lineno = 169, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerResumeForm"), "CareerResumeForm", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_59 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_60 = "";;
frame = frame.pop();
return new runtime.SafeString(t_60);
});
return macro_t_59;})()})])), env.opts.autoescape);
output += "\n            </section>\n            ";
;
}
else {
output += "\n            <div class=\"Career-section\">\n                <p class=\"Career-sectionText\"><b>К сожалению, сейчас нет открытых стажировок.</b></p>\n                <p class=\"Career-sectionText\">\n                    Мы будем очень рады получить ваш отклик! <br>\n                    Оставьте свой email, и вам придет уведомление, когда стажировки появятся.\n                </p>\n\n                <a href=\"/career/internships/apply\" class=\"Button\">Оставить заявку</a>\n            </div>\n            ";
;
}
output += "\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/CareerCategory/CareerCategory.jinja"] , dependencies)