var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/LegalMenuLeft/LegalMenuLeft.jinja"] = require( "front/components/LegalMenuLeft/LegalMenuLeft.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/LegalDocuments/LegalDocuments.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "svgSprite")) {
var t_10 = t_7.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_10);
output += "\n";
env.getTemplate("front/components/LegalMenuLeft/LegalMenuLeft.jinja", false, "assets/app/front/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(Object.prototype.hasOwnProperty.call(t_11, "LegalMenuLeft")) {
var t_14 = t_11.LegalMenuLeft;
} else {
cb(new Error("cannot import 'LegalMenuLeft'")); return;
}
context.setVariable("LegalMenuLeft", t_14);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
output += t_15;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_18,t_17) {
if(t_18) { cb(t_18); return; }
output += t_17;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
output += t_19;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_22,t_21) {
if(t_22) { cb(t_22); return; }
output += t_21;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 5;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 5, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["LegalPartners"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 5, colno = 50, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Partners"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 6;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 6, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["LegalPartners"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 6, colno = 52, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Partners"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n";
output += runtime.suppressValue((lineno = 9, colno = 9, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--about","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path")})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 12;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"LegalDocuments\">\n    <div class=\"LegalDocuments-floatNav\"></div>\n    <div class=\"LegalDocuments-background\">\n        <img class=\"LegalDocuments-backgroundImage\" src=\"\" srcset=\"\" alt=\"\">\n    </div>\n    <div class=\"LegalDocuments-left\">\n        ";
output += runtime.suppressValue((lineno = 19, colno = 29, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "LegalMenuLeft"), "LegalMenuLeft", context, [runtime.makeKeywordArgs({"classes": "LegalMenuLeft--legal","subpage": "documents","caller": (function (){var macro_t_23 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_24 = "";;
frame = frame.pop();
return new runtime.SafeString(t_24);
});
return macro_t_23;})()})])), env.opts.autoescape);
output += "\n        ";
output += "\n    </div>\n    <div class=\"LegalDocuments-right\">\n        <div class=\"LegalDocuments-header\">\n            <div class=\"LegalDocuments-subtitle\">\n                <span class=\"LegalDocuments-subtitleStroke\"></span>\n                <div class=\"LegalDocuments-subtitleText\">";
output += runtime.suppressValue((lineno = 34, colno = 61, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Документы"])), env.opts.autoescape);
output += "</div>\n            </div>\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "legalPolitics")) {
output += "\n            <div class=\"LegalDocuments-description\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "legalPolitics")),"title"), env.opts.autoescape);
output += "\n            </div>\n            <div class=\"LegalDocuments-text\">\n                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "legalPolitics")),"text_rendered")), env.opts.autoescape);
output += "\n            </div>\n            ";
;
}
output += "\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "legalDocuments")) {
output += "\n            <div class=\"LegalDocuments-description\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "legalDocuments")),"title"), env.opts.autoescape);
output += "\n            </div>\n            <div class=\"LegalDocuments-text\">\n                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "legalDocuments")),"text_rendered")), env.opts.autoescape);
output += "\n            </div>\n            ";
;
}
output += "\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/LegalDocuments/LegalDocuments.jinja"] , dependencies)