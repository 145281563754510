var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');
var LegalMenuLeft = require('front/components/LegalMenuLeft/LegalMenuLeft');
var LegalFormRestaurants = require('front/components/LegalForms/LegalFormRestaurants/LegalFormRestaurants');

require('./LegalRestaurants.less');

module.exports = Page.extend({
    template: require('./LegalRestaurants.jinja'),

    el: '.Legal',

    title: window.gettext('LegalRestaurants') + ': ' + window.gettext('Info'),

    initialize: function (options) {
        this.menuLeft = new LegalMenuLeft();
        this.form = new LegalFormRestaurants();

        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        return Page.prototype.activate.call(this, params);
    }
});
