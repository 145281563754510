var Backbone = require('backbone');
var _ = require('underscore');
require('isotope-layout/dist/isotope.pkgd.js');

require('./BrandsGrid.less');
var brandGridItemTemplate = require('./BrandsGridItem.jinja');

module.exports = Backbone.View.extend({
    el: '.BrandsGrid',

    template: require('./BrandsGrid.jinja'),

    initialize: function (options) {
        _.bindAll(this, '_setScreenWidth', '_onScroll');

        this.options = options || {};

        this.state = new Backbone.Model({
            screenWidth: null,
            scrollPagination: false
        });

        if (this.options.type !== 'brand') {
            this._setScreenWidth();
        }

        this.category = null;
        this.subCategory = null;
        this.styles = [];

        _.bindAll(this, 'checkLastColumns');

        this.$el.on('layoutComplete', this.checkLastColumns);

        this.$el.isotope({
            itemSelector: '.BrandsGrid-item',
            getSortData: {
                number: '[data-order] parseInt'
            },
            sortBy: 'number',
            masonry: {
                columnWidth: '.BrandsGrid-sizer'
            }
        });

        this.$loader = this.$('.BrandsGrid-loader');

        if (this.options.type !== 'brand') {
            this.listenTo(this.state, 'change:screenWidth', this.onChangeScreenWidth);
            this.setScreenWidth = _.throttle(this._setScreenWidth, 25);
            app.els.$window.on('resize', this.setScreenWidth);

            if (app.data.hasMore) {
                this.onScroll = _.throttle(this._onScroll, 50);
                this.state.set('scrollPagination', true);
                app.els.$window.on('scroll', this.onScroll);
            }
        }
    },

    checkLastColumns: function (event, laidOutItems) {
        this.$el.addClass('isReady');

        laidOutItems.forEach(function (item) {
            if (item.position.x + item.size.width > item.layout.modes.masonry.columnWidth * 4) {
                $(item.element).attr('data-last-column', 'true');
            } else {
                $(item.element).attr('data-last-column', 'false');
            }
        });
    },

    _setScreenWidth: function () {
        this.state.set('screenWidth', app.els.$window.width() >= 1440 ? 'wide' : 'narrow');
    },

    onChangeScreenWidth: function (model) {
        this.$('.BrandsGrid-item').each(function (index) {
            $(this).attr('data-order', index);
        });

        this.$el.isotope('updateSortData').isotope();
    },

    filterData: function (params) {
        var fetchParams = {

        };

        this.category = params.category.id;
        this.subCategory = params.subCategory.id;
        this.styles = _.map(params.styles, function (item) {
            return item.id;
        });

        if (this.category) {
            fetchParams.category = this.category;
        }

        if (this.subCategory) {
            fetchParams.subCategory = this.subCategory;
        }

        if (this.styles.length) {
            fetchParams.styles = this.styles;
        }

        this.fetchData(fetchParams);
    },

    fetchData: function (params) {
        this.state.set('isLoading', false);
        if (this.loadingRequest) {
            this.loadingRequest.abort();
        }

        $.ajax({
            url: '/api/brands/',
            dataType: 'json',
            data: params,
            success: function (data) {
                var $currentElems = this.$('.BrandsGrid-item');

                if (data.hasMore && !this.state.get('scrollPagination')) {
                    this.state.set('scrollPagination', true);
                    app.els.$window.on('scroll', this.onScroll);
                }

                var $addedElems = $(
                    _.map(data.brands, function (brand) {
                        return $(
                            brandGridItemTemplate.render({
                                brand: {
                                    ...brand,
                                    isFavorite: Object.keys(params).length == 1 && brand.isFavorite
                                },
                                render: true
                            })
                        )[0];
                    })
                );

                this.$el.isotope({
                    stagger: 0,
                    visibleStyle: {
                        opacity: 1,
                        transform: 'scale(1)'
                    },
                    hiddenStyle: {
                        opacity: 0,
                        transform: 'scale(0.002)'
                    }
                });

                this.$el
                    .isotope('remove', $currentElems)
                    .isotope('insert', $addedElems)
                    .isotope('updateSortData')
                    .isotope('layout');
            }.bind(this)
        });
    },

    _onScroll: function () {
        if (this.state.get('isLoading')) {
            return;
        }

        var windowBottom = app.els.$window.scrollTop() + app.els.$window.height();
        var gridBottom = this.$el.offset().top + this.$el.height();

        if (windowBottom > gridBottom) {
            this.loadMore();
        }
    },

    loadMore: function () {
        this.state.set('isLoading', true);
        this.$loader.addClass('isActive');
        this.state.set('scrollPagination', false);
        app.els.$window.off('scroll', this.onScroll);

        var fetchParams = {
            offset: _.reduce(
                this.$('.BrandsGrid-item'),
                function (tileCount, item) {
                    if ($(item).hasClass('BrandsGrid-item--isBig')) {
                        return tileCount + 4;
                    } else {
                        return tileCount + 1;
                    }
                },
                0
            ),
            limit: 20
        };

        if (this.category) {
            fetchParams.category = this.category;
        }

        if (this.subCategory) {
            fetchParams.subCategory = this.subCategory;
        }

        if (this.styles.length) {
            fetchParams.styles = this.styles;
        }

        this.loadingRequest = $.ajax({
            url: '/api/brands/',
            dataType: 'json',
            data: fetchParams,
            success: function (data) {
                this.state.set('isLoading', false);
                this.$loader.removeClass('isActive');

                if (data.hasMore) {
                    app.els.$window.on('scroll', this.onScroll);
                    this.state.set('scrollPagination', true);
                }

                var $addedElems = $(
                    _.map(data.brands, function (brand) {
                        return $(
                            brandGridItemTemplate.render({
                                brand: brand,
                                render: true
                            })
                        )[0];
                    })
                );

                this.$el.isotope({
                    stagger: 50,
                    visibleStyle: {
                        opacity: 1,
                        transform: 'translateY(0)'
                    },
                    hiddenStyle: {
                        opacity: 0,
                        transform: 'translateY(100px)'
                    }
                });

                this.$el.append($addedElems);
                this.$el.isotope('appended', $addedElems).isotope('layout');
            }.bind(this)
        });
    },

    deactivate: function () {
        this.$el.isotope('destroy');

        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }
});
