var Backbone = require('backbone');
var _ = require('underscore');
var basicScroll = require('basicscroll');

var getScrollAnchor = require('./ScrollPosition');
require('./Story.less');

module.exports = Backbone.View.extend({
    initialize: function () {
        this.type = this.$el.attr('data-type');
        this.id = this.$el.attr('data-id');

        if (this.id === '2016-1') {
            this.activateWideCircle();
        } else if (this.id === '2015-1') {
            this.y2015id1Instance = this.activate2015id1();

            app.els.$window.on(
                'resize',
                _.debounce(
                    function () {
                        this.y2015id1Instance.destroy();
                        this.y2015id1Instance = this.activate2015id1();
                    }.bind(this),
                    500
                )
            );
        } else if (this.id === '2015-5') {
            this.activateShmel();
        } else if (this.id === '2010-4') {
            this.activateBear();
        } else if (this.id === '2018-2') {
            this.activateMotor();
        } else if (this.id === '2008-2') {
            this.activate2014id10();
        } else if (this.id === '2009-3') {
            this.activateMoskvas();
        } else if (this.id === '2010-5') {
            this.coorsInstance = this.activateCoors();

            app.els.$window.on(
                'resize',
                _.debounce(
                    function () {
                        this.coorsInstance.destroy();
                        this.coorsInstance = this.activateCoors();
                    }.bind(this),
                    500
                )
            );
        } else if (this.id === '2007-2' || this.id === '2009-6' || this.id === '2018-1') {
            this.activateLottie();
        } else if (this.id === '2007-1') {
            this.activateVideo();
        } else if (this.id === '2011-4') {
            this.activate2011id4();
        } else if (this.id === '2011-5') {
            this.activate2011id5();
        } else if (this.id === '2011-3') {
            this.activateKirin();
        } else if (this.id === '2011-2') {
            this.activateProduction();
        } else if (this.id === '2012-5') {
            this.activate2012id5();
        } else if (this.id === '2012-6') {
            this.activate2012id6();
        } else if (this.id === '2012-2') {
            this.activateKhamovniki();
        } else if (this.id === '2012-3') {
            this.activateNestle();
        } else if (this.id === '2013-6') {
            this.activate2013id6();
        } else if (this.id === '2013-7') {
            this.activateCarling();
        } else if (this.id === '2013-5') {
            this.activatePentawards();
        } else if (this.id === '2013-4') {
            this.activate2013Medals();
        } else if (this.id === '2013-1') {
            this.activateBochkove();
        } else if (this.id === '2014-1') {
            this.y2014id1Instance = this.activate2014id1();

            app.els.$window.on(
                'resize',
                _.debounce(
                    function () {
                        this.y2014id1Instance.destroy();
                        this.y2014id1Instance = this.activate2014id1();
                    }.bind(this),
                    500
                )
            );
        } else if (this.id === '2014-2') {
            this.y2014id2Instance = this.activate2014id2();

            app.els.$window.on(
                'resize',
                _.debounce(
                    function () {
                        this.y2014id2Instance.destroy();
                        this.y2014id2Instance = this.activate2014id2();
                    }.bind(this),
                    500
                )
            );
        } else if (this.id === '2014-3') {
            this.y2014id3Instance = this.activate2014id3();

            app.els.$window.on(
                'resize',
                _.debounce(
                    function () {
                        this.y2014id3Instance.destroy();
                        this.y2014id3Instance = this.activate2014id3();
                    }.bind(this),
                    500
                )
            );
        } else if (this.id === '2014-4') {
            this.y2014id4Instance = this.activate2014id4();

            app.els.$window.on(
                'resize',
                _.debounce(
                    function () {
                        this.y2014id4Instance.destroy();
                        this.y2014id4Instance = this.activate2014id4();
                    }.bind(this),
                    500
                )
            );
        } else if (this.id === '2014-5') {
            this.y2014id5Instance = this.activate2014id5();

            app.els.$window.on(
                'resize',
                _.debounce(
                    function () {
                        this.y2014id5Instance.destroy();
                        this.y2014id5Instance = this.activate2014id5();
                    }.bind(this),
                    500
                )
            );
        } else if (this.id === '2014-6') {
            this.y2014id6Instance = this.activate2014id6();

            app.els.$window.on(
                'resize',
                _.debounce(
                    function () {
                        this.y2014id6Instance.destroy();
                        this.y2014id6Instance = this.activate2014id6();
                    }.bind(this),
                    500
                )
            );
        } else if (this.id === '2014-7') {
            this.activate2014id7();
        } else if (this.id === '2014-8') {
            this.activate2014id8();
        } else if (this.id === '2014-10') {
            this.activate2014id10();
        } else if (this.id === '2015-2') {
            this.activate2015id2();
        } else if (this.id === '2015-3') {
            this.y2015id3Instance = this.activate2015id3();

            app.els.$window.on(
                'resize',
                _.debounce(
                    function () {
                        this.y2015id3Instance.destroy();
                        this.y2015id3Instance = this.activate2015id3();
                    }.bind(this),
                    500
                )
            );
        } else if (this.id === '2015-4') {
            this.activateKhamovniki();
        } else if (this.id === '2015-7') {
            this.activate2015id7();
        } else if (this.id === '2017-3') {
            this.activate2017id3();
        } else if (this.id === '2018-3') {
            this.activate2018id3();
        } else if (this.id === '2019-1') {
            this.activate2019id1();
        } else if (this.id === '2019-2') {
            app.els.$window.on('resize', this.activate2019id2.bind(this));
            this.activate2019id2();
        } else if (this.id === '2019-3') {
            this.activateScrollCheck();
        } else if (this.id === '2019-4') {
            this.activate2019id4();
        } else if (this.id === '2020-1') {
            this.activate2020id1();
        } else if (this.id === '2020-2') {
            this.activateRotate();
        } else if (this.id === '2020-3') {
            this.activate2020id3();
        } else if (this.id === '2020-4') {
            this.activateScrollCheck(0, 8, 50);
        } else if (this.id === '2020-5') {
            this.activateScrollCheck(100);
        } else if (this.id === '2021-1') {
            this.activate2021id1();
        } else if (this.id === '2021-list') {
            this.activate2021List();
        } else if (this.id === '2021-2') {
            this.activate2021id2();
        } else if (this.id === '2022-3') {
            this.activate2022id3();
        } else if (this.id === '2022-5') {
            this.activate2022id5();
        } else if (this.id === '2022-6') {
            this.activate2022id6();
        } else if (this.id === '2023-1') {
            this.activate2023id1();
        } else if (this.id === '2023-2') {
            this.activate2023id2();
        } else if (this.id === '2023-3') {
            this.activate2023id3();
        } else if (this.id === '2023-4') {
            this.activate2023id4();
        } else if (this.id === '2023-5') {
            this.activate2023id5();
        }
    },

    activateWideCircle: function () {
        var innerCircleEl = this.$('.Story-innerCircle')[0];
        var outerCircleEl = this.$('.Story-outerCircle')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-top',
            props: {
                value: {
                    from: 0.8,
                    to: 1.5
                }
            },
            inside: function (instance, percentage, props) {
                innerCircleEl.style.transform = 'scale3d(' + props.value + ', ' + props.value + ', 1)';
            },
            outside: function (instance, percentage, props) {
                if (props.value !== lastTransform) {
                    innerCircleEl.style.transform = 'scale3d(' + props.value + ', ' + props.value + ', 1)';
                }
            }
        });

        instance.start();

        var instanceOuter = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-top',
            props: {
                value: {
                    from: 0.8,
                    to: 1.2
                }
            },
            inside: function (instance, percentage, props) {
                outerCircleEl.style.transform = 'scale3d(' + props.value + ', ' + props.value + ', 1)';
            },
            outside: function (instance, percentage, props) {
                if (props.value !== lastTransform) {
                    outerCircleEl.style.transform = 'scale3d(' + props.value + ', ' + props.value + ', 1)';
                }
            }
        });

        instanceOuter.start();
    },

    activateRotate: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'middle-middle',
            props: {
                rotateZ: {
                    from: -90,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();
    },

    activateParallax: function (el, translate, from = 'top-bottom', to = 'bottom-top') {
        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: from,
            to: to,
            props: {
                translateY: {
                    from: translate.from,
                    to: translate.to
                }
            },
            inside: function (instance, percentage, props) {
                el.style.transform = 'translateY(' + props.translateY + 'px)';
            },
            outside: function (instance, percentage, props) {
                el.style.transform = 'translateY(' + props.translateY + 'px)';
            }
        });

        instance.start();
    },

    activateScrollCheck: function (offsetStartDelta = 0, steps = 1, delta = 0) {
        var vh = app.els.$window.height();
        this.scrollOffset = this.$el.offset().top + this.$el.outerHeight() / 2 - vh + offsetStartDelta;
        this.steps = steps;
        this.step = 0;
        this.delta = delta;
        this.onScrollCheck = this.onScrollCheck.bind(this);
        app.els.$window.on('scroll', this.onScrollCheck);
        this.onScrollCheck();
    },

    onScrollCheck: function () {
        var scrollTop = app.els.$window.scrollTop();
        while (scrollTop >= this.scrollOffset && this.step < this.steps) {
            var animatedClass = 'isAnimated' + (this.step ? '-' + (this.step + 1) : '');
            this.$el.addClass(animatedClass);
            this.step++;
            this.scrollOffset += this.delta;
        }
        if (this.step >= this.steps) $(window).off('scroll', this.onScrollCheck);
    },

    activate2015id1: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var scrollPositionFrom = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'top',
            shift: 75
        });
        var scrollPositionTo = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'bottom',
            shift: 75
        });

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: scrollPositionFrom,
            to: scrollPositionTo,
            props: {
                translateX: {
                    from: -250,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translateX(' + props.translateX + 'px)';
            },
            outside: function (instance, percentage, props) {
                if (props.translateX !== lastTransform) {
                    imgEl.style.transform = 'translateX(' + props.translateX + 'px)';
                }
            }
        });

        instance.start();

        return instance;
    },

    activateShmel: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'middle-bottom',
            to: 'bottom-bottom',
            props: {
                rotateZ: {
                    from: 35,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();
    },

    activateLottie: function () {
        var animation = bodymovin.loadAnimation({
            container: this.$('.Story-animation')[0],
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: this.$('.Story-animation').attr('data-animation')
        });

        var isActive = false;

        var instance = basicScroll.create({
            elem: this.$('.Story-animation')[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-top',
            inside: function (instance, percentage, props) {
                if (!isActive) {
                    isActive = true;
                    animation.play();
                }
            }.bind(this),
            outside: function (instance, percentage, props) {
                if (isActive) {
                    isActive = false;
                    animation.pause();
                }
            }.bind(this)
        });

        instance.start();
    },

    activateVideo: function () {
        var video = this.$('video')[0];
        var isActive = false;

        var instance = basicScroll.create({
            elem: this.$('.Story-video')[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-top',
            inside: function (instance, percentage, props) {
                if (!isActive) {
                    isActive = true;
                    video.play();
                }
            }.bind(this),
            outside: function (instance, percentage, props) {
                if (isActive) {
                    isActive = false;
                    video.pause();
                }
            }.bind(this)
        });

        instance.start();
    },

    activateBear: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'middle-bottom',
            to: 'middle-middle',
            props: {
                transformY: {
                    from: 250,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translate3d(0, ' + props.transformY + 'px, 0)';
            },
            outside: function (instance, percentage, props) {
                if (props.transformY !== lastTransform) {
                    imgEl.style.transform = 'translate3d(0, ' + props.transformY + 'px, 0)';
                }
            }
        });

        instance.start();
    },

    activateMotor: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'middle-bottom',
            to: 'bottom-bottom',
            props: {
                customScale: {
                    from: 1.4,
                    to: 1
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
            },
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                }
            }
        });

        instance.start();
    },

    activateMoskvas: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'middle-bottom',
            to: 'middle-middle',
            props: {
                transformY: {
                    from: 250,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translate3d(0, ' + props.transformY + 'px, 0)';
            },
            outside: function (instance, percentage, props) {
                if (props.transformY !== lastTransform) {
                    imgEl.style.transform = 'translate3d(0, ' + props.transformY + 'px, 0)';
                }
            }
        });

        instance.start();
    },

    activateCoors: function () {
        var imgEl = this.$('.Story-img')[0];
        var img2El = this.$('.Story-img2')[0];
        var lastTransformY = null;
        var lastTransformX = null;

        var scrollPositionFrom = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'middle',
            shift: 125
        });
        var scrollPositionTo = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'middle',
            elemAnchor: 'middle',
            shift: 125
        });

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: scrollPositionFrom,
            to: scrollPositionTo,
            props: {
                transformY: {
                    from: 250,
                    to: 0
                },
                transformX: {
                    from: -150,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translate3d(0, ' + props.transformY + 'px, 0)';
                img2El.style.transform = 'translate3d(' + props.transformX + 'px, 0, 0)';
            },
            outside: function (instance, percentage, props) {
                if (props.transformY !== lastTransformY) {
                    imgEl.style.transform = 'translate3d(0, ' + props.transformY + 'px, 0)';
                }

                if (props.transformX !== lastTransformX) {
                    img2El.style.transform = 'translate3d(' + props.transformX + 'px, 0, 0)';
                }
            }
        });

        instance.start();

        return instance;
    },

    activate2011id4: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'middle-middle',
            props: {
                rotateZ: {
                    from: -90,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();
    },

    activate2011id5: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'middle-middle',
            props: {
                rotateZ: {
                    from: -90,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();
    },

    activateKirin: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-top',
            props: {
                translateY: {
                    from: 0,
                    to: -400
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translate3d(0, ' + props.translateY + 'px, 0)';
            },
            outside: function (instance, percentage, props) {
                if (props.translateY !== lastTransform) {
                    imgEl.style.transform = 'translate3d(0, ' + props.translateY + 'px, 0)';
                }
            }
        });

        instance.start();
    },

    activateProduction: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'bottom-bottom',
            to: 'middle-middle',
            props: {
                customScale: {
                    from: 0,
                    to: 1
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
            },
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                }
            }
        });

        instance.start();
    },

    activate2012id5: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'middle-middle',
            props: {
                rotateZ: {
                    from: -90,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();
    },

    activate2012id6: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'middle-middle',
            props: {
                rotateZ: {
                    from: -90,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();
    },

    activateKhamovniki: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'middle-bottom',
            to: 'bottom-bottom',
            props: {
                customScale: {
                    from: 0.7,
                    to: 1
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
            },
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                }
            }
        });

        instance.start();
    },

    activateNestle: function () {
        var imgEl = this.$('.Story-img')[0];
        var circleEl = this.$('.Story-circle')[0];
        var lastTransform = null;
        var CIRCLE_RADIUS = 70;
        var elWidth = this.$el.width();
        var elHeight = this.$el.outerHeight();

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-bottom',
            props: {
                customScale: {
                    from: 1.7,
                    to: 1
                },
                scaleCircle: {
                    from: 0,
                    to: 1,
                    timing: 'cubicIn'
                }
            },
            inside: function (instance, percentage, props) {
                var diagonal = Math.sqrt(Math.pow(elWidth, 2) + Math.pow(elHeight, 2));
                var scaleCircle = ((diagonal / 2 + 50) / CIRCLE_RADIUS - 1) * props.scaleCircle;

                imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                circleEl.style.transform = 'scaleX(' + (1 + scaleCircle) + ') scaleY(' + (1 + scaleCircle) + ')';
            }.bind(this),
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    var diagonal = Math.sqrt(Math.pow(elWidth, 2) + Math.pow(elHeight, 2));
                    var scaleCircle = ((diagonal / 2 + 50) / CIRCLE_RADIUS - 1) * props.scaleCircle;

                    imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                    circleEl.style.transform = 'scaleX(' + (1 + scaleCircle) + ') scaleY(' + (1 + scaleCircle) + ')';
                }
            }.bind(this)
        });

        instance.start();

        app.els.$window.on(
            'resize',
            _.debounce(
                function () {
                    elWidth = this.$el.width();
                    elHeight = this.$el.outerHeight();
                }.bind(this),
                500
            )
        );
    },

    activate2013id6: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'middle-middle',
            props: {
                rotateZ: {
                    from: -90,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgEl.style.transform = 'rotate3d(0, 0, 1, ' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();
    },

    activateCarling: function () {
        var imgEl = this.$('.Story-imgWrapper')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'middle-bottom',
            to: 'middle-top',
            props: {
                translateX: {
                    from: 0,
                    to: -140
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translateX(' + props.translateX + '%)';
            },
            outside: function (instance, percentage, props) {
                if (props.translateX !== lastTransform) {
                    imgEl.style.transform = 'translateX(' + props.translateX + '%)';
                }
            }
        });

        instance.start();
    },

    activatePentawards: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'middle-middle',
            props: {
                customScale: {
                    from: 3,
                    to: 1
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
            },
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                }
            }
        });

        instance.start();
    },

    activate2013Medals: function () {
        var imgEl = this.$('.Story-img')[0];
        var img2El = this.$('.Story-img2')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-bottom',
            props: {
                rotateZ: {
                    from: -90,
                    to: 0
                },
                customScale: {
                    from: 1.75,
                    to: 1
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform =
                    'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ') rotateZ(' + props.rotateZ + 'deg)';
                img2El.style.transform =
                    'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ') rotateZ(' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    imgEl.style.transform =
                        'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ') rotateZ(' + props.rotateZ + 'deg)';
                    img2El.style.transform =
                        'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ') rotateZ(' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();
    },

    activateBochkove: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-top',
            props: {
                translateY: {
                    from: -200,
                    to: -600
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
            },
            outside: function (instance, percentage, props) {
                if (props.translateY !== lastTransform) {
                    imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
                }
            }
        });

        instance.start();
    },

    activate2014id1: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var scrollPositionFrom = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'top',
            shift: 50
        });
        var scrollPositionTo = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'middle',
            shift: 50
        });

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: scrollPositionFrom,
            to: scrollPositionTo,
            props: {
                customScale: {
                    from: 0.2,
                    to: 1
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
            },
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                }
            }
        });

        instance.start();

        return instance;
    },

    activate2014id2: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var scrollPositionFrom = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'top',
            shift: 125
        });
        var scrollPositionTo = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'middle',
            shift: 125
        });

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: scrollPositionFrom,
            to: scrollPositionTo,
            props: {
                customScale: {
                    from: 0.1,
                    to: 1
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
            },
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                }
            }
        });

        instance.start();

        return instance;
    },

    activate2014id3: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var scrollPositionFrom = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'top',
            shift: 75
        });
        var scrollPositionTo = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'bottom',
            shift: 75
        });

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: scrollPositionFrom,
            to: scrollPositionTo,
            props: {
                rotateZ: {
                    from: -90,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();

        return instance;
    },

    activate2014id4: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var scrollPositionFrom = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'top',
            shift: 75
        });
        var scrollPositionTo = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'bottom',
            shift: 75
        });

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: scrollPositionFrom,
            to: scrollPositionTo,
            props: {
                rotateZ: {
                    from: -90,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();

        return instance;
    },

    activate2014id5: function () {
        var imgEl = this.$('.Story-img')[0];
        var imgWrapperEl = this.$('.Story-imgWrapper')[0];
        var lastTransform = null;

        var scrollPositionFrom = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'top',
            shift: 50
        });
        var scrollPositionTo = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'bottom',
            shift: 50
        });

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: scrollPositionFrom,
            to: scrollPositionTo,
            props: {
                translateY: {
                    from: 80,
                    to: 0
                },
                rotateZ: {
                    from: 15,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgWrapperEl.style.transform = 'translateY(' + props.translateY + 'px)';
                imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgWrapperEl.style.transform = 'translateY(' + props.translateY + 'px)';
                    imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();

        return instance;
    },

    activate2014id6: function () {
        var imgEl = this.$('.Story-img')[0];
        var imgWrapperEl = this.$('.Story-imgWrapper')[0];
        var lastTransform = null;

        var scrollPositionFrom = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'top',
            shift: 125
        });
        var scrollPositionTo = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'bottom',
            shift: 125
        });

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: scrollPositionFrom,
            to: scrollPositionTo,
            props: {
                translateY: {
                    from: 80,
                    to: 0
                },
                rotateZ: {
                    from: 15,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgWrapperEl.style.transform = 'translateY(' + props.translateY + 'px)';
                imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgWrapperEl.style.transform = 'translateY(' + props.translateY + 'px)';
                    imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();

        return instance;
    },

    activate2014id7: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-bottom',
            props: {
                rotateZ: {
                    from: 35,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();
    },

    activate2014id8: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-bottom',
            props: {
                rotateZ: {
                    from: 35,
                    to: 0
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
            },
            outside: function (instance, percentage, props) {
                if (props.rotateZ !== lastTransform) {
                    imgEl.style.transform = 'rotateZ(' + props.rotateZ + 'deg)';
                }
            }
        });

        instance.start();
    },

    activate2014id10: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-top',
            props: {
                translateY: {
                    from: 0,
                    to: -300
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
            },
            outside: function (instance, percentage, props) {
                if (props.scale !== lastTransform) {
                    imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
                }
            }
        });

        instance.start();
    },

    activate2015id2: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'middle-bottom',
            props: {
                customScale: {
                    from: 0,
                    to: 1
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
            },
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                }
            }
        });

        instance.start();
    },

    activate2015id3: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var scrollPositionFrom = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'top',
            shift: 50
        });
        var scrollPositionTo = getScrollAnchor({
            el: this.$el[0],
            viewportAnchor: 'bottom',
            elemAnchor: 'middle',
            shift: 50
        });

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: scrollPositionFrom,
            to: scrollPositionTo,
            props: {
                customScale: {
                    from: 0,
                    to: 1
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
            },
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                }
            }
        });

        instance.start();

        return instance;
    },

    activate2015id7: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-top',
            props: {
                translateY: {
                    from: 0,
                    to: -500
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
            },
            outside: function (instance, percentage, props) {
                if (props.scale !== lastTransform) {
                    imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
                }
            }
        });

        instance.start();
    },

    activate2017id3: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-top',
            props: {
                translateY: {
                    from: 0,
                    to: -300
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
            },
            outside: function (instance, percentage, props) {
                if (props.scale !== lastTransform) {
                    imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
                }
            }
        });

        instance.start();
    },

    activate2018id3: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-top',
            props: {
                translateY: {
                    from: -100,
                    to: -600
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
            },
            outside: function (instance, percentage, props) {
                if (props.scale !== lastTransform) {
                    imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
                }
            }
        });

        instance.start();
    },

    activate2019id1: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-top',
            props: {
                translateY: {
                    from: 0,
                    to: -100
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
            },
            outside: function (instance, percentage, props) {
                if (props.scale !== lastTransform) {
                    imgEl.style.transform = 'translateY(' + props.translateY + 'px)';
                }
            }
        });

        instance.start();
    },

    activate2019id2: function () {
        var vh = app.els.$window.height();
        var $imageMasks = this.$('.Story-imgWrapper:not(:first-child) .Story-imgMask');
        var offset = this.$el.offset().top + this.$el.outerHeight() / 2 - vh;
        var animationOffset = (vh - 200) / ($imageMasks.size() + 1);
        if (this.y2019InstanceList) {
            this.y2019InstanceList.forEach(function (item) {
                item.destroy();
            });
        }
        this.y2019InstanceList = [];
        $imageMasks.each(function (ind, el) {
            var instance = basicScroll.create({
                elem: this.$el[0],
                direct: true,
                from: offset,
                to: offset + animationOffset,
                props: {
                    height: {
                        from: 0,
                        to: '100%'
                    }
                },
                inside: function (instance, percentage, props) {
                    el.style.height = props.height;
                },
                outside: function (instance, percentage, props) {
                    el.style.height = props.height;
                }
            });

            offset += animationOffset;

            instance.start();
            this.y2019InstanceList.push(instance);
        }.bind(this));
    },

    activate2019id4: function () {
        this.reversed = false;
        this.ANIMATION_DURATION = 300;
        this.ANIMATION_DELAY = 500;
        setInterval(this.animate2019id4.bind(this), this.ANIMATION_DURATION * 2 + this.ANIMATION_DELAY);
    },

    animate2019id4: function () {
        this.reversed = !this.reversed;
        this.$el.addClass('isAnimated');
        setTimeout(function () {
            this.$el.toggleClass('isReversed', this.reversed);
            this.$el.removeClass('isAnimated');
        }.bind(this), this.ANIMATION_DURATION);
    },

    activate2020id1: function () {
        this.activateParallax(this.$('.Story-img')[0], {
            from: 0,
            to: -700
        });
        this.activateParallax(this.$('.Story-img2')[0], {
            from: 100,
            to: -150
        });
    },

    activate2020id3: function () {
        var vh = app.els.$window.height();
        var offset = this.$el.offset().top + 70 - vh;

        this.activateParallax(this.$('.Story-img')[0], {
            from: -400,
            to: 0
        }, offset, offset + vh);
        this.activateParallax(this.$('.Story-img2')[0], {
            from: 400,
            to: 0
        }, offset, offset + vh);
    },

    activate2021id1: function () {
        this.activateParallax(this.$('.Story-img')[0], {
            from: 0,
            to: -490
        });
        this.activateParallax(this.$('.Story-img2')[0], {
            from: 0,
            to: 490
        });
    },

    activate2021List: function () {
        this.reversed = false;
        this.ANIMATION_DURATION = 300;
        this.ANIMATION_DELAY = 700;

        setInterval(this.animate2021List.bind(this), (this.ANIMATION_DURATION + this.ANIMATION_DELAY) * 2);
    },

    animate2021List: function () {
        this.$('.Story-list').addClass('isAnimated');
        setTimeout(function () {
            this.$('.Story-list').removeClass('isAnimated');
        }.bind(this), this.ANIMATION_DURATION + this.ANIMATION_DELAY);
    },

    activate2021id2: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'middle-bottom',
            to: 'bottom-bottom',
            props: {
                customScale: {
                    from: 1.2,
                    to: 1
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
            },
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                }
            }
        });

        instance.start();
    },

    activate2022id3: function () {
        this.activateParallax(this.$('.Story-img')[0], {
            from: 0,
            to: 220
        });

        this.activateParallax(this.$('.Story-img2')[0], {
            from: 0,
            to: -220
        });

        this.activateParallax(this.$('.Story-img3')[0], {
            from: 0,
            to: 220
        });
    },

    activate2022id5: function () {
        var imgEl = this.$('.Story-img')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'middle-bottom',
            to: 'bottom-bottom',
            props: {
                customScale: {
                    from: 1.17,
                    to: 1
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
            },
            outside: function (instance, percentage, props) {
                if (props.customScale !== lastTransform) {
                    imgEl.style.transform = 'scaleX(' + props.customScale + ') scaleY(' + props.customScale + ')';
                }
            }
        });

        instance.start();
    },

    activate2022id6: function () {
        this.ANIMATION_DURATION = 1000;
        this.ANIMATION_DELAY = 150;
        this.$el.addClass('isAnimated-1');

        this.animate2022id6.bind(this)();
        setInterval(this.animate2022id6.bind(this), this.ANIMATION_DURATION * 3 + this.ANIMATION_DELAY);
    },

    animate2022id6: function () {
        setTimeout(function () {
            this.$el.removeClass('isAnimated-1');
            this.$el.addClass('isAnimated-2');
        }.bind(this), this.ANIMATION_DURATION);

        setTimeout(function () {
            this.$el.removeClass('isAnimated-2');
            this.$el.addClass('isAnimated-3');
        }.bind(this), this.ANIMATION_DURATION * 2 + this.ANIMATION_DELAY);

        setTimeout(function () {
            this.$el.removeClass('isAnimated-3');
            this.$el.addClass('isAnimated-1');
        }.bind(this), this.ANIMATION_DURATION * 3 + this.ANIMATION_DELAY);
    },

    activate2023id1: function () {
        var imgOld = this.$('.Story-img0')[0];

        var imgLeft = this.$('.Story-img1')[0];
        var imgCenter = this.$('.Story-img2')[0];
        var imgCenterWrapper = this.$('.Story-imgNewCenter')[0];
        var imgRight = this.$('.Story-img3')[0];

        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-middle',
            to: 'bottom-middle',
            props: {
                // customOpacityFadeOut: {
                //     from: 1,
                //     to: 0,
                // },

                // customOpacityFadeIn: {
                //     from: 0,
                //     to: 1,
                // },

                customH: {
                    from: 0,
                    to: 269
                },

                customLeftTranslate: {
                    from: 0,
                    to: '-189%',
                },
                customRightTranslate: {
                    from: 0,
                    to: '189%',
                }
            },
            inside: function (instance, percentage, props) {
                // imgOld.style.opacity = props.customOpacityFadeOut;

                // imgLeft.style.opacity = props.customOpacityFadeIn;
                imgLeft.style.transform = 'translate3d('+ props.customLeftTranslate +', 0, 0)';

                // imgCenter.style.opacity = props.customOpacityFadeIn;
                imgCenterWrapper.style.height = props.customH + 'px';

                // imgRight.style.opacity = props.customOpacityFadeIn;
                imgRight.style.transform = 'translate3d('+ props.customRightTranslate +', 0, 0)';
            },
        });

        instance.start();
    },

    activate2023id2: function () {
        var imgEl = this.$('.Story-imgWrapper')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-middle',
            props: {
                customScale: {
                    from: 1,
                    to: 0.6
                },
                customTranslate: {
                    from: -324,
                    to: -54
                }
            },
            inside: function (instance, percentage, props) {
                imgEl.style.transform = 'translate3d(-50%, ' + props.customTranslate + 'px, 0) scale(' + props.customScale + ')';
            },
        });

        instance.start();
    },

    activate2023id3: function () {
        var circleEl = this.$('.Story-circle');
        var imgEl = this.$('.Story-imgWrapper')[0];
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'middle-bottom',
            to: 'bottom-bottom',
            props: {
                customTranslate: {
                    from: 0,
                    to: -105
                },
                customScale: {
                    from: 1,
                    to: 1.5
                }
            },
            inside: function (instance, percentage, props) {
                circleEl.each(function(index, el) {
                    el.style.transform = 'scale(' + props.customScale + ')';
                });

                imgEl.style.transform = 'translate3d(0, ' + props.customTranslate + 'px, 0) scale(0.9)';
            }
        });

        instance.start();
    },

    activate2023id4: function () {
        var listItems = this.$('.Story-listItem');
        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'middle-bottom',
            to: 'bottom-bottom',
            props: {
                custom: {
                    from: 0,
                    to: 1.1,
                }
            },
            inside: function (instance, percentage, props) {
                listItems.removeClass('isAnimated');

                if (Number(props.custom * 100) >= 25) {
                    listItems.eq(0).addClass('isAnimated');
                }
                if (Number(props.custom * 100) >= 50) {
                    listItems.eq(1).addClass('isAnimated');
                }
                if (Number(props.custom * 100) >= 75) {
                    listItems.eq(2).addClass('isAnimated');
                }
                if (Number(props.custom * 100) >= 95) {
                    listItems.eq(3).addClass('isAnimated');
                }
            },
            outside: function (instance, percentage, props) {
                if (props.custom !== lastTransform) {
                    if (Number(props.custom * 100) >= 100) {
                        listItems.addClass('isAnimated');
                    }
                }
            }
        });

        instance.start();
    },

    activate2023id5: function () {
        var imgLeft = this.$('.Story-imgWrapper--rzd')[0];
        var imgRight = this.$('.Story-imgWrapper--aeroflot')[0];

        var figureLeft = this.$('.Story-imgLeft')[0];
        var figureRight = this.$('.Story-imgRight')[0];

        var lastTransform = null;

        var instance = basicScroll.create({
            elem: this.$el[0],
            direct: true,
            from: 'top-bottom',
            to: 'bottom-middle',
            props: {
                customTranslate: {
                    from: 0,
                    to: 120,
                },
                customRotate: {
                    from: 20,
                    to: 0,
                },
                figureLeftTranslate: {
                    from: 69,
                    to: 40,
                },
                figureRightTranslate: {
                    from: -105,
                    to: -55,
                }
            },
            inside: function (instance, percentage, props) {
                imgLeft.style.transform = 'translate3d(' + props.customTranslate + 'px, 0, 0) scale(1.1)';
                imgRight.style.transform = 'translate3d(' + (props.customTranslate * -1) + 'px, 0, 0)';

                figureLeft.style.transform = 'translate3d('+ props.figureLeftTranslate  +'px, 0, 0) rotate(' + props.customRotate + 'deg)';
                figureRight.style.transform = 'translate3d(' + props.figureRightTranslate + 'px, 0, 0) rotate(' + (props.customRotate * -1) + 'deg)';
            },
        });

        instance.start();
    }
});
