var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/LegalMenuLeft/LegalMenuLeft.jinja"] = require( "front/components/LegalMenuLeft/LegalMenuLeft.jinja" );
dependencies["front/components/LegalForms/LegalFormRetail/LegalFormRetail.jinja"] = require( "front/components/LegalForms/LegalFormRetail/LegalFormRetail.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/LegalRetail/LegalRetail.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/LegalRetail/LegalRetail.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/LegalRetail/LegalRetail.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/LegalRetail/LegalRetail.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "svgSprite")) {
var t_10 = t_7.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_10);
output += "\n";
env.getTemplate("front/components/LegalMenuLeft/LegalMenuLeft.jinja", false, "assets/app/front/pages/LegalRetail/LegalRetail.jinja", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(Object.prototype.hasOwnProperty.call(t_11, "LegalMenuLeft")) {
var t_14 = t_11.LegalMenuLeft;
} else {
cb(new Error("cannot import 'LegalMenuLeft'")); return;
}
context.setVariable("LegalMenuLeft", t_14);
output += "\n\n";
env.getTemplate("front/components/LegalForms/LegalFormRetail/LegalFormRetail.jinja", false, "assets/app/front/pages/LegalRetail/LegalRetail.jinja", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
t_15.getExported(function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
if(Object.prototype.hasOwnProperty.call(t_15, "LegalFormRetail")) {
var t_18 = t_15.LegalFormRetail;
} else {
cb(new Error("cannot import 'LegalFormRetail'")); return;
}
context.setVariable("LegalFormRetail", t_18);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
output += t_19;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_22,t_21) {
if(t_22) { cb(t_22); return; }
output += t_21;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
output += t_23;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_26,t_25) {
if(t_26) { cb(t_26); return; }
output += t_25;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 7;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 7, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнеров"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 7, colno = 50, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Стать партнером в рознице/сетях"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 8, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнеров"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 8, colno = 52, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Стать партнером в рознице/сетях"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 10;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n";
output += runtime.suppressValue((lineno = 11, colno = 9, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--about","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path")})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 14;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"Legal\">\n    <div class=\"Legal-floatNav\"></div>\n    <div class=\"Legal-background\">\n        <img class=\"Legal-backgroundImage\" src=\"\" srcset=\"\" alt=\"\">\n    </div>\n    <div class=\"Legal-left\">\n        ";
output += runtime.suppressValue((lineno = 21, colno = 29, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "LegalMenuLeft"), "LegalMenuLeft", context, [runtime.makeKeywordArgs({"classes": "LegalMenuLeft--legal","subpage": "retail","caller": (function (){var macro_t_27 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_28 = "";;
frame = frame.pop();
return new runtime.SafeString(t_28);
});
return macro_t_27;})()})])), env.opts.autoescape);
output += "\n    </div>\n    <div class=\"Legal-right\">\n        <div class=\"Legal-header\">\n            <div class=\"Legal-subtitle\">\n                <span class=\"Legal-subtitleStroke\"></span>\n                <div class=\"Legal-subtitleText\">";
output += runtime.suppressValue((lineno = 27, colno = 52, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Розница и сети"])), env.opts.autoescape);
output += "</div>\n            </div>\n            <h1 class=\"Legal-title\">\n                Заявка\n            </h1>\n        </div>\n        <div class=\"Legal-content\">\n            <div class=\"Legal-text\">\n                <p>Лучшее пиво для лучших клиентов!</p>\n            </div>\n\n            <div class=\"Legal-form\">\n                ";
output += runtime.suppressValue((lineno = 39, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "LegalFormRetail"), "LegalFormRetail", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_29 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_30 = "";;
frame = frame.pop();
return new runtime.SafeString(t_30);
});
return macro_t_29;})()})])), env.opts.autoescape);
output += "\n            </div>\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/LegalRetail/LegalRetail.jinja"] , dependencies)