var Backbone = require('backbone');
var _ = require('underscore');
var svg4everybody = require('svg4everybody');
var objectFitImages = require('object-fit-images/dist/ofi.common-js.js');
var Promise = require('promise-polyfill');

var Settings = require('./Settings');
var Utils = require('front/utils/Utils.js');
var CheckDevice = require('front/utils/CheckDevice/CheckDevice.js');
var Router = require('./Router');
var UtilGrid = require('front/components/UtilGrid/UtilGrid');

// Here we declare root components that are rendered on server
// and exist all the time

require('@babel/polyfill');
require('object-fit-images');
var objectFitVideos = require('object-fit-videos');
objectFitVideos();
require('front/components/Content/Content');
require('front/components/Input/Input');

require('reset.css');
require('common/fonts.less');
require('front/utils.less');
require('front/style.less');

var utilGrid = new UtilGrid();

// Requiring all SVGs for Webpack
// var svgModules = require.context('front/svg', false, /\.svg$/);
// svgModules.keys().forEach(svgModules);

// To add to Promise to window for IE
if (!window.Promise) {
    window.Promise = Promise;
}

/* Инициализация полифиллов */
// Поддержка css свойства object-fit для IE и старых браузеров.
objectFitImages();

// Поддержка svg sprites в IE.
svg4everybody();

app.configure = Settings.configure;
app.configure();

window.app.vent = _.extend({}, Backbone.Events);

window.app.state = {};
window.app.cache = {};
window.app.utils = Utils;

CheckDevice.initialize();

window.app.state = {};

// Global storage for frequently used DOM elements
// Example usage in views: app.els.$body
window.app.els = {
    $window: $(window),
    $body: $('body'),
    $htmlBody: $('html,body'),
    $content: $('.Content-body'),
    $popupContainer: $('.PopupContainer')
};

var Footer = require('front/components/Footer/Footer');
var PopupAge = require('front/components/PopupAge/PopupAge');
var TopNav = require('front/components/TopNav/TopNav');
var Notification = require('front/components/Notification/Notification.js');

app.views = {
    footer: new Footer(),
    popupAge: new PopupAge(),
    topNav: new TopNav(),
    notification: new Notification()
};

// Enabling frontend routing
app.els.$body.on('click', '.u-Route', function (e) {
    if (app.els.$body.hasClass('Custom404Page')) {
        return;
    }

    var currentRoute = Backbone.history.getFragment();
    var url =
        $(e.currentTarget).attr('href') ||
        $(e.target)
            .closest('a')
            .attr('href');

    e.preventDefault();
    Backbone.history.navigate(url, { trigger: 'true' });
});

app.router = new Router();

app.router.start();
