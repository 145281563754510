var Backbone = require('backbone/backbone');

require('./LegalForm.less');

module.exports = Backbone.View.extend({
    el: '.LegalForm',

    events: {
        'submit': 'submitForm',
        'change .js-uploadFiles': 'uploadFiles',
        'click .js-removeFile': 'removeFile',
    },

    initialize: function () {
        var _this = this;

        this.$submitBtn = this.$('.js-legalFormSubmit');

        this.$requiredFields = this.$('[data-required="true"]');
        this.$requiredFields.on('input', function() {
            _this._validateField($(this));
        });
    },

    _validateField: function(field) {
        var value = $.trim($(field).val());
        var isEmpty = value === '';

        var $el = $(field).attr('type') === 'file' ? $(field).parents('.LegalForm-file') : $(field);

        if ($el.attr('type') === 'checkbox') {
            var isChecked = $el.is(':checked');

            // Почему-то не добавляется класс непосредственно к чекбоксу…
            $el.parent().toggleClass('isInvalid', !isChecked);
        } else if ($el.attr('type') === 'email') {
            var isValidEmail = /.+@.+\..+/i.test(value);
            $el.toggleClass('isInvalid', !isValidEmail || isEmpty);
        }
        // else if ($el.attr('type') === 'tel') {
        //     var isValidPhone = /^(\+\d*|\d*)$/i.test(value);
        //     $el.toggleClass('isInvalid', !isValidPhone || isEmpty);
        // }
        else if ($el.attr('type') === 'number' || $el.attr('inputmode') === 'numeric') {
            var isValidNumber = /^\d*$/.test(value);
            $el.toggleClass('isInvalid', !isValidNumber || isEmpty);
        } else {
            $el.toggleClass('isInvalid', isEmpty);
        }
    },

    _validateForm: function() {
        var _this = this;
        var $form = this.$el;
        var isValid = true;

        $form.find('[data-required="true"]').each(function(key, field) {
            _this._validateField(field);
        });

        return $form.find('.isInvalid').length === 0;
    },

    submitForm: function (e) {
        e.preventDefault();
        var _this = this;

        var $form = this.$el;
        var formData = new FormData($form[0]);

        var isValid = this._validateForm();
        if (!isValid) {
            return;
        }

        this.$submitBtn.attr('disabled', true);
        this.$('.js-formMessage').remove();

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: formData,
            cache: false,
            processData: false,
            contentType: false,
            error: function (err) {
                _this.$submitBtn
                    .removeAttr('disabled')
                    .after('<div class="LegalForm-message LegalForm-message--error js-formMessage">Ошибка при отправке. Пожалуйста, попробуйте еще раз через 10 мин</div>');
            },
            success: function (response) {
                _this.$submitBtn.after('<div class="LegalForm-message LegalForm-message--thanks js-formMessage">Спасибо за заявку! Мы свяжемся с вами.</div>');
            }
        });
    },

    uploadFiles: function (e) {
        var $input = $(e.currentTarget),
            files = e.currentTarget.files,
            isMultiple = Boolean(e.currentTarget.dataset.multiple);

        if (files.length) {
            var template = [];
            var skip = false;
            var $filesList = $input.parents('.LegalForm-filesItem').find('.js-uploadFilesList');
            var index = $input.parents('.LegalForm-filesItem').find('.js-uploadFiles:last-of-type').data('index');

            var fileNames = [];
            $filesList.find('.LegalForm-uploadedFilesListItemName span').each(function(idx, fileNameSpan) {
                fileNames.push($(fileNameSpan).text());
            });

            for (const file of files) {
                if (fileNames.indexOf(file.name) !== -1) {
                    skip = true;

                    break;
                }

                template.push(
                    '<li class="LegalForm-uploadedFilesListItem">' +
                        '<div class="LegalForm-uploadedFilesListItemName">' +
                            '<span>' + file.name + '</span>' +
                        '</div>' +
                        '<button type="button" class="LegalForm-uploadedFilesListItemButton js-removeFile" data-single="' + isMultiple + '" data-index="' + index +'">' +
                            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path d="M9.197 9.197a.5.5 0 0 1 .707 0l2.192 2.192 2.193-2.192a.5.5 0 1 1 .707.707l-2.193 2.192 2.193 2.193a.5.5 0 1 1-.707.707l-2.193-2.193-2.192 2.193a.5.5 0 1 1-.707-.707l2.192-2.193-2.192-2.192a.5.5 0 0 1 0-.707Z"/><path fill-rule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm0-1a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" clip-rule="evenodd"/></svg>' +
                        '</button>' +
                    '</li>'
                );
            }

            if (!skip) {
                var filesList = template.join('');

                if (isMultiple) {
                    $filesList.append(filesList);
                    $input
                        .removeAttr('id')
                        .after('<input class="LegalForm-fieldFile js-uploadFiles" type="file" name="additionalFiles[]" id="legalForm-uploadAdditionalFiles" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" data-multiple="true" data-index="' + (index + 1) + '">');
                } else {
                    $input.parent().find('.LegalForm-fileUploadBtn').hide();
                    $input.parent().find('.LegalForm-fileUploadSuccessIcon').show();

                    $filesList.html(filesList);
                }
            }
        }
    },

    removeFile: function (e) {
        var $btn = $(e.currentTarget),
            isMultiple = Boolean($btn.data('single'));

        if (!isMultiple) {
            var $formFile = $btn.parents('.LegalForm-filesItem');

            $formFile.find('.LegalForm-fileUploadBtn').show();
            $formFile.find('.LegalForm-fileUploadSuccessIcon').hide();
            $formFile.find('input[type="file"]')[0].value = '';

            $btn.parent().remove();
        } else {
            $('.js-uploadFiles[data-index="' + $btn.data('index') + '"]').remove();
        }

        $btn.parent().remove();
    }
});
