var Backbone = require('backbone');
var _ = require('underscore');

var Page = require('front/pages/Page/Page');
var MenuLeft = require('front/components/MenuLeft/MenuLeft');
var TopNav = require('front/components/TopNav/TopNav');

require('./People.less');

module.exports = Page.extend({
    template: require('./People.jinja'),

    el: '.People',

    title: window.gettext('Компания') + ': ' + window.gettext('Люди'),

    events: {
        'mouseover .People-item': 'toggleDescriptionIn',
        'mouseout .People-item': 'toggleDescriptionOut'
    },

    initialize: function (options) {
        _.bindAll(this, '_backgroundParallax');

        this.menuLeft = new MenuLeft();

        this.$peopleBackgroundImage = $('.People-backgroundImage');
        this.$peoplePositionDescription = $('.People-positionDescription');
        this.$peoplePosition = $('.People-position');
        this.$peopleTextWrapper = $('.People-textWrapper');

        this.backgroundParallax = _.throttle(this._backgroundParallax, 10);
        app.els.$window.on('scroll', this.backgroundParallax);
        Page.prototype.initialize.call(this, options);
    },

    toggleDescriptionIn: function (e) {
        var height = this.$peoplePositionDescription.height();
        var fontSize = parseInt(this.$peoplePosition.css('font-size'), 10);
        $(e.currentTarget)
            .find(this.$peopleTextWrapper)
            .css('padding-bottom', height - fontSize * 2);  
    },

    toggleDescriptionOut: function (e) {
        this.$peopleTextWrapper.css('padding-bottom', 0);
    },

    _backgroundParallax: function () {
        var scrollTop = app.els.$window.scrollTop();
        scrollTop = -scrollTop * 0.3;
        var backgroundTop = scrollTop.toFixed(2) + 'px';

        this.$peopleBackgroundImage.css({
            transform: 'translate3d(0, ' + backgroundTop + ', 0)'
        });
    }
});
