var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/CareerVacancyApply/CareerVacancyApplyStep5.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_2 = "";t_2 += "\n    <h2 class=\"CommonNewForm-title\">Сопроводительное письмо</h2>\n    <div class=\"CommonNewForm-fieldRow\">\n        <div class=\"CommonNewForm-fieldGroup\">\n            <label class=\"CommonNewForm-label\" for=\"CareerVacancyApplyForm-cv\">Сопроводительное письмо</label>\n            <textarea class=\"CommonNewForm-field CommonNewForm-field--textarea\" name=\"cv\" id=\"CareerVacancyApplyForm-cv\" placeholder=\"Напишите в свободной форме о том, что вам кажется важным\"></textarea>\n        </div>\n    </div>\n\n    <div class=\"CommonNewForm-btnWrapper\">\n        <button type=\"submit\" class=\"Button\">Отправить отклик</button>\n    </div>\n\n    ";
t_2 += runtime.suppressValue((lineno = 13, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
t_2 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("CareerVacancyApplyStep5");
context.setVariable("CareerVacancyApplyStep5", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/CareerVacancyApply/CareerVacancyApplyStep5.jinja"] , dependencies)