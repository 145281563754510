var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');
var MenuLeft = require('front/components/MenuLeft/MenuLeft');
var TopNav = require('front/components/TopNav/TopNav');
var Slider = require('front/components/Slider/Slider');

require('./About.less');

module.exports = Page.extend({
    template: require('./About.jinja'),

    el: '.About',

    title: window.gettext('Компания') + ': ' + window.gettext('О нас'),

    initialize: function (options) {
        this.menuLeft = new MenuLeft();

        this.slider = new Slider({
            el: this.$('.Slider')
        });

        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        this.slider.activate();

        return Page.prototype.activate.call(this, params);
    }
});
