var Backbone = require('backbone/backbone');

require('./CareerResumeForm.less');

var CommonForm = require('front/components/CommonNewForm/CommonNewForm');

module.exports = Backbone.View.extend({
    el: '.CareerResumeForm',

    events: {
        'submit': 'submitForm',
    },

    initialize: function () {
        var _this = this;

        this.$careerForm = $('.CareerResumeForm');
        this.form = new CommonForm(this.$careerForm);
    },

    submitForm: function (e) {
        e.preventDefault();

        var successCallback = function(response) {
            var msg = '<div class="CareerResumeForm-successMessage"><b>Спасибо!</b> Мы&nbsp;получили ваше резюме и&nbsp;скоро свяжемся с&nbsp;вами!</div>';
            $('.CareerResumeForm').html(msg);
        }

        var errorCallback = function(response) {
            console.warn('error response CareerResumeForm');
            console.log(response);
        }

        this.form.submit(e, successCallback, errorCallback);
    },
});
