var Backbone = require('backbone');
var _ = require('underscore');

require('owl.carousel/dist/assets/owl.carousel.css');
require('owl.carousel');

require('./Slider.less');
var SliderCaptionTemplateIndex = require('./SliderCaption.jinja');
var SliderVariantCaption = require('./SliderVariantCaption.jinja');

module.exports = Backbone.View.extend({
    events: {
        'click .Slider-left': 'prev',
        'click .Slider-right': 'next'
    },

    initialize: function () {
        _.bindAll(this, 'onChangeSlide');

        var sliderType = $('.Slider').attr('data-id');
        switch (sliderType) {
        case 'SliderIndex':
            this.captionTemplate = SliderCaptionTemplateIndex;
            break;
        case 'SliderVariant':
            this.captionTemplate = SliderVariantCaption;
            break;
        default:
            break;
        }
    },

    activate: function () {
        this.$items = this.$('.Slider-items');
        this.$counterCurrent = this.$('.Slider-counterCurrent');
        this.$captionText = this.$('.Slider-captionText');

        this.$items.owlCarousel({
            items: 1,
            loop: true,
            smartSpeed: 500
        });

        this.$items.on('translate.owl.carousel', this.onChangeSlide);
    },

    prev: function () {
        this.$items.trigger('prev.owl.carousel');
    },

    next: function () {
        this.$items.trigger('next.owl.carousel');
    },

    onChangeSlide: function (e) {
        var currentSlide = e.page.index + 1;

        var $currentSlide = this.$('.Slider-item[data-index="' + currentSlide + '"]').eq(0);

        this.$counterCurrent.html(currentSlide);
        this.$counterCurrent.attr('data-digits-count', String(currentSlide).length);

        // When we change slides fast we don't want old captions to clutter,
        // so we remove them if there is more than 1 exiting caption
        this.$captionText.find('.Slider-captionValue.isExiting').remove();

        this.$captionText
            .find('.Slider-captionValue')
            .addClass('isExiting')
            .fadeOut(500, function () {
                $(this).remove();
            });

        // When don't want to see fadeIn of multiple captions, so if there more
        // than 1 caption fadeIn, we stop for animations for all captions except new one
        this.$captionText
            .find('.Slider-captionValue.isEntering')
            .stop(true)
            .removeClass('isEntering')
            .addClass('isExiting')
            .fadeOut(500, function () {
                $(this).remove();
            });

        var $newCaption = $(
            this.captionTemplate.render({
                slide: {
                    title: $currentSlide.attr('data-title'),
                    link: $currentSlide.attr('data-link'),
                    linkTitle: $currentSlide.attr('data-link-title')
                },
                render: true
            })
        )
            .appendTo(this.$captionText)
            .addClass('isEntering')
            .fadeIn(500, function () {
                $(this).removeClass('isEntering');
            });
    },

    destroy: function () {
        this.$items.trigger('destroy.owl.carousel');

        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }
});
