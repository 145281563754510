var Backbone =  require('backbone/backbone');
var Cookie = require('js-cookie');
require('./Notification.less');

module.exports = Backbone.View.extend({
    el: '.Notification',

    events: {
        'click .Notification-close': 'onCloseClick'
    },

    initialize: function (options) {
        this.options = options || {};

        var isCookieNotificationSeen = Cookie.get('isCookieNotificationSeen');

        if (isCookieNotificationSeen !== 'true') {
            this.$el.addClass('show');
        }
    },

    onCloseClick: function () {
        this.$el.removeClass('show');

        setTimeout(function () {
            Cookie.set('isCookieNotificationSeen', 'true', { expires: 1 });
            this.remove();
        }.bind(this), 1000);
    }
});
