var Backbone = require('backbone');
var _ = require('underscore');
var queryString = require('query-string');
require('dotdotdot');

var Page = require('front/pages/Page/Page');
var TopNav = require('front/components/TopNav/TopNav');
var TagList = require('front/components/TagList/TagList');
var PostGrid = require('front/components/PostGrid/PostGrid');
require('front/components/Button/Button');

require('./PostList.less');

module.exports = Page.extend({
    template: require('./PostList.jinja'),

    el: '.PostList',

    events: {
        'click .StyleTag': 'onChangeTag',
        'click .SectionBadge': 'onClickSectionBadge'
    },

    title: window.gettext('Медиа'),

    initialize: function (options) {
        this.options = options;

        this.data = {
            tags: app.data.tags,
            sections: app.data.sections,
            activeTags: app.data.activeTags,
            activeSection: app.data.activeSection,
            hasMore: app.data.hasMore,
            posts: app.data.posts,
            favorite: app.data.favorite,
            news: app.data.news
        };

        this.state = new Backbone.Model({
            tags: app.data.activeTags,
            section: app.data.activeSection ? app.data.activeSection : 'all'
        });

        this.listenTo(this.state, 'change:tags', this.onChangeFilters);
        this.listenTo(this.state, 'change:section', this.onChangeFilters);
        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        this.setTitle();
        app.els.$body.addClass('PostListPage');

        this.topNav = new TopNav({
            el: this.$('.TopNav'),
            type: 'sticky'
        });

        this.postGrid = new PostGrid({
            section: this.state.get('section') !== 'all' ? this.state.get('section').id : null,
            tags: this.state.get('tags')
        });

        this.$('.PostList-newsTitle').dotdotdot({
            watch: true
        });

        this.tagList = new TagList();

        this.$tags = this.$('.StyleTag');
        this.$sectionDescription = this.$('.PostList-description');
        this.$sections = this.$('.PostList-sectionItem');
        this.$main = this.$('.PostList-main');
        this.$grid = this.$('.PostList-grid');

        this.topNav.activate();

        return Page.prototype.activate.call(this, params);
    },

    update: function () {
        this.setTitle();
        app.els.$body.addClass('PostListPage');
    },

    onChangeTag: function (e) {
        var id = parseInt(
            $(e.target)
                .closest('.StyleTag')
                .attr('data-id'),
            10
        );

        var containedTags = _.findWhere(this.state.get('tags'), { id: id });
        if (containedTags) {
            var newTags = _.without(this.state.get('tags'), containedTags);
        } else {
            var addedTag = this.data.tags.filter(function (tag) {
                return tag.id === id;
            });
            var newTags = _.union(this.state.get('tags'), addedTag);
        }

        this.state.set('tags', newTags);
    },

    onChangeFilters: function () {
        this.updateGetParams();
        this.updateFilters();
        this.tagList.wrapTags();

        if (this.state.get('section').slug !== 'all' || this.state.get('tags').length) {
            this.$main.slideUp();

            app.els.$htmlBody.animate({
                scrollTop: 0
            });
        } else {
            this.$main.slideDown();
        }

        this.$grid.attr('data-animation-state', 'triggered');

        this.postGrid.filterData(this.state.toJSON()).then(function (data) {
            this.data.tags = data.tags;
            this.tagList.setTags(data.tags);
            this.$tags = this.$('.StyleTag');
            this.updateFilters();
        }.bind(this));
    },

    onClickSectionBadge: function (e) {
        e.preventDefault();
        e.stopPropagation();

        var url =
            $(e.currentTarget).attr('href') ||
            $(e.target)
                .closest('a')
                .attr('href');

        if (
            $(e.target)
                .closest('a')
                .attr('data-section') === this.state.get('section').slug
        ) {
            return;
        }

        Backbone.history.navigate(url, { trigger: 'true' });
    },

    getQueryParams: function (qs) {
        qs = qs.split('+').join(' ');

        var params = {};
        var tokens;
        var re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }

        return params;
    },

    updateSection: function (slug) {

        var tagsArg = this.getQueryParams(window.location.search).tags || '';

        if (tagsArg) {

            var tagsId = _.map(tagsArg.split(','), function (id) {
                return parseInt(id, 10);
            });

            var tags = this.data.tags.filter(function (tag) {
                return tagsId.indexOf(tag.id) != -1;
            });

            this.state.set('tags', tags);
        } else {
            this.state.set('tags', []);
        }

        var section = this.data.sections.filter(function (section) {
            return section.slug === slug;
        })[0];

        if (!section) {
            section = {
                slug: 'all'
            };
        }

        this.state.set('section', section);
    },

    updateGetParams: function () {
        var params = queryString.parse(location.search);

        if (this.state.get('tags').length) {
            params.tags = _.pluck(this.state.get('tags'), 'id').join(',');
        } else {
            delete params.tags;
        }

        var stringified = queryString.stringify(params);
        if (stringified) {
            app.router.navigate(window.location.pathname + '?' + stringified, {trigger: true});
        } else {
            app.router.navigate(window.location.pathname, {trigger: true});
        }
    },

    updateFilters: function () {
        this.$sections.removeClass('isActive');
        this.$sections
            .filter(
                function (index, el) {
                    return $(el).attr('data-section') === this.state.get('section').slug;
                }.bind(this)
            )
            .addClass('isActive');

        this.$sectionDescription.toggleClass('isActive', !!this.state.get('section').description).html(this.state.get('section').description);

        this.$tags.removeClass('isActive');
        this.$tags
            .filter(
                function (index, el) {
                    return _.chain(this.state.get('tags'))
                        .pluck('id')
                        .contains(parseInt($(el).attr('data-id'), 10))
                        .value();
                }.bind(this)
            )
            .addClass('isActive');
    }
});
