var Backbone = require('backbone');
var _ = require('underscore');

var waitForTransitionEnd = require('waitForTransitionEnd');

require('./SubscribeSections.less');

module.exports = Backbone.View.extend({
    el: '.SubscribeSections',

    events: {
        'click .SubscribeSections-close': 'close',
        'click .SubscribeSections-submit button': 'submit',
        'change .SubscribeSections-input input': 'changeSection'
    },

    initialize: function (options) {
        this.options = options || {};

        this.$content = this.$('.SubscribeSections-content');
        this.$message = this.$('.SubscribeSections-message');
        this.$submitButton = this.$('.SubscribeSections-submit .Button');

        this.sectionsState = options.sections.map(function (section) {
            return section.slug;
        });
    },

    changeSection: function (e) {
        var slug = $(e.target).val();

        if (
            this.sectionsState.filter(function (s) {
                return s === slug;
            }).length > 0
        ) {
            this.sectionsState = _.without(this.sectionsState, slug);
        } else {
            this.sectionsState.push(slug);
        }

        if (this.sectionsState.length === 0) {
            this.$submitButton.attr('disabled', 'disabled');
        } else {
            this.$submitButton.removeAttr('disabled');
        }
    },

    submit: function () {
        this.trigger('submitted');

        var data = {
            sections: this.sectionsState,
            email: this.email
        };

        console.log(data);
        this.$submitButton.addClass('Button--submitting');

        setTimeout(
            function () {
                this.$submitButton.removeClass('Button--submitting');
                this.$content.fadeOut();
                this.$message
                    .css('display', 'flex')
                    .hide()
                    .fadeIn();

                setTimeout(
                    function () {
                        this.playOut();
                    }.bind(this),
                    2000
                );
            }.bind(this),
            2000
        );
    },

    setEmail: function (email) {
        this.email = email;
    },

    close: function () {
        this.playOut();
    },

    playIn: function () {
        this.$el.addClass('isActive');
    },

    resetState: function () {
        this.$content.show();
        this.$message.hide();

        _.each(
            this.$('input'),
            function (el) {
                $(el).prop('checked', true);
            },
            this
        );

        this.sectionsState = options.sections.map(function (section) {
            return section.slug;
        });
    },

    playOut: function () {
        this.email = null;

        this.$el.removeClass('isActive');

        waitForTransitionEnd(
            this.$el,
            'transform',
            function () {
                this.resetState();
            }.bind(this),
            700
        );
    }
});
