var Backbone = require('backbone');
var _ = require('underscore');
var queryString = require('query-string');
var Utils = require('front/utils/Utils');
var Stickyfill = require('stickyfilljs');

require('front/components/StyleTag/StyleTag');
var BrandsGrid = require('front/components/BrandsGrid/BrandsGrid');

require('./BrandsList.less');

module.exports = Backbone.View.extend({
    template: require('./BrandsList.jinja'),

    events: {
        'click .BrandsList-subCategory': 'onChangeSubCategory',
        'click .StyleTag': 'onChangeStyle'
    },

    initialize: function (options) {
        _.bindAll(this, '_onResize', '_toggleStickyState');

        this.options = options || {};

        this.data = options.data;

        this.state = new Backbone.Model({
            category: this.data.category,
            subCategory: this.data.subCategory,
            styles: this.data.styles
        });

        this.listenTo(this.state, 'change:subCategory', this.onChangeFilters);
        this.listenTo(this.state, 'change:styles', this.onChangeFilters);

        this.$subCategories = this.$('.BrandsList-subCategory');
        this.$styles = this.$('.StyleTag');
        this.$stylesMenu = this.$('.BrandsList-styles');
        this.$more = this.$('.BrandsList-stylesMore');

        this.onResize = _.throttle(this._onResize, 100);
        app.els.$window.on('resize', this.onResize);

        this.wrapStyleTags();
        Stickyfill.add(this.$stylesMenu);
        this.listenToSticky();

        this.brandsGrid = new BrandsGrid();
    },

    onChangeSubCategory: function (e) {
        var slug = $(e.target).attr('data-sub-category');
        var subCategory = this.data.category.subCategories.filter(function (subCategory) {
            return subCategory.slug === slug;
        })[0];

        if (!subCategory) {
            subCategory = {
                slug: 'all'
            };
        }

        this.state.set('subCategory', subCategory);
    },

    onChangeStyle: function (e) {
        var id = parseInt(
            $(e.target)
                .closest('.StyleTag')
                .attr('data-id'),
            10
        );

        var containedStyle = _.findWhere(this.state.get('styles'), { id: id });
        if (containedStyle) {
            var newStyles = _.without(this.state.get('styles'), containedStyle);
        } else {
            var addedStyle = this.data.category.styles.filter(function (style) {
                return style.id === id;
            });
            var newStyles = _.union(this.state.get('styles'), addedStyle);
        }

        newStyles = _.sortBy(newStyles, function (i) {
            return i.id;
        });

        this.state.set('styles', newStyles);
    },

    onChangeFilters: function () {
        this.updateGetParams();
        this.updateFilters();
        this.wrapStyleTags();
        this.brandsGrid.filterData(this.state.toJSON());
    },

    updateGetParams: function () {
        var params = queryString.parse(location.search);
        if (this.state.get('subCategory').slug !== 'all') {
            params.subCategory = this.state.get('subCategory').slug;
        } else {
            delete params.subCategory;
        }

        if (this.state.get('styles').length) {
            params.styles = _.pluck(this.state.get('styles'), 'id').join(',');
        } else {
            delete params.styles;
        }

        var stringified = queryString.stringify(params);
        if (stringified) {
            app.router.navigate(window.location.pathname + '?' + stringified, {trigger: true});
        } else {
            app.router.navigate(window.location.pathname, {trigger: true});
        }
    },

    updateFilters: function () {
        this.$subCategories.removeClass('isActive');
        this.$subCategories
            .filter(
                function (index, el) {
                    return $(el).attr('data-sub-category') === this.state.get('subCategory').slug;
                }.bind(this)
            )
            .addClass('isActive');

        this.$styles.removeClass('isActive');
        this.$styles
            .filter(
                function (index, el) {
                    return _
                        .chain(this.state.get('styles'))
                        .pluck('id')
                        .contains(parseInt($(el).attr('data-id'), 10))
                        .value();
                }.bind(this)
            )
            .addClass('isActive');
    },

    wrapStyleTags: function () {
        this.$stylesList = this.$('.BrandsList-stylesList');

        var nonVisibleStyles = this.$stylesList.find('.StyleTag').filter(
            function (index, el) {
                if ($(el).position().left > this.$stylesList.width()) {
                    return true;
                }
            }.bind(this)
        );

        var ids = nonVisibleStyles.map(function () {
            return parseInt($(this).attr('data-id'), 10);
        });

        if (!ids.length) {
            this.$more.fadeOut();
        } else {
            this.$more.fadeIn();
        }

        var nonVisibleStyles = this.$('.BrandsList-stylesTooltip')
            .find('.StyleTag')
            .filter(function () {
                var id = parseInt($(this).attr('data-id'), 10);

                if (!_.contains(ids, id)) {
                    $(this).addClass('isHidden');
                } else {
                    $(this).removeClass('isHidden');
                }
            });
    },

    _onResize: function () {
        this.wrapStyleTags();
    },

    listenToSticky: function () {
        this.toggleStickyState = _.throttle(this._toggleStickyState, 25);
        app.els.$window.on('scroll', this.toggleStickyState);
    },

    _toggleStickyState: function () {
        if (app.els.$window.scrollTop() === this.$stylesMenu.offset().top) {
            this.$stylesMenu.addClass('isSticky');
        } else {
            this.$stylesMenu.removeClass('isSticky');
        }
    }
});
