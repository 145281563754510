var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/Button/Button.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["classes", "title", "tag", "href", "attrs"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");frame.set("title", Object.prototype.hasOwnProperty.call(kwargs, "title") ? kwargs["title"] : "");frame.set("tag", Object.prototype.hasOwnProperty.call(kwargs, "tag") ? kwargs["tag"] : "button");frame.set("href", Object.prototype.hasOwnProperty.call(kwargs, "href") ? kwargs["href"] : "");frame.set("attrs", Object.prototype.hasOwnProperty.call(kwargs, "attrs") ? kwargs["attrs"] : []);var t_2 = "";t_2 += "\n    <";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "tag"), env.opts.autoescape);
t_2 += " class=\"Button ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_2 += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "tag") == "a") {
t_2 += "href=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "href"), env.opts.autoescape);
t_2 += "\"";
;
}
frame = frame.push();
var t_5 = runtime.contextOrFrameLookup(context, frame, "attrs");
if(t_5) {t_5 = runtime.fromIterator(t_5);
var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("attr", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),0), env.opts.autoescape);
if(env.getFilter("length").call(context, t_6) > 1) {
t_2 += "=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),1), env.opts.autoescape);
t_2 += "\"";
;
}
;
}
}
frame = frame.pop();
t_2 += ">\n        <span class=\"Button-content\">";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
t_2 += "</span>\n        <span class=\"Button-loader\"></span>\n    </";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "tag"), env.opts.autoescape);
t_2 += ">\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("Button");
context.setVariable("Button", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/Button/Button.jinja"] , dependencies)