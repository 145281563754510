var Backbone = require('backbone/backbone');

require('./MenuLeft.less');

module.exports = Backbone.View.extend({
    el: '.MenuLeft',

    initialize: function () {
       
    }
});
