var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/Video/Video.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/Video/Video.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_5 = "";t_5 += "\n    <div class=\"Video\"\n         data-video-provider=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"videoProvider"), env.opts.autoescape);
t_5 += "\"\n         data-aspect-poster=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"aspectPoster"), env.opts.autoescape);
t_5 += "\"\n         data-aspect-real=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"aspectReal"), env.opts.autoescape);
t_5 += "\"\n         data-id=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"videoId"), env.opts.autoescape);
t_5 += "\">\n\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"videoProvider") == "vimeo") {
t_5 += "\n            <iframe class=\"Video-iframe\" id=\"player";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"videoId"), env.opts.autoescape);
t_5 += "\"\n                    src=\"https://player.vimeo.com/video/";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"videoId"), env.opts.autoescape);
t_5 += "?background=1&player_id=player";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"videoId"), env.opts.autoescape);
t_5 += "&api=1\"\n                    frameborder=\"0\">\n            </iframe>\n        ";
;
}
else {
t_5 += "\n            <div class=\"Video-iframe\" id=\"player";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"videoId"), env.opts.autoescape);
t_5 += "\"></div>\n        ";
;
}
t_5 += "\n\n        <div class=\"Video-background\" style=\"background-image: url(";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"img"), env.opts.autoescape);
t_5 += ")\"></div>\n        <div class=\"Video-blackout\"></div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("Video");
context.setVariable("Video", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/Video/Video.jinja"] , dependencies)