var Page = require('front/pages/Page/Page');
require('front/components/Link/Link');

require('./Responsible.less');

module.exports = Page.extend({
    template: require('./Responsible.jinja'),

    title: window.gettext('Об ответственном употреблении'),

    el: '.Responsible'
});
