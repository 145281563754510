var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/ButtonIcon/ButtonIcon.jinja"] = require( "front/components/ButtonIcon/ButtonIcon.jinja" );
dependencies["front/components/Button/Button.jinja"] = require( "front/components/Button/Button.jinja" );
dependencies["front/components/Slider/SliderVariant.jinja"] = require( "front/components/Slider/SliderVariant.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/Excursion/Excursion.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/Excursion/Excursion.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/Excursion/Excursion.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n\n";
env.getTemplate("front/components/ButtonIcon/ButtonIcon.jinja", false, "assets/app/front/pages/Excursion/Excursion.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "ButtonIcon")) {
var t_11 = t_8.ButtonIcon;
} else {
cb(new Error("cannot import 'ButtonIcon'")); return;
}
context.setVariable("ButtonIcon", t_11);
output += "\n";
env.getTemplate("front/components/Button/Button.jinja", false, "assets/app/front/pages/Excursion/Excursion.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "Button")) {
var t_15 = t_12.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_15);
output += "\n";
env.getTemplate("front/components/Slider/SliderVariant.jinja", false, "assets/app/front/pages/Excursion/Excursion.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "SliderVariant")) {
var t_19 = t_16.SliderVariant;
} else {
cb(new Error("cannot import 'SliderVariant'")); return;
}
context.setVariable("SliderVariant", t_19);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
output += t_20;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_23,t_22) {
if(t_23) { cb(t_23); return; }
output += t_22;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
output += t_24;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popup"))(env, context, frame, runtime, function(t_27,t_26) {
if(t_27) { cb(t_27); return; }
output += t_26;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 8, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Экскурсия"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 9, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Экскурсия"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "ExcursionPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popup(env, context, frame, runtime, cb) {
var lineno = 13;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"Excursion ";
if(!runtime.contextOrFrameLookup(context, frame, "server")) {
output += "Excursion--toAnimate";
;
}
output += "\">\n        <div class=\"Excursion-floatNav\"></div>\n        <div class=\"Excursion-background\">\n            <img class=\"Excursion-backgroundImage\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"data")),"img"), env.opts.autoescape);
output += "\"\n                srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"data")),"img")),"w1024"), env.opts.autoescape);
output += " 1024w,\n                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"data")),"img")),"w1280"), env.opts.autoescape);
output += " 1280w,\n                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"data")),"img")),"w1440"), env.opts.autoescape);
output += " 1440w,\n                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"data")),"img")),"w1920"), env.opts.autoescape);
output += " 1920w\"\n                alt=\"";
output += runtime.suppressValue((lineno = 22, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Экскурсия"])), env.opts.autoescape);
output += "\"\n                decoding=\"async\"\n            >\n        </div>\n\n        <div class=\"Excursion-header\">\n            <div class=\"Excursion-subtitle\"></div>\n        </div>\n\n        <div class=\"Excursion-left\">\n            <div class=\"Excursion-leftTitle\">\n                <span class=\"Excursion-leftTitleStroke\"></span>\n                <div class=\"Excursion-leftTitleText\">";
output += runtime.suppressValue((lineno = 34, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Экскурсия"])), env.opts.autoescape);
output += "</div>\n            </div>\n\n            <div class=\"Excursion-textSection\">\n                <h1 class=\"Excursion-title\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"title"), env.opts.autoescape);
output += "</h1>\n                <div class=\"Excursion-textSectionContent\">\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"text"))), env.opts.autoescape);
output += "\n                </div>\n            </div>\n\n            ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursIndividual")) > 0 && env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursStandart")) > 0) {
output += "<div class=\"Excursion-textSection\">\n                    <h2 class=\"Excursion-textSectionTitle\">\n                        ";
output += runtime.suppressValue((lineno = 47, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Стоимость экскурсии"])), env.opts.autoescape);
output += "\n                    </h2>\n\n                    <div class=\"Excursion-tour\">\n                        ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursStandart")) > 0) {
output += "<table class=\"Excursion-tourTable\">\n                                <tr>\n                                    <th>";
output += runtime.suppressValue((lineno = 54, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["В составе общей группы"])), env.opts.autoescape);
output += "</th>\n                                    <td>";
output += runtime.suppressValue((lineno = 55, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Будни"])), env.opts.autoescape);
output += "</td>\n                                    <td>";
output += runtime.suppressValue((lineno = 56, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Выходные"])), env.opts.autoescape);
output += "</td>\n                                </tr>\n                                ";
frame = frame.push();
var t_30 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursStandart");
if(t_30) {t_30 = runtime.fromIterator(t_30);
var t_29 = t_30.length;
for(var t_28=0; t_28 < t_30.length; t_28++) {
var t_31 = t_30[t_28];
frame.set("row", t_31);
frame.set("loop.index", t_28 + 1);
frame.set("loop.index0", t_28);
frame.set("loop.revindex", t_29 - t_28);
frame.set("loop.revindex0", t_29 - t_28 - 1);
frame.set("loop.first", t_28 === 0);
frame.set("loop.last", t_28 === t_29 - 1);
frame.set("loop.length", t_29);
output += "<tr>\n                                        <td>";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((t_31),"people"),"–",true), env.opts.autoescape);
output += "</td>\n                                        <td>";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((t_31),"workdays"),"–",true), env.opts.autoescape);
output += "</td>\n                                        <td>";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((t_31),"weekends"),"–",true), env.opts.autoescape);
output += "</td>\n                                    </tr>\n                                ";
;
}
}
frame = frame.pop();
output += "</table>\n                        ";
;
}
output += "<div class=\"Excursion-tourNote\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursStandartNote"), env.opts.autoescape);
output += "</div>\n                    </div>\n\n                    <div class=\"Excursion-tour\">\n                        ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursIndividual")) > 0) {
output += "<table class=\"Excursion-tourTable\">\n                                <tr>\n                                    <th>";
output += runtime.suppressValue((lineno = 75, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Только для вас"])), env.opts.autoescape);
output += "</th>\n                                    <td>";
output += runtime.suppressValue((lineno = 76, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Будни"])), env.opts.autoescape);
output += "</td>\n                                    <td>";
output += runtime.suppressValue((lineno = 77, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Выходные"])), env.opts.autoescape);
output += "</td>\n                                </tr>\n                                ";
frame = frame.push();
var t_34 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursIndividual");
if(t_34) {t_34 = runtime.fromIterator(t_34);
var t_33 = t_34.length;
for(var t_32=0; t_32 < t_34.length; t_32++) {
var t_35 = t_34[t_32];
frame.set("row", t_35);
frame.set("loop.index", t_32 + 1);
frame.set("loop.index0", t_32);
frame.set("loop.revindex", t_33 - t_32);
frame.set("loop.revindex0", t_33 - t_32 - 1);
frame.set("loop.first", t_32 === 0);
frame.set("loop.last", t_32 === t_33 - 1);
frame.set("loop.length", t_33);
output += "<tr>\n                                        <td>";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((t_35),"people"),"–",true), env.opts.autoescape);
output += "</td>\n                                        <td>";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((t_35),"workdays"),"–",true), env.opts.autoescape);
output += "</td>\n                                        <td>";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((t_35),"weekends"),"–",true), env.opts.autoescape);
output += "</td>\n                                    </tr>\n                                ";
;
}
}
frame = frame.pop();
output += "</table>\n                        ";
;
}
output += "<div class=\"Excursion-tourNote\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursIndividualNote"), env.opts.autoescape);
output += "</div>\n                    </div>\n\n                    <div class=\"Excursion-buyButtonWrapper\">\n                        ";
output += runtime.suppressValue((lineno = 93, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Button"), "Button", context, [runtime.makeKeywordArgs({"title": (runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "ru"?(lineno = 94, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Купить билет"])):((runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "zh-tw"?(lineno = 94, colno = 85, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["买票"])):((runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "zh-hans"?(lineno = 94, colno = 128, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["买票"])):(lineno = 94, colno = 171, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Buy a ticket"]))))))),"classes": "Button--tiny Excursion-button js-excursionBuyTicket","attrs": [["data-tc-showroom",""],["data-tc-token","eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSJ9.eyJwIjoiNjA0YjI1NWFiZTcxYzlmOWE1ZjhhZjA2In0.ykLp9ZAqkfuOjP1EcTJXXy3llmBE_kQ3-xJFLFKNqyY"]]})])), env.opts.autoescape);
output += "\n\n                        <div class=\"Excursion-buyButtonWrapperNote\">\n                            ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"buyButtonNote"))), env.opts.autoescape);
output += "\n                        </div>\n                    </div>\n\n                    <div class=\"Excursion-asideInfoBlocks Excursion-asideInfoBlocks--tickets\">\n                        <div class=\"Excursion-asideInfo\">\n                            <div class=\"Excursion-asideInfoIcon Excursion-asideInfoIcon--docs\">\n                                ";
output += runtime.suppressValue((lineno = 110, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["excursion-docs"])), env.opts.autoescape);
output += "\n                            </div>\n                            <div class=\"Excursion-asideInfoText\">\n                                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"docs"), env.opts.autoescape);
output += "\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            ";
;
}
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"features")) > 0) {
output += "<div class=\"Excursion-textSection\">\n                    <div class=\"Excursion-textSectionTitle\">\n                        ";
output += runtime.suppressValue((lineno = 123, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Что ждёт вас на экскурсии?"])), env.opts.autoescape);
output += "\n                    </div>\n\n                    ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"features")) > 1) {
output += "<ul class=\"Excursion-featuresList\">\n                        ";
frame = frame.push();
var t_38 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"features");
if(t_38) {t_38 = runtime.fromIterator(t_38);
var t_37 = t_38.length;
for(var t_36=0; t_36 < t_38.length; t_36++) {
var t_39 = t_38[t_36];
frame.set("feature", t_39);
frame.set("loop.index", t_36 + 1);
frame.set("loop.index0", t_36);
frame.set("loop.revindex", t_37 - t_36);
frame.set("loop.revindex0", t_37 - t_36 - 1);
frame.set("loop.first", t_36 === 0);
frame.set("loop.last", t_36 === t_37 - 1);
frame.set("loop.length", t_37);
output += "<li>";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((t_39),"text"))), env.opts.autoescape);
output += "</li>\n                        ";
;
}
}
frame = frame.pop();
output += "</ul>\n                    ";
;
}
else {
output += "\n                    <div class=\"Excursion-textSectionContent\">\n                        <p>";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"features")),0)),"text"))), env.opts.autoescape);
output += "</p>\n                    </div>\n                    ";
;
}
output += "\n\n                    <div class=\"Excursion-asideInfoBlocks\">\n                        <div class=\"Excursion-asideInfo\">\n                            <div class=\"Excursion-asideInfoTime\">\n                                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"durationTime"), env.opts.autoescape);
output += "\n                            </div>\n                            <div class=\"Excursion-asideInfoText\">\n                                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"durationText")), env.opts.autoescape);
output += "\n                            </div>\n                        </div>\n\n                        <div class=\"Excursion-asideInfo\">\n                            <div class=\"Excursion-asideInfoIcon Excursion-asideInfoIcon--shoot\">\n                                ";
output += runtime.suppressValue((lineno = 150, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["excursion-shoot"])), env.opts.autoescape);
output += "\n                            </div>\n                            <div class=\"Excursion-asideInfoText\">\n                                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"shoot"), env.opts.autoescape);
output += "\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            ";
;
}
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"slides")) > 0) {
output += "<div class=\"Excursion-slider\">\n                    ";
output += runtime.suppressValue((lineno = 162, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SliderVariant"), "SliderVariant", context, [runtime.makeKeywordArgs({"slides": runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"slides"),"classes": "Slider--excursion"})])), env.opts.autoescape);
output += "\n                </div>";
;
}
output += "\n\n            <div class=\"Excursion-bus\">\n                ";
output += runtime.suppressValue((lineno = 167, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["Bus"])), env.opts.autoescape);
output += "\n            </div>\n\n            <div class=\"Excursion-textSection Excursion-textSection--beforeMap\">\n                <h2 class=\"Excursion-textSectionTitle\">\n                    ";
output += runtime.suppressValue((lineno = 172, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Как добраться?"])), env.opts.autoescape);
output += "\n                </h2>\n\n                <div class=\"Excursion-textSectionContent\">\n                    <h3>\n                        ";
output += runtime.suppressValue((lineno = 177, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бесплатный автобус"])), env.opts.autoescape);
output += "\n                    </h3>\n\n\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"bus"))), env.opts.autoescape);
output += "\n                </div>\n\n                <div class=\"Excursion-asideInfoBlocks Excursion-asideInfoBlocks--time\">\n                    <div class=\"Excursion-asideInfo\">\n                        <div class=\"Excursion-asideInfoIcon Excursion-asideInfoIcon--time\">\n                            ";
output += runtime.suppressValue((lineno = 187, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["excursion-time"])), env.opts.autoescape);
output += "\n                        </div>\n                        <div class=\"Excursion-asideInfoText\">\n                            ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"busSchedule")), env.opts.autoescape);
output += "\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"Excursion-map\">\n                <p>На&nbsp;карте показан маршрут до&nbsp;остановки фирменного автобуса:</p>\n\n                <div class=\"Excursion-mapContainerWrapper\">\n                    <div class=\"Excursion-mapContainer\" id=\"map\"></div>\n                </div>\n            </div>\n\n            <div class=\"Excursion-textSection\">\n                <div class=\"Excursion-textSectionContent\">\n                    <h3>";
output += runtime.suppressValue((lineno = 206, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["На машине"])), env.opts.autoescape);
output += "</h3>\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"car"))), env.opts.autoescape);
output += "\n                </div>\n                <div class=\"Excursion-textSectionContent\">\n                    <h3>\n                        ";
output += runtime.suppressValue((lineno = 211, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Общественный транспорт"])), env.opts.autoescape);
output += "\n                    </h3>\n\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"common"))), env.opts.autoescape);
output += "\n                </div>\n            </div>\n\n            <div class=\"Excursion-textSection\">\n                <h2 class=\"Excursion-textSectionTitle\">Политика возврата билетов</h2>\n                <div class=\"Excursion-textSectionContent\">\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"returnPolicy"))), env.opts.autoescape);
output += "\n                </div>\n            </div>\n\n            <div class=\"Excursion-textSection\" id=\"excursion-faq\">\n                <div class=\"Excursion-textSectionTitle\">Часто задаваемые вопросы</div>\n                <div class=\"Excursion-faq FAQ\">\n                    ";
frame = frame.push();
var t_42 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"questions");
if(t_42) {t_42 = runtime.fromIterator(t_42);
var t_41 = t_42.length;
for(var t_40=0; t_40 < t_42.length; t_40++) {
var t_43 = t_42[t_40];
frame.set("question", t_43);
frame.set("loop.index", t_40 + 1);
frame.set("loop.index0", t_40);
frame.set("loop.revindex", t_41 - t_40);
frame.set("loop.revindex0", t_41 - t_40 - 1);
frame.set("loop.first", t_40 === 0);
frame.set("loop.last", t_40 === t_41 - 1);
frame.set("loop.length", t_41);
output += "<div class=\"FAQ-item\">\n                            <div class=\"FAQ-header\">\n                                <div class=\"FAQ-headerTitle\">";
output += runtime.suppressValue(runtime.memberLookup((t_43),"question"), env.opts.autoescape);
output += "</div>\n                                <div class=\"FAQ-headerCross\">\n                                    ";
output += runtime.suppressValue((lineno = 233, colno = 48, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["career-cross"])), env.opts.autoescape);
output += "\n                                </div>\n                            </div>\n                            <div class=\"FAQ-answer\">\n                                <div class=\"FAQ-answerContent\">\n                                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((t_43),"answer"))), env.opts.autoescape);
output += "\n                                </div>\n                            </div>\n                        </div>\n                    ";
;
}
}
frame = frame.pop();
output += "</div>\n            </div>\n        </div>\n\n        <div class=\"Excursion-right\">\n            <aside class=\"Excursion-contacts\">\n                <h3 class=\"Excursion-contactsTitle\">Контакты</h3>\n                <ul class=\"Excursion-contactsList\">\n                    <li class=\"Excursion-contactsListItem\">\n                        <div class=\"Excursion-contactsListLabel\">Пишите:</div>\n                        <div class=\"Excursion-contactsListContent\">\n                            <a class=\"Excursion-contactsListLink\" href=\"mailto:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"email"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"email"), env.opts.autoescape);
output += "</a>\n                            <a class=\"Excursion-contactsListLink\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"whatsapp"), env.opts.autoescape);
output += "\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">\n                                <span class=\"Excursion-contactsListIcon\">\n                                    ";
output += runtime.suppressValue((lineno = 257, colno = 48, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["excursion-contacts-whatsapp"])), env.opts.autoescape);
output += "\n                                </span>\n                                WhatsApp\n                            </a>\n                            <a class=\"Excursion-contactsListLink\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"telegram"), env.opts.autoescape);
output += "\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">\n                                <span class=\"Excursion-contactsListIcon\">\n                                    ";
output += runtime.suppressValue((lineno = 263, colno = 48, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["excursion-contacts-tg"])), env.opts.autoescape);
output += "\n                                </span>\n                                Telegram\n                            </a>\n                        </div>\n                    </li>\n                    <li class=\"Excursion-contactsListItem\">\n                        <div class=\"Excursion-contactsListLabel\">Звоните:</div>\n                        <div class=\"Excursion-contactsListContent\">\n                            <a class=\"Excursion-contactsListLink\" href=\"tel:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"phoneValue"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"phone"), env.opts.autoescape);
output += "</a>\n                        </div>\n                    </li>\n                </ul>\n\n                <div class=\"Excursion-contactsText\">\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"contactsDescription"))), env.opts.autoescape);
output += "\n                </div>\n\n                <a class=\"Button Button--tiny Excursion-button Excursion-button--black\" href=\"#excursion-faq\">Частые вопросы</a>\n            </aside>\n        </div>\n\n        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"disclaimerTitle")) {
output += "\n        <div class=\"Excursion-disclaimer is-showed\">\n            <div class=\"Excursion-disclaimerContent\">\n                <button type=\"button\" class=\"Excursion-disclaimerClose\" aria-label=\"Закрыть дисклеймер\">\n                    <svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" aria-hidden=\"true\" focusable=\"false\">\n                        <path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.7155 9.99939L19.9991 0.715787L19.2833 0L9.9997 9.2836L0.716699 0.000606046L0.000912092 0.716393L9.28391 9.99939L0 19.2833L0.715787 19.9991L9.9997 10.7152L19.2842 19.9997L20 19.2839L10.7155 9.99939Z\" fill=\"black\"/>\n                    </svg>\n                </button>\n\n                <h2 class=\"Excursion-disclaimerContentTitle\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"disclaimerTitle"), env.opts.autoescape);
output += "</h2>\n                <div class=\"Excursion-disclaimerContentText\">\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"disclaimerText"))), env.opts.autoescape);
output += "\n                </div>\n            </div>\n        </div>\n        ";
;
}
output += "\n\n        <div class=\"Excursion-fixedButton\">\n            <div class=\"Excursion-fixedButtonColumn\">\n                ";
output += runtime.suppressValue((lineno = 304, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Button"), "Button", context, [runtime.makeKeywordArgs({"title": (runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "ru"?(lineno = 305, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Расписание и билеты"])):((runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "zh-tw"?(lineno = 305, colno = 84, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["买票"])):((runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "zh-hans"?(lineno = 305, colno = 127, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["买票"])):(lineno = 305, colno = 170, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Buy a ticket"]))))))),"classes": "Button--tiny Excursion-buyButton","attrs": [["data-tc-showroom",""],["data-tc-token","eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSJ9.eyJwIjoiNjA0YjI1NWFiZTcxYzlmOWE1ZjhhZjA2In0.ykLp9ZAqkfuOjP1EcTJXXy3llmBE_kQ3-xJFLFKNqyY"]]})])), env.opts.autoescape);
output += "\n            </div>\n        </div>\n\n        <div class=\"Excursion-close\">";
output += runtime.suppressValue((lineno = 315, colno = 50, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "ButtonIcon"), "ButtonIcon", context, [])), env.opts.autoescape);
output += "</div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_body_class: b_body_class,
b_popup: b_popup,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/Excursion/Excursion.jinja"] , dependencies)