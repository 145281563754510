var Backbone = require('backbone');
var _ = require('underscore');

var Page = require('front/pages/Page/Page');
var waitForTransitionEnd = require('waitForTransitionEnd');
var Subscribe = require('front/components/Subscribe/Subscribe');
var Share = require('front/components/Share/Share');
var Slider = require('front/components/Slider/Slider');
var SubscribeSections = require('front/components/SubscribeSections/SubscribeSections');

require('./Post.less');
var template = require('./Post.jinja');

module.exports = Page.extend({
    template: template,

    el: '.Post',

    events: {
        'click .Post-close': 'closePost',

        'click .Post-share--fb': 'onShareFacebookClick',
        'click .Post-share--tw': 'onShareTwitterClick',
        'click .Post-share--tg': 'onShareTelegramClick',
        'click .Post-share--vk': 'onShareVKClick'
    },

    apiUrl: function () {
        return '/api/post/' + this.options.id + '/?lang=' + app.data.lang;
    },

    initialize: function (options) {
        Page.prototype.initialize.call(this, options);

        _.bindAll(this, '_backgroundParallax');
        this.options = options || {};
        this.data = undefined;
        this.post = this.options.data.post;
        this.sections = this.options.data.sections;
        this.prevScrollPosition = 0;
        this.backgroundParallax = _.throttle(this._backgroundParallax, 10);
    },

    activate: function () {
        this.share = new Share({
            data: {}
        });

        this.subscribe = new Subscribe({
            el: this.$('.Subscribe')
            // type: 'post'
        });
        // this.listenTo(this.subscribe, 'submitted', this.submittedEmail);

        // this.subscribeSections = new SubscribeSections({
        //     el: this.$('.SubscribeSections'),
        //     sections: this.sections
        // });
        // this.listenTo(this.subscribeSections, 'submitted', this.submittedSections);

        this.sliders = _.map(
            this.$('.Slider'),
            function (el, index) {
                return new Slider({
                    el: $(el)
                });
            },
            this
        );

        this.sliders.forEach(function (view) {
            view.activate();
        });

        this.share.updateView({
            data: {
                title: window.gettext('Медиа') + ': ' + (this.post && this.post.title || this.data && this.data.post && this.data.post.title),
                link: this.post && this.post.link || this.data && this.data.post && this.data.post.link
            }
        });

        this.$topImage = this.$('.Post-img img');
        Page.prototype.activate.call(this);
    },

    _backgroundParallax: function () {
        var scrollTop = app.els.$window.scrollTop();
        var backgroundTop = (scrollTop * 0.3).toFixed(2) + 'px';

        this.$topImage.css({
            transform: 'translate3d(0, ' + backgroundTop + ', 0)'
        });
    },

    submittedEmail: function (email) {
        this.subscribeSections.setEmail(email);
        this.subscribeSections.playIn();
    },

    submittedSections: function () {
        this.subscribe.resetState();
    },

    playIn: function () {
        return new Promise(
            function (resolve) {
                var $popupContainer = $('.PopupContainer');
                var $content = $('.Content');
                var $contentMain = $('.Content-main');
                var $topNavInner = $('.TopNav--floatNav,.TopNav--fixed').find('.TopNav-inner');

                var loadPromise = new Promise(function (resolve) {
                    resolve();
                });

                // If is frontend append the new view and add animation class
                if (!this.options.server) {
                    loadPromise = this.loadData().then(
                        function () {
                            return $popupContainer.append(this.render().el);
                        }.bind(this)
                    );
                }

                loadPromise.then(
                    function () {
                        waitForTransitionEnd(
                            $topNavInner,
                            'transform',
                            function () {
                                $topNavInner.css('visibility', 'hidden');
                            },
                            300
                        );
                        $topNavInner.addClass('TopNav-inner--isInactive');
                        $content.addClass('Content--scalingOut');
                        app.els.$body.addClass('PostPage');

                        this.prevScrollPosition = app.els.$window.scrollTop();

                        this.$el.outerHeight();
                        this.$el.removeClass('Post--toAnimate');

                        this.title = window.gettext('Медиа') + ': ' + (this.post && this.post.title || this.data && this.data.post && this.data.post.title);
                        this.setTitle();

                        var waitForTransition = new Promise(function (resolve) {
                            resolve();
                        });

                        if (!this.options.server) {
                            waitForTransition = new Promise(function (resolve) {
                                waitForTransitionEnd(
                                    $contentMain,
                                    'transform',
                                    function () {
                                        resolve();
                                    },
                                    600
                                );
                            });
                        }

                        waitForTransition.then(
                            function () {
                                this.activate();
                                app.els.$window.scrollTop(0);

                                setTimeout(
                                    function () {
                                        $contentMain.hide();
                                        this.$el.css('position', 'relative');
                                        app.els.$window.on('scroll', this.backgroundParallax);

                                        resolve();
                                    }.bind(this),
                                    25
                                );
                            }.bind(this)
                        );
                    }.bind(this)
                );
            }.bind(this)
        );
    },

    playOut: function () {
        return new Promise(
            function (resolve) {
                var $contentMain = $('.Content-main');
                var $topNavInner = $('.TopNav--floatNav,.TopNav--fixed').find('.TopNav-inner');
                var $content = $('.Content');
                app.els.$body.removeClass('PostPage');

                $contentMain.outerHeight();
                $contentMain.show();

                this.$el.outerHeight();
                this.$el.css({
                    position: 'fixed',
                    transform: 'translateY(' + (app.els.$window.scrollTop() + window.innerHeight) + 'px)'
                });
                $content.removeClass('Content--scalingOut');

                $topNavInner.css('visibility', 'visible');
                $topNavInner.removeClass('TopNav-inner--isInactive');

                $contentMain.css({
                    position: 'relative',
                    left: 0,
                    right: 0,
                    top: 0
                });

                app.els.$window.scrollTop(this.prevScrollPosition);

                var waitForTransition = new Promise(function (resolve) {
                    waitForTransitionEnd(
                        $contentMain,
                        'transform',
                        function () {
                            app.els.$window.off('scroll', this.backgroundParallax);
                            resolve();
                        }.bind(this),
                        600
                    );
                }.bind(this));

                waitForTransition.then(
                    function () {
                        this.sliders.forEach(function (slider) {
                            slider.destroy();
                        });

                        this.deactivate();
                        resolve();
                    }.bind(this)
                );
            }.bind(this)
        );
    },

    updatePost: function (id) {
        this.playOut().then(function () {
            this.options.id = id;
            this.options.server = false;
            this.playIn();
        }.bind(this));
    },

    closePopup: function () {
        this.closePost();
    },

    closePost: function () {
        if (app.state.oldUrl === undefined) {
            Backbone.history.navigate(Urls['posts:index'](), { trigger: 'true' });
        }

        Backbone.history.navigate(app.state.oldUrl, { trigger: 'true' });
    },

    getShareData: function () {
        var title = window.gettext('Медиа') + ': ' + this.post.title;
        var link = this.post.link;

        var data = {
            title: title,
            link: (link[0] == '/' ? window.location.origin : '') + link
        };

        return data;
    },

    onShareFacebookClick: function () {
        this.shareFacebook(this.getShareData());
    },

    onShareTwitterClick: function () {
        this.shareTwitter(this.getShareData());
    },

    onShareTelegramClick: function () {
        this.shareTelegram(this.getShareData());
    },

    onShareVKClick: function () {
        this.shareVK(this.getShareData());
    },

    shareFacebook: function (data) {
        window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(data.link),
            'facebooksharedialog',
            'width=626,height=436'
        );
    },

    shareTwitter: function (data) {
        var tweetUrl =
            'https://twitter.com/share' +
            '?url=' +
            encodeURIComponent(data.link) +
            '&text=' +
            encodeURIComponent(data.title);

        window.open(tweetUrl, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
    },

    shareTelegram: function (data) {
        var telegramUrl =
            'https://t.me/share/url' +
            '?url=' +
            encodeURIComponent(data.link) +
            '&text=' +
            encodeURIComponent(data.title);

        window.open(telegramUrl, 'width=626,height=436');
    },

    shareVK: function (data) {
        var vkUrl =
            'http://vk.com/share.php' +
            '?url=' +
            encodeURIComponent(data.link) +
            '&title=' +
            encodeURIComponent(data.title);

        window.open(vkUrl, 'width=626,height=436');
    }
});
