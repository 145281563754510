var Backbone = require('backbone');
var _ = require('underscore');

var Page = require('front/pages/Page/Page');
var waitForTransitionEnd = require('waitForTransitionEnd');
var StyleTag = require('front/components/StyleTag/StyleTag');
var Locations = require('front/components/Locations/Locations');
var Utils = require('front/utils/Utils');

require('./LocationsPopup.less');
var template = require('./LocationsPopup.jinja');

module.exports = Page.extend({
    template: template,

    el: '.LocationsPopup',

    title: window.gettext('Где попробовать'),

    events: {
        'click .LocationsPopup-close': 'closeLocations',
        'change .LocationsPopup-category input': 'onChangeCategory',
        'change .LocationsPopup-subCategory input': 'onChangeSubCategory',
        'click .StyleTag': 'onChangeTag'
    },

    apiUrl: function () {
        return '/api/locations/';
    },

    initialize: function (options) {

        Page.prototype.initialize.call(this, options);

        if (options.server) {
            this.data = {}; //prevent api call for data when opens from direct url
            this.data.brands = this.options.data.brands;
            this.data.beer = this.options.data.beer;
            this.data.ciders = this.options.data.ciders;
            this.data.locations = this.options.data.locations;
        }

        this.options = options || {};
        this.prevScrollPosition = 0;

        this.state = {
            locations: [],
            brands: [],
            subCategories: [],
            category: 'beer'
        };
    },

    onChangeCategory: function (e) {
        e.stopPropagation();

        this.state.category = $(e.target).val();
        this.state.subCategories = this.data[this.state.category].subCategories.map(function (item) {
            return item.slug;
        });
        this.state.brands = [];

        this.resetView();
        this.filterLocations();
    },

    onChangeSubCategory: function (e) {
        e.stopPropagation();

        var value = $(e.target).val();

        if (_.contains(this.state.subCategories, value)) {
            this.state.subCategories = _.without(this.state.subCategories, value);
        } else {
            this.state.subCategories.push(value);
        }

        this.filterLocations();
    },

    onChangeTag: function (e) {
        e.stopPropagation();

        var $el = $(e.target).closest('.StyleTag');
        var value = parseInt($el.attr('data-id'), 10);

        if (_.contains(this.state.brands, value)) {
            this.state.brands = _.without(this.state.brands, value);
            $el.removeClass('isActive');
        } else {
            this.state.brands.push(value);
            $el.addClass('isActive');
        }

        this.filterLocations();
    },

    calcLocations: function (brands) {

        var allLocations =  this.data.brands && this.data.brands
            .filter(
                function (b) {
                    var sameCategory = b.category === this.state.category;
                    var inSubcategories = _.contains(this.state.subCategories, b.subCategory);

                    if (this.state.brands.length) {
                        return sameCategory && inSubcategories && this.state.brands.indexOf(b.id) !== -1;
                    } else {
                        return sameCategory && inSubcategories;
                    }
                }.bind(this)
            )
            .reduce(
                function (prevValue, currentValue) {
                    return prevValue.concat(currentValue.locations);
                }.bind(this),
                []
             );

        return _.uniq(allLocations);

        return _.uniq(allLocations).map(function (id) {
            return _.findWhere(this.data.locations, { id: id });
        }.bind(this));
    },

    filterLocations: function () {
        this.state.locations = this.calcLocations(this.data.brands);

        this.locations.updateView({
            locations: this.state.locations
        });
    },

    resetView: function () {
        this.$('.LocationsPopup-filters').hide();
        this.$('.LocationsPopup-filters--' + this.state.category).show();

        this.$('.LocationsPopup-subCategory input').prop('checked', true);
        this.$('.StyleTag').removeClass('isActive');
    },

    activate: function () {
        this.locations = new Locations({
            el: this.$('.Locations'),
            type: 'popup',
            data: {
                locations: this.data.locations
            }
        });

        Promise.all([
            this.locations.initMap(),
            this.locations.loadLocations()
        ])
        .then(function (results) {
            _.extend(this.data, results[1]);
            this.locations.initMarkers(results[1]);
            this.$el.addClass('LocationsPopup--loaded');
        }.bind(this));

        Page.prototype.activate.call(this);
    },

    playIn: function () {
        return new Promise(
            function (resolve) {
                var $popupContainer = $('.PopupContainer');
                var $content = $('.Content');
                var $contentMain = $('.Content-main');
                var $topNavInner = $('.TopNav--floatNav,.TopNav--fixed').find('.TopNav-inner');

                var loadPromise = new Promise(function (resolve) {
                    resolve();
                });

                // If is frontend append the new view and add animation class
                if (!this.options.server) {
                    loadPromise = this.loadData().then(
                        function () {
                            return $popupContainer.append(this.render().el);
                        }.bind(this)
                    );
                }

                loadPromise.then(
                    function () {
                        waitForTransitionEnd(
                            $topNavInner,
                            'transform',
                            function () {
                                $topNavInner.css('visibility', 'hidden');
                                $content.children('.Notify').remove();
                            },
                            300
                        );
                        $topNavInner.addClass('TopNav-inner--isInactive');
                        $content.addClass('Content--scalingOut');

                        this.prevScrollPosition = app.els.$window.scrollTop();

                        this.$el.outerHeight();
                        this.$el.removeClass('LocationsPopup--toAnimate');

                        this.state.subCategories = this.data.beer && this.data.beer.subCategories &&
                            this.data.beer.subCategories.map(function (item) {
                                return item.slug;
                            }) || [];
                        this.state.locations = this.calcLocations(this.data.brands);

                        this.setTitle();

                        var waitForTransition = new Promise(function (resolve) {
                            waitForTransitionEnd(
                                $contentMain,
                                'transform',
                                function () {
                                    resolve();
                                },
                                600
                            );
                        });

                        waitForTransition.then(
                            function () {
                                this.activate();
                                app.els.$window.scrollTop(0);

                                setTimeout(
                                    function () {
                                        $contentMain.hide();
                                        // this.$el.css('position', 'relative');

                                        resolve();
                                    }.bind(this),
                                    25
                                );
                            }.bind(this)
                        );
                    }.bind(this)
                );
            }.bind(this)
        );
    },

    playOut: function () {
        return new Promise(
            function (resolve) {
                var $contentMain = $('.Content-main');
                var $topNavInner = $('.TopNav--floatNav,.TopNav--fixed').find('.TopNav-inner');
                var $content = $('.Content');
                app.els.$body.removeClass('LocationsPage');

                $contentMain.outerHeight();
                $contentMain.show();

                this.$el.outerHeight();
                this.$el.css({
                    position: 'fixed',
                    transform: 'translateY(' + (app.els.$window.scrollTop() + window.innerHeight) + 'px)'
                });
                $content.removeClass('Content--scalingOut');

                $topNavInner.css('visibility', 'visible');
                $topNavInner.removeClass('TopNav-inner--isInactive');

                $contentMain.css({
                    position: 'relative',
                    left: 0,
                    right: 0,
                    top: 0
                });

                app.els.$window.scrollTop(this.prevScrollPosition);

                var waitForTransition = new Promise(function (resolve) {
                    waitForTransitionEnd(
                        $contentMain,
                        'transform',
                        function () {
                            resolve();
                        },
                        600
                    );
                });

                waitForTransition.then(
                    function () {
                        this.locations.destroy();
                        this.locations = null;

                        this.deactivate({destroy:true});
                    }.bind(this)
                );

                resolve();
            }.bind(this)
        );
    },

    closePopup: function () {
        this.closeLocations();
    },

    closeLocations: function () {
        if (app.state.oldUrl === undefined) {
            Backbone.history.navigate(Urls['front:index'](), { trigger: 'true' });
        }

        Backbone.history.navigate(app.state.oldUrl, { trigger: 'true' });
    }

    // loadData: function () {
    //     Page.prototype.loadData.call(this);
    //
    //     return Utils.createEmptyPromise();
    // }
});
