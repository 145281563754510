var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Grid/Grid.jinja"] = require( "front/components/Grid/Grid.jinja" );
dependencies["front/components/TopNav/TopNav.jinja"] = require( "front/components/TopNav/TopNav.jinja" );
dependencies["front/components/MenuLeft/MenuLeft.jinja"] = require( "front/components/MenuLeft/MenuLeft.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/People/People.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/People/People.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/People/People.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n\n";
env.getTemplate("front/components/Grid/Grid.jinja", false, "assets/app/front/pages/People/People.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "Grid")) {
var t_10 = t_7.Grid;
} else {
cb(new Error("cannot import 'Grid'")); return;
}
context.setVariable("Grid", t_10);
output += "\n";
env.getTemplate("front/components/TopNav/TopNav.jinja", false, "assets/app/front/pages/People/People.jinja", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(Object.prototype.hasOwnProperty.call(t_11, "TopNav")) {
var t_14 = t_11.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_14);
output += "\n";
env.getTemplate("front/components/MenuLeft/MenuLeft.jinja", false, "assets/app/front/pages/People/People.jinja", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
t_15.getExported(function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
if(Object.prototype.hasOwnProperty.call(t_15, "MenuLeft")) {
var t_18 = t_15.MenuLeft;
} else {
cb(new Error("cannot import 'MenuLeft'")); return;
}
context.setVariable("MenuLeft", t_18);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
output += t_19;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_22,t_21) {
if(t_22) { cb(t_22); return; }
output += t_21;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
output += t_23;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_26,t_25) {
if(t_26) { cb(t_26); return; }
output += t_25;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 8, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Компания"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 8, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Люди"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 9, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Компания"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 9, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Люди"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    ";
output += runtime.suppressValue((lineno = 12, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path")})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 15;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"People\">\n        <div class=\"People-floatNav\"></div>\n\n        <div class=\"People-background\">\n            <img class=\"People-backgroundImage\"\n            src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peopleData")),"img"), env.opts.autoescape);
output += "\"\n            srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peopleData")),"img")),"w1024"), env.opts.autoescape);
output += " 1024w,\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peopleData")),"img")),"w1280"), env.opts.autoescape);
output += " 1280w,\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peopleData")),"img")),"w1440"), env.opts.autoescape);
output += " 1440w,\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peopleData")),"img")),"w1920"), env.opts.autoescape);
output += " 1920w\"\n            alt=\"\">\n        </div>\n\n        <div class=\"People-left\">\n            ";
output += runtime.suppressValue((lineno = 30, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "MenuLeft"), "MenuLeft", context, [runtime.makeKeywordArgs({"subpage": "people","caller": (function (){var macro_t_27 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_28 = "";;
frame = frame.pop();
return new runtime.SafeString(t_28);
});
return macro_t_27;})()})])), env.opts.autoescape);
output += "\n        </div>\n\n        <div class=\"People-right\">\n            <div class=\"People-header\">\n                <div class=\"People-subtitle\">\n                    <span class=\"People-subtitleStroke\"></span>\n                    <div class=\"People-subtitleText\">";
output += runtime.suppressValue((lineno = 37, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Люди"])), env.opts.autoescape);
output += "</div>\n                </div>\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peoplePage")),"text")) {
output += "<div class=\"People-description\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peoplePage")),"text"), env.opts.autoescape);
output += "</div>";
;
}
output += "\n            </div>\n\n            <div class=\"People-grid\">\n                ";
frame = frame.push();
var t_31 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peoplePage")),"persons");
if(t_31) {t_31 = runtime.fromIterator(t_31);
var t_30 = t_31.length;
for(var t_29=0; t_29 < t_31.length; t_29++) {
var t_32 = t_31[t_29];
frame.set("person", t_32);
frame.set("loop.index", t_29 + 1);
frame.set("loop.index0", t_29);
frame.set("loop.revindex", t_30 - t_29);
frame.set("loop.revindex0", t_30 - t_29 - 1);
frame.set("loop.first", t_29 === 0);
frame.set("loop.last", t_29 === t_30 - 1);
frame.set("loop.length", t_30);
output += "\n                    <div class=\"People-item\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"200\">\n                        <div class=\"People-item-inner\">\n                        <img class=\"People-image\"\n                        src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_32),"img")),"w1024"), env.opts.autoescape);
output += "\"\n                        srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_32),"img")),"w720"), env.opts.autoescape);
output += " 720w,\n                                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_32),"img")),"w1024"), env.opts.autoescape);
output += " 1024w,\n                                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_32),"img")),"w1280"), env.opts.autoescape);
output += " 1280w,\n                                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_32),"img")),"w1440"), env.opts.autoescape);
output += " 1440w\"\n                        sizes=\"calc((540 / 1440) * 100vw)\"\n                        alt=\"\">\n                        <div class=\"People-text\">\n                            <div class=\"People-textWrapper\">\n                                <div class=\"People-name\">";
output += runtime.suppressValue(runtime.memberLookup((t_32),"name"), env.opts.autoescape);
output += "</div>\n                                <div class=\"People-position\">";
output += runtime.suppressValue(runtime.memberLookup((t_32),"department"), env.opts.autoescape);
output += "</div>\n                                ";
if(runtime.memberLookup((t_32),"text")) {
output += "<div class=\"People-positionDescription\">";
output += runtime.suppressValue(runtime.memberLookup((t_32),"text"), env.opts.autoescape);
output += "</div>";
;
}
output += "\n                            </div>\n                        </div>\n                        </div>\n                    </div>\n                ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n        </div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/People/People.jinja"] , dependencies)