var Backbone = require('backbone/backbone');

require('./CareerVacancyCard.less');

module.exports = Backbone.View.extend({
    el: '.CareerVacancyCard',

    events: {},

    initialize: function () {
        
    },
});
