var Backbone = require('backbone');
var _ = require('underscore');
var Base = require('front/components/Base/Base');

require('front/components/StyleTag/StyleTag');

require('./TagList.less');
require('front/components/StyleTag/StyleTag.jinja');

module.exports = Base.extend({
    el: '.TagList',
    template: require('./TagList.jinja'),

    initialize: function (options) {
        this.wrapTags();
        Base.prototype.initialize.call(this, options);
    },

    setTags: function (data) {
        this.data = {
            tags: data,
            render: true
        };

        this.$el.remove();
        this.render();
        this.wrapTags();
    },

    $parent: function () {
        return $('.PostList-tags');
    },

    wrapTags: function () {
        this.$list = this.$('.TagList-list');
        this.$more = this.$('.TagList-more');

        var nonVisibleStyles = this.$list.find('.StyleTag').filter(
            function (index, el) {
                if ($(el).position().left > this.$list.width()) {
                    return true;
                }
            }.bind(this)
        );

        var ids = nonVisibleStyles.map(function () {
            return parseInt($(this).attr('data-id'), 10);
        });

        if (!ids.length) {
            this.$more.fadeOut();
        } else {
            this.$more.fadeIn();
        }

        var nonVisibleStyles = this.$('.TagList-tooltip')
            .find('.StyleTag')
            .filter(function () {
                var id = parseInt($(this).attr('data-id'), 10);

                if (!_.contains(ids, id)) {
                    $(this).addClass('isHidden');
                } else {
                    $(this).removeClass('isHidden');
                }
            });
    }
});
