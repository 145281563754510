var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');
var TopNav = require('front/components/TopNav/TopNav');
var Slider = require('front/components/Slider/Slider');
var BuildingCross = require('front/components/BuildingCross/BuildingCross');

require('./Production.less');

module.exports = Page.extend({
    template: require('./Production.jinja'),

    el: '.Production',

    events: {
        'mouseover .BuildingCross': 'hoverInArea',
        'mouseout .BuildingCross': 'hoverOutArea'
    },

    title: window.gettext('Производство'),

    initialize: function (options) {
        this.slider = new Slider({
            el: this.$('.Slider')
        });

        this.buildingCross = new BuildingCross();
        Page.prototype.initialize.call(this, options);
    },

    hoverInArea: function (e) {
        var dataId = $(e.currentTarget).attr('data-id');
        if (dataId !== 'background') {
            $('.Production-aerialViewPath[data-id="background"]').attr('state', 'background');
            $('.Production-aerialViewPath:not([data-id="background"])').attr('state', 'background');
            $('.Production-aerialViewPath[data-id=' + dataId + ']').attr('state', 'foreground');
            $('.BuildingCross[data-id=' + dataId + ']').attr('state', 'foreground');
            $('.BuildingLabel[data-id=' + dataId + ']').attr('state', 'foreground');
        }
    },

    hoverOutArea: function (e) {
        $('.BuildingCross').attr('state', '');
        $('.Production-aerialViewPath').attr('state', '');
    },

    render: function () {
        this.slider.render();
    },

    activate: function (params) {
        this.slider.activate();

        return Page.prototype.activate.call(this, params);
    }
});
