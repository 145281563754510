var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Slider/SliderCaption.jinja"] = require( "front/components/Slider/SliderCaption.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/Slider/SliderIndex.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/Slider/SliderIndex.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n";
env.getTemplate("front/components/Slider/SliderCaption.jinja", false, "assets/app/front/components/Slider/SliderIndex.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "SliderCaption")) {
var t_8 = t_5.SliderCaption;
} else {
cb(new Error("cannot import 'SliderCaption'")); return;
}
context.setVariable("SliderCaption", t_8);
output += "\n\n";
var macro_t_9 = runtime.makeMacro(
[], 
["slides", "classes"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("slides", Object.prototype.hasOwnProperty.call(kwargs, "slides") ? kwargs["slides"] : []);frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");var t_10 = "";t_10 += "\n    <div class=\"Slider ";
t_10 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_10 += "\" data-id=\"SliderIndex\">\n        <div class=\"Slider-items owl-carousel\">\n            ";
frame = frame.push();
var t_13 = runtime.contextOrFrameLookup(context, frame, "slides");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("s", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
t_10 += "\n                <div class=\"Slider-item\"\n                    data-index=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
t_10 += "\"\n                    data-title=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((t_14),"title"), env.opts.autoescape);
t_10 += "\" \n                    data-link=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((t_14),"link"), env.opts.autoescape);
t_10 += "\" \n                    data-link-title=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((t_14),"linkTitle"), env.opts.autoescape);
t_10 += "\">\n                    <img src=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_14),"img")),"w1024"), env.opts.autoescape);
t_10 += "\" \n                        srcset=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_14),"img")),"w1024"), env.opts.autoescape);
t_10 += " 1024w,\n                                ";
t_10 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_14),"img")),"w1280"), env.opts.autoescape);
t_10 += " 1280w,\n                                ";
t_10 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_14),"img")),"w1440"), env.opts.autoescape);
t_10 += " 1440w,\n                                ";
t_10 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_14),"img")),"w1920"), env.opts.autoescape);
t_10 += " 1920w\"\n                        sizes=\"(max-width: 1199px) calc((746 / 1024) * 100vw),\n                               (min-width: 1200px) and (max-width: 1439px) calc((900 / 1200) * 100vw),\n                               (min-width: 1440px) calc((1104 / 1440) * 100vw)\"\n                        alt=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((t_14),"title"), env.opts.autoescape);
t_10 += "\">\n                </div>\n            ";
;
}
}
frame = frame.pop();
t_10 += "\n        </div>\n        <div class=\"Slider-left\"></div>\n        <div class=\"Slider-right\"></div>\n        <div class=\"Slider-inner\">\n            <div class=\"Slider-caption\">\n                <div class=\"Slider-counter\">\n                    <span class=\"Slider-counterCurrent\">1</span>\n                    <span> / </span>\n                    <span class=\"Slider-counterTotal\">";
t_10 += runtime.suppressValue(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "slides")), env.opts.autoescape);
t_10 += "</span>\n                </div>\n                <div class=\"Slider-captionText\">\n                    ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "slides"))) {
t_10 += "\n                        ";
t_10 += runtime.suppressValue((lineno = 35, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SliderCaption"), "SliderCaption", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slides")),0)])), env.opts.autoescape);
t_10 += "\n                    ";
;
}
t_10 += "                \n                </div>\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_10);
});
context.addExport("SliderIndex");
context.setVariable("SliderIndex", macro_t_9);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 44, colno = 18, runtime.callWrap(macro_t_9, "SliderIndex", context, [runtime.makeKeywordArgs({"classes": runtime.contextOrFrameLookup(context, frame, "classes")})])), env.opts.autoescape);
output += "\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/Slider/SliderIndex.jinja"] , dependencies)