var Backbone = require('backbone');
var _ = require('underscore');
var Cookie = require('js-cookie');

require('front/components/ButtonIcon/ButtonIcon');

require('./Notify.less');

module.exports = Backbone.View.extend({
    template: require('./Notify.jinja'),

    events: {
        'click .Notify-close': 'closeNotify'
    },

    closeNotify: function () {
        this.$el.animate(
            {
                'margin-top': -80
            },
            function () {
                $(this).hide();
            }
        );
        Cookie.set('notifyHidden', 'true', { expires: 1 });
    },

    destroy: function () {
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }
});
