var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/BrandsGrid/BrandsGridItem.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["brand", "favorites"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("brand", Object.prototype.hasOwnProperty.call(kwargs, "brand") ? kwargs["brand"] : {});frame.set("favorites", Object.prototype.hasOwnProperty.call(kwargs, "favorites") ? kwargs["favorites"] : runtime.contextOrFrameLookup(context, frame, "True"));var t_2 = "";t_2 += "<a class=\"BrandsGrid-item";
t_2 += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"isNew")?" BrandsGrid-item--isNew":""), env.opts.autoescape);
t_2 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "favorites") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"isFavorite")?" BrandsGrid-item--isBig":""), env.opts.autoescape);
t_2 += "\"\n        href=\"";
t_2 += runtime.suppressValue((lineno = 4, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:brand",runtime.makeKeywordArgs({"args": [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"id")]})])), env.opts.autoescape);
t_2 += "\"\n        data-order=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"gridOrder"), env.opts.autoescape);
t_2 += "\"\n        data-category=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"category")),"id"), env.opts.autoescape);
t_2 += "\"\n        data-sub-category=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"subCategory")),"id"), env.opts.autoescape);
t_2 += "\"\n        data-styles=\"";
t_2 += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("dump").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"styles"))), env.opts.autoescape);
t_2 += "\"\n        data-id=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"id"), env.opts.autoescape);
t_2 += "\">\n        <table class=\"BrandsGrid-itemContent\">\n            <tr height=\"100%\" style=\"height:100%\"><td class=\"BrandsGrid-itemImgContainer\">\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"isNew")) {
t_2 += "<div class=\"BrandsGrid-itemBadge\">";
t_2 += runtime.suppressValue((lineno = 13, colno = 54, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Новинка"])), env.opts.autoescape);
t_2 += "</div>";
;
}
t_2 += "\n\n            <div class=\"BrandsGrid-itemImgContainer\">\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"isFavorite")) {
t_2 += "\n                    <img class=\"BrandsGrid-itemImg\" \n                        src=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"img")),"w458_square"), env.opts.autoescape);
t_2 += "\"\n                        srcset=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"img")),"w916_square"), env.opts.autoescape);
t_2 += " 2x\"\n                        alt=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"title"), env.opts.autoescape);
t_2 += "\">\n                ";
;
}
else {
t_2 += "\n                    <img class=\"BrandsGrid-itemImg\" \n                        src=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"img")),"w229_square"), env.opts.autoescape);
t_2 += "\"\n                        srcset=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"img")),"w458_square"), env.opts.autoescape);
t_2 += " 2x\"\n                        alt=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"title"), env.opts.autoescape);
t_2 += "\">\n                ";
;
}
t_2 += "\n            </div>\n            </td></tr>\n            <tr><td>\n            <span class=\"BrandsGrid-itemTitle\">";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"title"), env.opts.autoescape);
t_2 += "</span>\n            <span class=\"BrandsGrid-itemStyle\">";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"style"), env.opts.autoescape);
t_2 += "</span>\n            </td></tr>\n        </table>\n    </a>";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("BrandsGridItem");
context.setVariable("BrandsGridItem", macro_t_1);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += runtime.suppressValue((lineno = 39, colno = 21, runtime.callWrap(macro_t_1, "BrandsGridItem", context, [runtime.makeKeywordArgs({"brand": runtime.contextOrFrameLookup(context, frame, "brand")})])), env.opts.autoescape);
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/BrandsGrid/BrandsGridItem.jinja"] , dependencies)