var Backbone = require('backbone');
var _ = require('underscore');
var Stickyfill = require('stickyfilljs');

require('front/components/Button/Button.js');

require('./TopNav.less');

var MIN_SCREEN_SIZE = 1024;

module.exports = Backbone.View.extend({
    template: require('./TopNav.jinja'),

    el: '.TopNav',

    initialize: function (options) {
        _.bindAll(this, '_checkScroll', 'checkHorizontalScroll');

        this.options = options || {};
        this.data = this.options.data || {};

        this.checkScroll = _.throttle(this._checkScroll, 50);
        // this.checkHorizontalScroll = _.throttle(this._checkScroll, 50);

        this.checkScroll();
    },

    render: function () {
        var params = {};
        if (this.options.type) {
            params.classes = 'TopNav--' + this.options.type;
        }

        this.setElement(this.template.render(_.extend(params, { currentUrl: window.location.pathname, render: true })));

        this.$inner = this.$('.TopNav-inner');

        return this;
    },

    _checkScroll: function (e) {
        if (app.els.$window.scrollTop() >= 50) {
            this.$el.addClass('isActive');
        } else {
            this.$el.removeClass('isActive');
        }
    },

    checkHorizontalScroll: function () {
        if (this.$el.hasClass('isActive') && app.els.$window.width() < MIN_SCREEN_SIZE && this.$inner) {
            this.$inner.css({ left: -app.els.$window.scrollLeft() });
        }
    },

    activate: function () {
        app.els.$window.on('scroll', this.checkScroll);
        app.els.$window.on('scroll', this.checkHorizontalScroll);

        if (this.options.type && this.options.type === 'sticky') {
            Stickyfill.add(this.$el);
        }
    },

    destroy: function () {
        app.els.$window.off('scroll', this.checkScroll);

        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }
});
