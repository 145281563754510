var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Button/Button.jinja"] = require( "front/components/Button/Button.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/TopNav/TopNav.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/TopNav/TopNav.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n";
env.getTemplate("front/components/Button/Button.jinja", false, "assets/app/front/components/TopNav/TopNav.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "Button")) {
var t_8 = t_5.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_8);
output += "\n\n";
var macro_t_9 = runtime.makeMacro(
[], 
["classes", "currentUrl"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");frame.set("currentUrl", Object.prototype.hasOwnProperty.call(kwargs, "currentUrl") ? kwargs["currentUrl"] : "");var t_10 = "";t_10 += "\n    <nav class=\"TopNav ";
t_10 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_10 += "\">\n        <div class=\"TopNav-inner\">\n            <a class=\"TopNav-logo\" href=\"";
t_10 += runtime.suppressValue((lineno = 6, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:index"])), env.opts.autoescape);
t_10 += "\">\n                ";
t_10 += runtime.suppressValue((lineno = 7, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["logo"])), env.opts.autoescape);
t_10 += "\n            </a>\n\n            <div class=\"TopNav-links\">\n                <a class=\"TopNav-link ";
t_10 += runtime.suppressValue((lineno = 11, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/about/"])), env.opts.autoescape);
t_10 += " ";
t_10 += runtime.suppressValue((lineno = 11, colno = 84, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/people/"])), env.opts.autoescape);
t_10 += " ";
t_10 += runtime.suppressValue((lineno = 11, colno = 122, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/history/"])), env.opts.autoescape);
t_10 += "\"\n                   href=\"";
t_10 += runtime.suppressValue((lineno = 12, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:about"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 12, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Компания"])), env.opts.autoescape);
t_10 += "</a>\n                <a class=\"TopNav-link ";
t_10 += runtime.suppressValue((lineno = 13, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/production/"])), env.opts.autoescape);
t_10 += "\"\n                   href=\"";
t_10 += runtime.suppressValue((lineno = 14, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:production"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 14, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Производство"])), env.opts.autoescape);
t_10 += "</a>\n                <a class=\"TopNav-link ";
t_10 += runtime.suppressValue((lineno = 15, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/brands/"])), env.opts.autoescape);
t_10 += " ";
t_10 += runtime.suppressValue((lineno = 15, colno = 85, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/brand/"])), env.opts.autoescape);
t_10 += "\"\n                   href=\"";
t_10 += runtime.suppressValue((lineno = 16, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:brands-category",runtime.makeKeywordArgs({"args": ["beer"]})])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 16, colno = 80, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бренды"])), env.opts.autoescape);
t_10 += "</a>\n                <a class=\"TopNav-link TopNav-link--ru ";
t_10 += runtime.suppressValue((lineno = 17, colno = 63, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/posts/"])), env.opts.autoescape);
t_10 += "\"\n                   href=\"";
t_10 += runtime.suppressValue((lineno = 18, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:index"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 18, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
t_10 += "</a>\n                <a class=\"TopNav-link ";
t_10 += runtime.suppressValue((lineno = 19, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/legal/"])), env.opts.autoescape);
t_10 += "\"\n                   href=\"";
t_10 += runtime.suppressValue((lineno = 20, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-information"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 20, colno = 67, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнеров"])), env.opts.autoescape);
t_10 += "</a>\n                <a class=\"TopNav-link TopNav-link--ru ";
t_10 += runtime.suppressValue((lineno = 21, colno = 63, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/career/"])), env.opts.autoescape);
t_10 += "\"\n                   href=\"";
t_10 += runtime.suppressValue((lineno = 22, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:career"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 22, colno = 56, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
t_10 += "</a>\n                <a class=\"TopNav-link TopNav-link--ru\" target=\"_blank\" href=\"https://shop.mosbrew.ru/\">";
t_10 += runtime.suppressValue((lineno = 23, colno = 107, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Магазин"])), env.opts.autoescape);
t_10 += "</a>\n                <a class=\"TopNav-link\" href=\"";
t_10 += runtime.suppressValue((lineno = 24, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:contact"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 24, colno = 77, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Контакты"])), env.opts.autoescape);
t_10 += "</a>\n            </div>\n\n            <div class=\"TopNav-tour\">\n                ";
t_10 += runtime.suppressValue((lineno = 28, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Button"), "Button", context, [runtime.makeKeywordArgs({"tag": "a","href": (lineno = 28, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:excursion"])),"title": (lineno = 28, colno = 71, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Экскурсия"])),"classes": "Button--small u-Route"})])), env.opts.autoescape);
t_10 += "\n            </div>\n        </div>\n    </nav>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_10);
});
context.addExport("TopNav");
context.setVariable("TopNav", macro_t_9);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 35, colno = 13, runtime.callWrap(macro_t_9, "TopNav", context, [runtime.makeKeywordArgs({"classes": runtime.contextOrFrameLookup(context, frame, "classes"),"currentUrl": runtime.contextOrFrameLookup(context, frame, "currentUrl")})])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/TopNav/TopNav.jinja"] , dependencies)