var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Button/Button.jinja"] = require( "front/components/Button/Button.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/PopupAge/PopupAge.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/PopupAge/PopupAge.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n";
env.getTemplate("front/components/Button/Button.jinja", false, "assets/app/front/components/PopupAge/PopupAge.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "Button")) {
var t_8 = t_5.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_8);
output += "\n\n";
var macro_t_9 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_10 = "";t_10 += "\n    <div class=\"PopupAge\">\n        <div class=\"PopupAge-overlay\"></div>\n        <div class=\"PopupAge-content\">\n            <div class=\"PopupAge-logo\">";
t_10 += runtime.suppressValue((lineno = 7, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["logo"])), env.opts.autoescape);
t_10 += "</div>\n            <div class=\"PopupAge-question\">";
t_10 += runtime.suppressValue((lineno = 8, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Уже исполнилось 18 лет?"])), env.opts.autoescape);
t_10 += "</div>\n            <div class=\"PopupAge-controls\">\n                <div class=\"PopupAge-control PopupAge-control--accept\">\n                    ";
var t_11;
t_11 = (lineno = 11, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Да"]));
frame.set("yesText", t_11, true);
if(frame.topLevel) {
context.setVariable("yesText", t_11);
}
if(frame.topLevel) {
context.addExport("yesText", t_11);
}
t_10 += "\n\n                    ";
t_10 += runtime.suppressValue((lineno = 13, colno = 29, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Button"), "Button", context, [runtime.makeKeywordArgs({"title": runtime.contextOrFrameLookup(context, frame, "yesText"),"classes": "Button--grow"})])), env.opts.autoescape);
t_10 += "\n                </div>\n                <div class=\"PopupAge-control PopupAge-control--reject\">\n                    ";
var t_12;
t_12 = (lineno = 16, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Нет"]));
frame.set("noText", t_12, true);
if(frame.topLevel) {
context.setVariable("noText", t_12);
}
if(frame.topLevel) {
context.addExport("noText", t_12);
}
t_10 += "\n\n                    ";
t_10 += runtime.suppressValue((lineno = 18, colno = 29, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Button"), "Button", context, [runtime.makeKeywordArgs({"title": runtime.contextOrFrameLookup(context, frame, "noText"),"classes": "Button--grow"})])), env.opts.autoescape);
t_10 += "\n                </div>\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_10);
});
context.addExport("PopupAge");
context.setVariable("PopupAge", macro_t_9);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 26, colno = 15, runtime.callWrap(macro_t_9, "PopupAge", context, [])), env.opts.autoescape);
output += "\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/PopupAge/PopupAge.jinja"] , dependencies)