var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/Story/Story.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/Story/Story.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n\n";
var macro_t_5 = runtime.makeMacro(
["story"], 
[], 
function (l_story, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("story", l_story);
var t_6 = "";t_6 += "\n    ";
if(runtime.memberLookup((l_story),"id") == "2016-1") {
t_6 += "\n        <div class=\"Story Story--wideCircle\"\n             data-id=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"id"), env.opts.autoescape);
t_6 += "\"\n             data-type=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"type"), env.opts.autoescape);
t_6 += "\"\n             ";
if(runtime.memberLookup((l_story),"colorBackground")) {
t_6 += "style=\"background-color: ";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"colorBackground"), env.opts.autoescape);
t_6 += "\"";
;
}
t_6 += ">\n            <div class=\"Story-content\">\n                ";
t_6 += "\n                <div class=\"Story-text\">";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"text1"), env.opts.autoescape);
t_6 += "</div>\n            </div>\n\n            <div class=\"Story-imgWrapperOuter\">\n                <div class=\"Story-imgWrapperInner\">\n                    <div class=\"Story-outerCircle\"></div>\n                    <div class=\"Story-innerCircle\"></div>\n                    <div class=\"Story-contentCircle\">\n                        <img class=\"Story-circleImage\"\n                             src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                             srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n                    </div>\n                </div>\n            </div>\n        </div>\n\n    ";
;
}
else {
if(runtime.memberLookup((l_story),"id") == "2023-1") {
t_6 += "\n        <div class=\"Story\" data-id=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"id"), env.opts.autoescape);
t_6 += "\">\n            <div class=\"Story-content\">\n                <div class=\"Story-text\">";
t_6 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_story),"text1")), env.opts.autoescape);
t_6 += "</div>\n\n                <div class=\"Story-imgWrapper\">\n                    <div class=\"Story-imgOld\">\n                        <img class=\"Story-img0\"\n                             src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image0")),"x1"), env.opts.autoescape);
t_6 += "\"\n                             srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image0")),"x2"), env.opts.autoescape);
t_6 += " 2x\"\n                             alt=\"\">\n                    </div>\n                    <div class=\"Story-imgNew\">\n                        <img class=\"Story-img1\"\n                             src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                             srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\"\n                             alt=\"\">\n\n                        <div class=\"Story-imgNewCenter\">\n                            <img class=\"Story-img2\"\n                                 src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                 srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x2"), env.opts.autoescape);
t_6 += " 2x\"\n                                 alt=\"\">\n                        </div>\n\n                        <img class=\"Story-img3\"\n                             src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image3")),"x1"), env.opts.autoescape);
t_6 += "\"\n                             srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image3")),"x2"), env.opts.autoescape);
t_6 += " 2x\"\n                             alt=\"\">\n                    </div>\n                </div>\n            </div>\n        </div>\n    ";
;
}
else {
if(runtime.memberLookup((l_story),"id") == "2023-4") {
t_6 += "\n        <div class=\"Story\" data-id=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"id"), env.opts.autoescape);
t_6 += "\">\n            <div class=\"Story-content\">\n                <div class=\"Story-text\">";
t_6 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_story),"text1")), env.opts.autoescape);
t_6 += "</div>\n\n                <ul class=\"Story-list\">\n                    ";
frame = frame.push();
var t_9 = runtime.memberLookup((l_story),"list");
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("item", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
t_6 += "\n                    <li class=\"Story-listItem\">\n                        <div class=\"Story-listItemText\">\n                            ";
t_6 += runtime.suppressValue(runtime.memberLookup((t_10),"text"), env.opts.autoescape);
t_6 += "\n                        </div>\n                        <div class=\"Story-listItemImgWrapper\">\n                            <img class=\"Story-listItemImg\"\n                                src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_10),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_10),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\"\n                                alt=\"\">\n                        </div>\n                    </li>\n                    ";
;
}
}
frame = frame.pop();
t_6 += "\n                </ul>\n            </div>\n        </div>\n    ";
;
}
else {
if(runtime.memberLookup((l_story),"id") == "2023-5") {
t_6 += "\n        <div class=\"Story\" data-id=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"id"), env.opts.autoescape);
t_6 += "\">\n            <div class=\"Story-content\">\n                <div class=\"Story-contentCol\">\n                    <div class=\"Story-text\">";
t_6 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_story),"text1")), env.opts.autoescape);
t_6 += "</div>\n                    <div class=\"Story-imgWrapper Story-imgWrapper--rzd\">\n                        <img class=\"Story-img\"\n                                src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\"\n                                alt=\"\">\n                    </div>\n                </div>\n\n                <div class=\"Story-contentFigure\">\n                    <img class=\"Story-imgLeft\"\n                                src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_story),"figure")),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_story),"figure")),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\"\n                                alt=\"\">\n                    <img class=\"Story-imgRight\"\n                                src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_story),"figure")),"image2")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_story),"figure")),"image2")),"x2"), env.opts.autoescape);
t_6 += " 2x\"\n                                alt=\"\">\n                </div>\n\n                <div class=\"Story-contentCol\">\n                    <div class=\"Story-text Story-text--right\">";
t_6 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_story),"text2")), env.opts.autoescape);
t_6 += "</div>\n                    <div class=\"Story-imgWrapper Story-imgWrapper--aeroflot\">\n                        <img class=\"Story-img\"\n                                src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x2"), env.opts.autoescape);
t_6 += " 2x\"\n                                alt=\"\">\n                    </div>\n                </div>\n            </div>\n        </div>\n    ";
;
}
else {
if(runtime.memberLookup((l_story),"id") == "2022-6") {
t_6 += "\n        <div class=\"Story\" data-id=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"id"), env.opts.autoescape);
t_6 += "\">\n            <div class=\"Story-inner\">\n                <div class=\"Story-imgWrapper\">\n                    <img class=\"Story-img\"\n                         src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                         srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n\n                    <img class=\"Story-img\"\n                         src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x1"), env.opts.autoescape);
t_6 += "\"\n                         srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n\n                    <img class=\"Story-img\"\n                         src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image3")),"x1"), env.opts.autoescape);
t_6 += "\"\n                         srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image3")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n                </div>\n\n                <div class=\"Story-imgWrapper2\">\n                    <img class=\"Story-img\"\n                         src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x1"), env.opts.autoescape);
t_6 += "\"\n                         srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n\n                    <img class=\"Story-img\"\n                         src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image3")),"x1"), env.opts.autoescape);
t_6 += "\"\n                         srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image3")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n\n                    <img class=\"Story-img\"\n                         src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                         srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n                </div>\n\n                <div class=\"Story-imgWrapper3\">\n                    <img class=\"Story-img\"\n                         src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image3")),"x1"), env.opts.autoescape);
t_6 += "\"\n                         srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image3")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n\n                    <img class=\"Story-img\"\n                         src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                         srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n\n                    <img class=\"Story-img\"\n                         src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x1"), env.opts.autoescape);
t_6 += "\"\n                         srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n                </div>\n\n                <div class=\"Story-content\">\n                    <div class=\"Story-text\">";
t_6 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_story),"text1")), env.opts.autoescape);
t_6 += "</div>\n\n                    ";
if(runtime.memberLookup((l_story),"text2")) {
t_6 += "\n                        <div class=\"Story-text2\">";
t_6 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_story),"text2")), env.opts.autoescape);
t_6 += "</div>\n                    ";
;
}
t_6 += "\n                </div>\n            </div>\n        </div>\n\n    ";
;
}
else {
if(runtime.memberLookup((l_story),"type") == "2021-list") {
t_6 += "\n        <div class=\"Story\"\n            data-id=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"id"), env.opts.autoescape);
t_6 += "\"\n            ";
if(runtime.memberLookup((l_story),"colorBackground")) {
t_6 += "style=\"background-color: ";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"colorBackground"), env.opts.autoescape);
t_6 += "\"";
;
}
t_6 += "\n        >\n            <div class=\"Story-title\">";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"title"), env.opts.autoescape);
t_6 += "</div>\n\n            <div class=\"Story-list\">\n                ";
frame = frame.push();
var t_13 = runtime.memberLookup((l_story),"list");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("item", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
t_6 += "\n                <div class=\"Story-listItem\">\n                    <div class=\"Story-listItemImage\">\n                        <img class=\"Story-listItemImageAward\"\n                            src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_14),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                            srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_14),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\"\n                            alt=\"\">\n                        <img class=\"Story-listItemImageBottle\"\n                            src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_14),"image2")),"x1"), env.opts.autoescape);
t_6 += "\"\n                            srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_14),"image2")),"x2"), env.opts.autoescape);
t_6 += " 2x\"\n                            alt=\"\">\n                    </div>\n                    <div class=\"Story-listItemText\">\n                        ";
t_6 += runtime.suppressValue(runtime.memberLookup((t_14),"text"), env.opts.autoescape);
t_6 += "\n                    </div>\n                </div>\n                ";
;
}
}
frame = frame.pop();
t_6 += "\n            </div>\n        </div>\n\n    ";
;
}
else {
if(runtime.memberLookup((l_story),"type") == "text") {
t_6 += "\n        <div class=\"Story Story--text\"\n             data-type=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"type"), env.opts.autoescape);
t_6 += "\"\n             data-id=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"id"), env.opts.autoescape);
t_6 += "\"\n             data-column=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"column"), env.opts.autoescape);
t_6 += "\">\n            ";
t_6 += "\n            <div class=\"Story-text\">";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"text1"), env.opts.autoescape);
t_6 += "</div>\n        </div>\n\n    ";
;
}
else {
t_6 += "\n        <div class=\"Story\"\n             data-id=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"id"), env.opts.autoescape);
t_6 += "\"\n             ";
if(runtime.memberLookup((l_story),"row")) {
t_6 += "data-row=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"row"), env.opts.autoescape);
t_6 += "\"";
;
}
t_6 += "\n             ";
if(runtime.memberLookup((l_story),"column")) {
t_6 += "data-column=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"column"), env.opts.autoescape);
t_6 += "\"";
;
}
t_6 += "\n             ";
if(runtime.memberLookup((l_story),"colorBackground")) {
t_6 += "style=\"background-color: ";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"colorBackground"), env.opts.autoescape);
t_6 += "\"";
;
}
t_6 += "\n        >\n            ";
if(runtime.memberLookup((l_story),"id") == "2023-3") {
t_6 += "\n                <div class=\"Story-circle Story-circle--blue\"></div>\n                <div class=\"Story-circle Story-circle--pink\"></div>\n                <div class=\"Story-circle Story-circle--yellow\"></div>\n            ";
;
}
t_6 += "\n\n            <div class=\"Story-inner\">\n                ";
if(runtime.memberLookup((l_story),"image1")) {
t_6 += "\n                    <div class=\"Story-imgWrapper\">\n                        <img class=\"Story-img\"\n                             src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                             srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n\n                        ";
if(runtime.memberLookup((l_story),"id") == "2012-3") {
t_6 += "\n                            <div class=\"Story-circle\"></div>\n                        ";
;
}
t_6 += "\n\n                        ";
if(runtime.memberLookup((l_story),"id") == "2013-7") {
t_6 += "\n                            <img class=\"Story-img2\"\n                                 src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                 srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n\n                            <img class=\"Story-img3\"\n                                 src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                 srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n                        ";
;
}
t_6 += "\n\n                        ";
if(runtime.memberLookup((l_story),"id") == "2019-4") {
t_6 += "\n                            <img class=\"Story-img\"\n                                 src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                 srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n                        ";
;
}
t_6 += "\n                    </div>\n                ";
;
}
t_6 += "\n\n                ";
if(runtime.memberLookup((l_story),"image2")) {
t_6 += "\n                    <div class=\"Story-imgWrapper2\">\n                        ";
if(runtime.memberLookup((l_story),"id") == "2019-4") {
t_6 += "\n                            ";
frame = frame.push();
var t_17 = (lineno = 253, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [4]));
if(t_17) {t_17 = runtime.fromIterator(t_17);
var t_16 = t_17.length;
for(var t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15];
frame.set("i", t_18);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
t_6 += "\n                                ";
if(t_18 % 2) {
t_6 += "\n                                    <img class=\"Story-img\"\n                                         src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                         srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n                                ";
;
}
else {
t_6 += "\n                                    <img class=\"Story-img\"\n                                         src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                         srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n                                ";
;
}
t_6 += "\n                            ";
;
}
}
frame = frame.pop();
t_6 += "\n                        ";
;
}
else {
t_6 += "\n                            <img class=\"Story-img2\"\n                                 src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                 ";
if(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x2")) {
t_6 += "srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image2")),"x2"), env.opts.autoescape);
t_6 += " 2x\"";
;
}
t_6 += ">\n                        ";
;
}
t_6 += "\n                    </div>\n                ";
;
}
t_6 += "\n\n                ";
if(runtime.memberLookup((l_story),"image3")) {
t_6 += "\n                    <div class=\"Story-imgWrapper3\">\n                        <img class=\"Story-img3\"\n                             src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image3")),"x1"), env.opts.autoescape);
t_6 += "\"\n                             srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image3")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n                    </div>\n                ";
;
}
t_6 += "\n\n                ";
if(runtime.memberLookup((l_story),"image4")) {
t_6 += "\n                    <div class=\"Story-imgWrapper4\">\n                        <img class=\"Story-img4\"\n                             src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image4")),"x1"), env.opts.autoescape);
t_6 += "\"\n                             srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image4")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n                    </div>\n                ";
;
}
t_6 += "\n\n                ";
if(runtime.memberLookup((l_story),"images")) {
t_6 += "\n                    <div class=\"Story-imagesWrapper\">\n                        ";
if(runtime.memberLookup((l_story),"id") == "2019-2") {
t_6 += "\n                            ";
frame = frame.push();
var t_21 = runtime.memberLookup((l_story),"images");
if(t_21) {t_21 = runtime.fromIterator(t_21);
var t_20 = t_21.length;
for(var t_19=0; t_19 < t_21.length; t_19++) {
var t_22 = t_21[t_19];
frame.set("image", t_22);
frame.set("loop.index", t_19 + 1);
frame.set("loop.index0", t_19);
frame.set("loop.revindex", t_20 - t_19);
frame.set("loop.revindex0", t_20 - t_19 - 1);
frame.set("loop.first", t_19 === 0);
frame.set("loop.last", t_19 === t_20 - 1);
frame.set("loop.length", t_20);
t_6 += "\n                                <svg class=\"Story-imgWrapper\" viewBox=\"0 0 70 250\" version=\"1.1\"\n                                     xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n                                     preserveAspectRatio=\"xMidYMid slice\">\n                                    <mask id=\"mask-";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
t_6 += "\">\n                                        <rect class=\"Story-imgMask\" fill=\"#fff\" x=\"0\" y=\"0\" width=\"70\"></rect>\n                                    </mask>\n                                    <image class=\"Story-img\" xlink:href=\"";
t_6 += runtime.suppressValue(t_22, env.opts.autoescape);
t_6 += "\"\n                                           mask=\"url(#mask-";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
t_6 += ")\"/>\n                                </svg>\n                            ";
;
}
}
frame = frame.pop();
t_6 += "\n                        ";
;
}
else {
t_6 += "\n                            ";
frame = frame.push();
var t_25 = runtime.memberLookup((l_story),"images");
if(t_25) {t_25 = runtime.fromIterator(t_25);
var t_24 = t_25.length;
for(var t_23=0; t_23 < t_25.length; t_23++) {
var t_26 = t_25[t_23];
frame.set("image", t_26);
frame.set("loop.index", t_23 + 1);
frame.set("loop.index0", t_23);
frame.set("loop.revindex", t_24 - t_23);
frame.set("loop.revindex0", t_24 - t_23 - 1);
frame.set("loop.first", t_23 === 0);
frame.set("loop.last", t_23 === t_24 - 1);
frame.set("loop.length", t_24);
t_6 += "\n                                <img class=\"Story-img\"\n                                     src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x1"), env.opts.autoescape);
t_6 += "\"\n                                     srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_story),"image1")),"x2"), env.opts.autoescape);
t_6 += " 2x\">\n                            ";
;
}
}
frame = frame.pop();
t_6 += "\n                        ";
;
}
t_6 += "\n                    </div>\n                ";
;
}
t_6 += "\n\n                ";
if(runtime.memberLookup((l_story),"svgList")) {
t_6 += "\n                    <div class=\"Story-svgList\">\n                        ";
frame = frame.push();
var t_29 = runtime.memberLookup((l_story),"svgList");
if(t_29) {t_29 = runtime.fromIterator(t_29);
var t_28 = t_29.length;
for(var t_27=0; t_27 < t_29.length; t_27++) {
var t_30 = t_29[t_27];
frame.set("svg", t_30);
frame.set("loop.index", t_27 + 1);
frame.set("loop.index0", t_27);
frame.set("loop.revindex", t_28 - t_27);
frame.set("loop.revindex0", t_28 - t_27 - 1);
frame.set("loop.first", t_27 === 0);
frame.set("loop.last", t_27 === t_28 - 1);
frame.set("loop.length", t_28);
t_6 += "\n                            ";
t_6 += runtime.suppressValue((lineno = 315, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, [t_30])), env.opts.autoescape);
t_6 += "\n                        ";
;
}
}
frame = frame.pop();
t_6 += "\n                    </div>\n                ";
;
}
t_6 += "\n\n                <div class=\"Story-content\">\n                    ";
t_6 += "\n                    <div class=\"Story-text\">";
t_6 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_story),"text1")), env.opts.autoescape);
t_6 += "</div>\n\n                    ";
if(runtime.memberLookup((l_story),"text2")) {
t_6 += "\n                        <div class=\"Story-text2\">";
t_6 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_story),"text2")), env.opts.autoescape);
t_6 += "</div>\n                    ";
;
}
t_6 += "\n                </div>\n\n                ";
if(runtime.memberLookup((l_story),"animation")) {
t_6 += "\n                    <div class=\"Story-animation\" data-animation=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"animation"), env.opts.autoescape);
t_6 += "\"></div>\n                ";
;
}
t_6 += "\n\n                ";
if(runtime.memberLookup((l_story),"video")) {
t_6 += "\n                    <video class=\"Story-video\" autoplay muted loop>\n                        <source src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_story),"video"), env.opts.autoescape);
t_6 += "\">\n                        Your browser does not support the video tag.\n                    </video>\n                ";
;
}
t_6 += "\n            </div>\n        </div>\n    ";
;
}
;
}
;
}
;
}
;
}
;
}
;
}
t_6 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_6);
});
context.addExport("Story");
context.setVariable("Story", macro_t_5);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/Story/Story.jinja"] , dependencies)