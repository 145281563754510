var Backbone = require('backbone');
var MenuLeft = require('front/components/MenuLeft/MenuLeft');
var Slider = require('front/components/Slider/Slider');
var Page = require('front/pages/Page/Page');

require('./Career.less');

module.exports = Page.extend({
    template: require('./Career.jinja'),

    el: '.Career',

    events: {
        'click .Career-jobType': 'selectJobs',
        'click .Career-jobCross.isActive': 'hideJobs'
    },

    initialize: function (options) {
        this.menuLeft = new MenuLeft();
        this.shownLists = [];

        this.slider = new Slider({
            el: this.$('.Slider')
        });
        this.$careerElements = $('.Career-jobType');
        Page.prototype.initialize.call(this, options);
    },

    selectJobs: function (e) {
        if (this.shownLists.length > 0) {
            var innerTextClick = $(e.currentTarget)[0].innerText;
            var innerTextShown = $(this.shownLists[0])[0].innerText;

            if (innerTextClick === innerTextShown) {
                return;
            }

            this.hideJobs(e).then(
                function () {
                    this.showjob(e);
                }.bind(this)
            );
        } else {
            this.showjob(e);
        }
    },

    hideJobs: function (e) {
        e.stopPropagation();

        return new Promise(
            function (resolve) {
                $(this.shownLists).each(
                    function (index, element) {
                        var $element = $(element);
                        var $elementCross = $element.find('.Career-jobCross');
                        var $targetSalary = $element.find('.Career-jobSalary');

                        $elementCross.removeClass('isActive');
                        var $list = $(element).find('.Career-jobList');
                        $list.slideUp(
                            300,
                            'swing',
                            function () {
                                $list.attr('data-state', '');
                                $element.removeClass('isActive');
                                $targetSalary.css('opacity', '0');

                                resolve();
                            }.bind(this)
                        );
                    }.bind(this)
                );

                this.shownLists = [];
            }.bind(this)
        );
    },

    showjob: function (e) {
        var $target = $(e.currentTarget);
        if ($target.hasClass('isActive')) {
            return;
        }
        var $targetCross = $target.find('.Career-jobCross');
        var $targetList = $(e.currentTarget).find('.Career-jobList');
        var $targetSalary = $(e.currentTarget).find('.Career-jobSalary');

        $target.addClass('isActive');
        $targetCross.addClass('isActive');
        $targetSalary.addClass('isActive');
        $targetList.attr('data-state', 'active').slideDown(300, 'swing', function () {
            $targetSalary.css('opacity', '1');
        });

        this.shownLists.push($target);
    },

    render: function () {
        this.slider.render();
    },

    activate: function (params) {
        this.slider.activate();

        return Page.prototype.activate.call(this, params);
    }
});
