var Backbone = require('backbone');
var _ = require('underscore');
var Utils = require('front/utils/Utils');

//Base component, most other views are inherider from it
//can render itself and control the child components
module.exports = Backbone.View.extend(
    _.extend({}, Backbone.Events, {
        initialize: function (options) {
            this.options = options || {};

            // storage for child views
            this.views = {};

            if (this.options.server === false) {
                this.setElement($(''));
            }
        },

        isRendered: function () {
            return !!this.$el.length;
        },

        $parent: function () {
            return null;
        },

        attach: function () {
            var $parent = this.$parent();
            if ($parent) $parent.append(this.$el);
        },

        renderData: function () {
            return this.data || {};
        },

        render: function () {
            var data = this.renderData();
            data.viewOptions = this.options;

            this.setElement(this.template.render(_.extend({}, data, {
                'LANGUAGE_CODE': window.app.data.lang
            })));
            this.attach();

            return this;
        },

        activate: function () {
            return this.loadData()
                .then(
                    function () {
                        if (!this.isRendered() || this.$el.parents('html').length == 0) {
                            this.render();
                        }
                        this.viewsRegistration();
                    }.bind(this)
                )
                .then(
                    function () {
                        // activate child view
                        var promise = Utils.createEmptyPromise();

                        if (this.views) {
                            promise = Promise.all(
                                _.map(this.views, function (view) {
                                    return view.activate();
                                })
                            );
                        }

                        return promise;
                    }.bind(this)
                );
        },

        update: function () {},

        deactivate: function (params) {
            var promise = Utils.createEmptyPromise();

            if (this.views) {
                promise = Promise.all(
                    _.map(this.views, function (view) {
                        return view.deactivate(params);
                    })
                );
            }

            return promise.then(
                function () {
                    if (params && params.destroy) {
                        this.destroy();
                    }
                }.bind(this)
            );
        },

        destroy: function () {
            this.undelegateEvents();
            this.$el.removeData().unbind();
            this.remove();
        },

        viewsRegistration: function () {},

        // Method to store subview in views storage object.
        // All views should be created using this method.
        registerView: function (viewName, view) {
            view.parent = this;
            this.views[viewName] = view;

            return view;
        },

        addView: function (view) {
            this.views.push(view);
        },

        getView: function (viewName) {
            return this.views[viewName];
        },

        destroyView: function (viewName) {
            this.views[viewName].destroy();
            delete this.views[viewName];
        },

        forceLoadData: function (apiUrl) {
            var api;
            apiUrl = apiUrl || this.apiUrl;

            if (apiUrl) {
                api = _.isFunction(apiUrl) ? apiUrl() : apiUrl;

                return Promise.resolve(
                    $.getJSON(api)
                        .then(
                            function (data) {
                                this.data = data;
                            }.bind(this)
                        )
                        .fail(function () {})
                );
            } else {
                return Utils.createEmptyPromise();
            }
        },

        loadData: function () {
            var apiUrl = _.isFunction(this.apiUrl) ? this.apiUrl() : this.apiUrl;

            if ((!this.data || !this.options.server) && apiUrl) {
                if (!this.disableCache) {
                    //if caching is allowed then cache it and return
                    if (window.app.cache[apiUrl] !== undefined) {
                        this.data = window.app.cache[apiUrl];

                        return Utils.createEmptyPromise();
                    }

                    return this.forceLoadData(apiUrl).then(
                        function () {
                            window.app.cache[apiUrl] = this.data;
                        }.bind(this)
                    );
                } else {
                    return this.forceLoadData(apiUrl);
                }
            } else {
                return Utils.createEmptyPromise();
            }
        },

        //method for animation logic on component activation
        playIn: function () {
            // empty Promise, signal that method is done his work
            return Utils.createEmptyPromise();
        },

        //method for animation logic on component deactivation
        playOut: function () {
            // empty Promise, signal that method is done his work
            return Utils.createEmptyPromise();
        }
    })
);
