var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/Share/Share.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/Share/Share.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n\n";
var macro_t_5 = runtime.makeMacro(
[], 
["classes"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");var t_6 = "";t_6 += "\n    <div class=\"Share ";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_6 += "\">\n        <button class=\"Share-button\">";
t_6 += runtime.suppressValue((lineno = 4, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["social"])), env.opts.autoescape);
t_6 += "</button>\n        <button class=\"Share-close\">";
t_6 += runtime.suppressValue((lineno = 5, colno = 48, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["crossSocial"])), env.opts.autoescape);
t_6 += "</button>\n\n        <div class=\"Share-list\">\n            <button  class=\"Share-item Share-item--telegram\">\n                <span class=\"Share-itemInner\">";
t_6 += runtime.suppressValue((lineno = 9, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["telegramIcon"])), env.opts.autoescape);
t_6 += "</span>\n            </button>\n            <button class=\"Share-item Share-item--twitter\">\n                <span class=\"Share-itemInner\">";
t_6 += runtime.suppressValue((lineno = 12, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["twitterIcon"])), env.opts.autoescape);
t_6 += "</span>\n            </button>\n            ";
t_6 += "\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_6);
});
context.addExport("Share");
context.setVariable("Share", macro_t_5);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 24, colno = 12, runtime.callWrap(macro_t_5, "Share", context, [])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/Share/Share.jinja"] , dependencies)