var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');

require('./Confidential.less');

module.exports = Page.extend({
    template: require('./Confidential.jinja'),

    el: '.Confidential',

    title: 'Политика',

    initialize: function (options) {
        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        return Page.prototype.activate.call(this, params);
    }
});
