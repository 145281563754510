var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');
var MenuLeft = require('front/components/MenuLeft/MenuLeft');
var TopNav = require('front/components/TopNav/TopNav');
var Slider = require('front/components/Slider/Slider');

require('./Principles.less');

module.exports = Page.extend({
    template: require('./Principles.jinja'),

    el: '.Principles',

    title: window.gettext('Компания') + ': ' + window.gettext('Принципы'),

    initialize: function (options) {
        this.menuLeft = new MenuLeft();

        this.slider = new Slider({
            el: this.$('.Slider')
        });
        Page.prototype.initialize.call(this, options);
    },

    render: function () {
        this.slider.render();
    },

    activate: function (params) {
        this.slider.activate();

        return Page.prototype.activate.call(this, params);
    }
});
