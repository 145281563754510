var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/LegalMenuLeft/LegalMenuLeft.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/LegalMenuLeft/LegalMenuLeft.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n\n";
var macro_t_5 = runtime.makeMacro(
[], 
["classes", "subpage"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");frame.set("subpage", Object.prototype.hasOwnProperty.call(kwargs, "subpage") ? kwargs["subpage"] : "");var t_6 = "";t_6 += "\n    <nav class=\"LegalMenuLeft ";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_6 += "\">\n        <a class=\"LegalMenuLeft-item ";
if(runtime.contextOrFrameLookup(context, frame, "subpage") == "legal") {
t_6 += "isActive";
;
}
t_6 += "\" href=\"";
t_6 += runtime.suppressValue((lineno = 4, colno = 97, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-information"])), env.opts.autoescape);
t_6 += "\">";
t_6 += runtime.suppressValue((lineno = 4, colno = 133, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Стать поставщиком транспортных услуг"])), env.opts.autoescape);
t_6 += "</a>\n        <a class=\"LegalMenuLeft-item ";
if(runtime.contextOrFrameLookup(context, frame, "subpage") == "restaurants") {
t_6 += "isActive";
;
}
t_6 += "\" href=\"";
t_6 += runtime.suppressValue((lineno = 5, colno = 103, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-restaurants"])), env.opts.autoescape);
t_6 += "\">Партнерство для ресторанов и&nbsp;баров</a>\n        <a class=\"LegalMenuLeft-item ";
if(runtime.contextOrFrameLookup(context, frame, "subpage") == "retail") {
t_6 += "isActive";
;
}
t_6 += "\" href=\"";
t_6 += runtime.suppressValue((lineno = 6, colno = 98, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-retail"])), env.opts.autoescape);
t_6 += "\">Стать партнером в&nbsp;рознице/сетях</a>\n        <a class=\"LegalMenuLeft-item ";
if(runtime.contextOrFrameLookup(context, frame, "subpage") == "documents") {
t_6 += "isActive";
;
}
t_6 += "\" href=\"";
t_6 += runtime.suppressValue((lineno = 7, colno = 101, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-documents"])), env.opts.autoescape);
t_6 += "\">";
t_6 += runtime.suppressValue((lineno = 7, colno = 135, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Документы"])), env.opts.autoescape);
t_6 += "</a>\n        <a class=\"LegalMenuLeft-item\" href=\"/legal/declarations\" target=\"_blank\" rel=\"noopener\">";
t_6 += runtime.suppressValue((lineno = 8, colno = 100, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Декларации соответствия"])), env.opts.autoescape);
t_6 += "</a>\n    </nav>\n    ";
t_6 += runtime.suppressValue((lineno = 10, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
t_6 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_6);
});
context.addExport("LegalMenuLeft");
context.setVariable("LegalMenuLeft", macro_t_5);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/LegalMenuLeft/LegalMenuLeft.jinja"] , dependencies)