var _ = require('underscore');

var Page = require('front/pages/Page/Page');
var TopNav = require('front/components/TopNav/TopNav');
var BrandsList = require('front/components/BrandsList/BrandsList');

require('./Brands.less');

module.exports = Page.extend({
    template: require('./Brands.jinja'),

    el: '.Brands',

    title: window.gettext('Бренды'),

    initialize: function (options) {
        _.bindAll(this, '_backgroundParallax');

        this.topNav = new TopNav({
            el: this.$('.TopNav'),
            type: 'sticky'
        });

        var subCategory;
        if (app.data.subCategory) {
            subCategory = app.data.subCategory;
        } else {
            subCategory = {
                slug: 'all'
            };
        }

        this.brandsList = new BrandsList({
            el: this.$('.BrandsList'),
            data: {
                subCategory: subCategory,
                styles: app.data.activeStyles,
                category: app.data.category
            }
        });

        this.$background = $('.Brands-headMedia');

        this.backgroundParallax = _.throttle(this._backgroundParallax, 10);
        app.els.$window.on('scroll', this.backgroundParallax);

        this.title = window.gettext('Бренды') + ': ' + app.data.category.title;
        this.setTitle();
        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        this.topNav.activate();
        this.update();

        return Page.prototype.activate.call(this, params);
    },

    getQueryParams: function (qs) {
        qs = qs.split('+').join(' ');

        var params = {};
        var tokens;
        var re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }

        return params;
    },

    update: function () {
        var tagsArg = this.getQueryParams(window.location.search).styles || '';

        if (tagsArg) {

            var tagsId = _.map(tagsArg.split(','), function (id) {
                return parseInt(id, 10);
            });

            var tags = _.sortBy(app.data.styles, function (i) {
                return i.id;
            }).filter(function (tag) {
                return tagsId.indexOf(tag.id) !== -1;
            });

            this.brandsList.state.set('styles', tags);
        } else {
            this.brandsList.state.set('styles', []);
        }
    },

    _backgroundParallax: function () {
        var scrollTop = app.els.$window.scrollTop();
        scrollTop = scrollTop * 0.3;
        var backgroundTop = scrollTop.toFixed(2) + 'px';

        this.$background.css({
            transform: 'translate3d(0, ' + backgroundTop + ', 0)'
        });
    }
});
