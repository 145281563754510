var Backbone = require('backbone');
var template = require('./Input.jinja');
require('./Input.less');

module.exports = Backbone.View.extend({
    el: '.Input',

    template: template,

    events: {
        'click svg': 'focusInput'
    },

    initialize: function (options) {
        this.options = options || {};
    },

    focusInput: function (e) {
        e.preventDefault();

        var $input = $(e.currentTarget).next();
        $input.focus();
    }
});
