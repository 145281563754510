var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Grid/Grid.jinja"] = require( "front/components/Grid/Grid.jinja" );
dependencies["front/components/TopNav/TopNav.jinja"] = require( "front/components/TopNav/TopNav.jinja" );
dependencies["front/components/MenuLeft/MenuLeft.jinja"] = require( "front/components/MenuLeft/MenuLeft.jinja" );
dependencies["front/components/Slider/SliderVariant.jinja"] = require( "front/components/Slider/SliderVariant.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/About/About.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/About/About.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/About/About.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/About/About.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "svgSprite")) {
var t_10 = t_7.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_10);
output += "\n";
env.getTemplate("front/components/Grid/Grid.jinja", false, "assets/app/front/pages/About/About.jinja", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(Object.prototype.hasOwnProperty.call(t_11, "Grid")) {
var t_14 = t_11.Grid;
} else {
cb(new Error("cannot import 'Grid'")); return;
}
context.setVariable("Grid", t_14);
output += "\n";
env.getTemplate("front/components/TopNav/TopNav.jinja", false, "assets/app/front/pages/About/About.jinja", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
t_15.getExported(function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
if(Object.prototype.hasOwnProperty.call(t_15, "TopNav")) {
var t_18 = t_15.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_18);
output += "\n";
env.getTemplate("front/components/MenuLeft/MenuLeft.jinja", false, "assets/app/front/pages/About/About.jinja", false, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
t_19.getExported(function(t_21,t_19) {
if(t_21) { cb(t_21); return; }
if(Object.prototype.hasOwnProperty.call(t_19, "MenuLeft")) {
var t_22 = t_19.MenuLeft;
} else {
cb(new Error("cannot import 'MenuLeft'")); return;
}
context.setVariable("MenuLeft", t_22);
output += "\n";
env.getTemplate("front/components/Slider/SliderVariant.jinja", false, "assets/app/front/pages/About/About.jinja", false, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
t_23.getExported(function(t_25,t_23) {
if(t_25) { cb(t_25); return; }
if(Object.prototype.hasOwnProperty.call(t_23, "SliderVariant")) {
var t_26 = t_23.SliderVariant;
} else {
cb(new Error("cannot import 'SliderVariant'")); return;
}
context.setVariable("SliderVariant", t_26);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_28,t_27) {
if(t_28) { cb(t_28); return; }
output += t_27;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_30,t_29) {
if(t_30) { cb(t_30); return; }
output += t_29;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_32,t_31) {
if(t_32) { cb(t_32); return; }
output += t_31;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_34,t_33) {
if(t_34) { cb(t_34); return; }
output += t_33;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 8, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Компания"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 8, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["О нас"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 9, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Компания"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 9, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["О нас"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n";
output += runtime.suppressValue((lineno = 12, colno = 9, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--about","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path")})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 15;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"About\">\n    <div class=\"About-floatNav\"></div>\n    <div class=\"About-background\">\n        <img class=\"About-backgroundImage\" src=\"\" srcset=\"\" alt=\"\">\n    </div>\n    <div class=\"About-left\">\n        ";
output += runtime.suppressValue((lineno = 22, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "MenuLeft"), "MenuLeft", context, [runtime.makeKeywordArgs({"classes": "MenuLeft--about","subpage": "about","caller": (function (){var macro_t_35 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_36 = "";;
frame = frame.pop();
return new runtime.SafeString(t_36);
});
return macro_t_35;})()})])), env.opts.autoescape);
output += "\n    </div>\n    <div class=\"About-right\">\n        <div class=\"About-header\">\n            <div class=\"About-subtitle\">\n                <span class=\"About-subtitleStroke\"></span>\n                <div class=\"About-subtitleText\">";
output += runtime.suppressValue((lineno = 28, colno = 52, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["О нас"])), env.opts.autoescape);
output += "</div>\n            </div>\n            <div class=\"About-description\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "aboutPage")),"title"), env.opts.autoescape);
output += "\n            </div>\n            <div class=\"About-text\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "aboutPage")),"text"), env.opts.autoescape);
output += "\n            </div>\n        </div>\n        ";
if(runtime.contextOrFrameLookup(context, frame, "aboutSlides")) {
output += "<div class=\"About-slider\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"0\">\n            ";
output += runtime.suppressValue((lineno = 39, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SliderVariant"), "SliderVariant", context, [runtime.makeKeywordArgs({"slides": runtime.contextOrFrameLookup(context, frame, "aboutSlides"),"classes": "Slider--about"})])), env.opts.autoescape);
output += "\n        </div>";
;
}
output += "\n        <div class=\"About-production\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"0\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "aboutPage")),"production_title"), env.opts.autoescape);
output += "\n        </div>\n        <div class=\"About-productionDescription\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"0\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "aboutPage")),"production_text"), env.opts.autoescape);
output += "\n        </div>\n        <div class=\"About-logos\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"0\">\n            ";
frame = frame.push();
var t_39 = runtime.contextOrFrameLookup(context, frame, "aboutLogos");
if(t_39) {t_39 = runtime.fromIterator(t_39);
var t_38 = t_39.length;
for(var t_37=0; t_37 < t_39.length; t_37++) {
var t_40 = t_39[t_37];
frame.set("logo", t_40);
frame.set("loop.index", t_37 + 1);
frame.set("loop.index0", t_37);
frame.set("loop.revindex", t_38 - t_37);
frame.set("loop.revindex0", t_38 - t_37 - 1);
frame.set("loop.first", t_37 === 0);
frame.set("loop.last", t_37 === t_38 - 1);
frame.set("loop.length", t_38);
output += "\n            <div class=\"About-logosItem\">\n                <img class=\"About-logo\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_40),"img")),"w1024"), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_40),"img")),"w1024"), env.opts.autoescape);
output += " 1024w,\n                                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_40),"img")),"w1280"), env.opts.autoescape);
output += " 1280w,\n                                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_40),"img")),"w1440"), env.opts.autoescape);
output += " 1440w,\n                                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_40),"img")),"w1920"), env.opts.autoescape);
output += " 1920w\"\n                    sizes=\"calc((540 / 1440) * 100vw)\" alt=\"\">\n            </div>\n            ";
;
}
}
frame = frame.pop();
output += "\n        </div>\n        <div class=\"About-brands\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"0\">\n            <div class=\"About-brandsSection\">\n                <div class=\"About-brandsTitle\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "aboutPage")),"russian_brands_field"), env.opts.autoescape);
output += "\n                </div>\n                <div class=\"About-brandsList\">\n                    ";
frame = frame.push();
var t_43 = runtime.contextOrFrameLookup(context, frame, "russianBrands");
if(t_43) {t_43 = runtime.fromIterator(t_43);
var t_42 = t_43.length;
for(var t_41=0; t_41 < t_43.length; t_41++) {
var t_44 = t_43[t_41];
frame.set("item", t_44);
frame.set("loop.index", t_41 + 1);
frame.set("loop.index0", t_41);
frame.set("loop.revindex", t_42 - t_41);
frame.set("loop.revindex0", t_42 - t_41 - 1);
frame.set("loop.first", t_41 === 0);
frame.set("loop.last", t_41 === t_42 - 1);
frame.set("loop.length", t_42);
output += "\n                    ";
if(runtime.memberLookup((t_44),"link")) {
output += "<a class=\"About-brandsArrow\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_44),"link"), env.opts.autoescape);
output += "\">";
;
}
output += "\n                        <div class=\"About-brandsItem\">\n                            <div class=\"About-brandsHeader\">\n                                ";
output += runtime.suppressValue((runtime.memberLookup((t_44),"name")?runtime.memberLookup((t_44),"name"):""), env.opts.autoescape);
output += "\n                            </div>\n                            <div class=\"About-brandsDescription\">\n                                ";
output += runtime.suppressValue((runtime.memberLookup((t_44),"country")?runtime.memberLookup((t_44),"country"):""), env.opts.autoescape);
output += "\n                            </div>\n                            ";
if(runtime.memberLookup((t_44),"link")) {
output += " ";
output += runtime.suppressValue((lineno = 74, colno = 59, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["arrowInfo"])), env.opts.autoescape);
output += " ";
;
}
output += "\n                        </div>\n                        ";
if(runtime.memberLookup((t_44),"link")) {
output += " </a> ";
;
}
output += "\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n            </div>\n            <div class=\"About-brandsSection\">\n                <div class=\"About-brandsTitle\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "aboutPage")),"international_brands_field"), env.opts.autoescape);
output += "\n                </div>\n                <div class=\"About-brandsList\">\n                    ";
frame = frame.push();
var t_47 = runtime.contextOrFrameLookup(context, frame, "internationalBrands");
if(t_47) {t_47 = runtime.fromIterator(t_47);
var t_46 = t_47.length;
for(var t_45=0; t_45 < t_47.length; t_45++) {
var t_48 = t_47[t_45];
frame.set("item", t_48);
frame.set("loop.index", t_45 + 1);
frame.set("loop.index0", t_45);
frame.set("loop.revindex", t_46 - t_45);
frame.set("loop.revindex0", t_46 - t_45 - 1);
frame.set("loop.first", t_45 === 0);
frame.set("loop.last", t_45 === t_46 - 1);
frame.set("loop.length", t_46);
output += "\n                    ";
if(runtime.memberLookup((t_48),"link")) {
output += " <a class=\"About-brandsArrow\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_48),"link"), env.opts.autoescape);
output += "\"> ";
;
}
output += "\n                        <div class=\"About-brandsItem\">\n                            <div class=\"About-brandsHeader\">\n                                ";
output += runtime.suppressValue((runtime.memberLookup((t_48),"name")?runtime.memberLookup((t_48),"name"):""), env.opts.autoescape);
output += "\n                            </div>\n                            <div class=\"About-brandsDescription\">\n                                ";
output += runtime.suppressValue((runtime.memberLookup((t_48),"country")?runtime.memberLookup((t_48),"country"):""), env.opts.autoescape);
output += "\n                            </div>\n                            ";
if(runtime.memberLookup((t_48),"link")) {
output += " ";
output += runtime.suppressValue((lineno = 94, colno = 59, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["arrowInfo"])), env.opts.autoescape);
output += " ";
;
}
output += "\n                        </div>\n                        ";
if(runtime.memberLookup((t_48),"link")) {
output += " </a> ";
;
}
output += "\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n            </div>\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n";
output += "\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/About/About.jinja"] , dependencies)