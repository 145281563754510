var _ = require('underscore/underscore');
var waitForTransitionEnd = require('waitForTransitionEnd');

var Base = require('front/components/Base/Base');
var Utils = require('front/utils/Utils');

var SCROLL_DISTANCE_TO_FADE_HEADER = 300;

module.exports = Base.extend({
    // Тип страницы, используется для поиска активного элемента меню
    type: '',

    // Заголовок страницы
    title: undefined,

    initialize: function (options) {

        Base.prototype.initialize.call(this, options);

        _.bindAll(this, 'onScrollForAnimation', 'onResizeForAnimation', 'onFontsLoaded', 'onKeyDown');

        this.animationsList = [];
    },

    setTitle: function (title) {
        title = title !== undefined ? title : this.title;
        
        if (title !== undefined) {
            document.title = window.gettext('Московская Пивоваренная Компания') + (title ? $('<div> • ' + title + '</div>').text() : '');
        }
    },

    $parent: function () {
        return app.els.$content;
    },

    activate: function (params) {
        this.setTitle();

        $(window)
            .on('resize', this.onResizeForAnimation)
            .on('scroll', this.onScrollForAnimation)
            .on('keydown', this.onKeyDown);

        this.onResizeForAnimation();
        this.onScrollForAnimation();

        window.app.vent.on('fonts-loaded', this.onFontsLoaded);

        this.fontLoadSafeTimeout = setTimeout(this.onFontsLoaded, 1000);

        return Base.prototype.activate.call(this, params);
    },

    deactivate: function (params) {
        $(window)
            .off('resize', this.onResizeForAnimation)
            .off('scroll', this.onScrollForAnimation)
            .off('keydown', this.onKeyDown);

        clearTimeout(this.fontLoadSafeTimeout);

        window.app.vent.off('fonts-loaded', this.onFontsLoaded);

        return Base.prototype.deactivate.call(this, params);
    },

    playIn: function () {
        return new Promise(() => {
            setTimeout(
                function () {
                    this.$el.removeClass('beforeEnter');
                }.bind(this),
                5
            );
        });
    },

    playOut: function () {
        return Utils.createEmptyPromise();
        // return new Promise(
        //     function (resolve) {
        //         waitForTransitionEnd(
        //             this.$el,
        //             'transform',
        //             function () {
        //                 resolve();
        //             },
        //             600
        //         );

        //         $('.TopNav--fixed').addClass('isExiting');

        //         this.$el.addClass('isExiting');
        //         this.$el.css({
        //             position: 'fixed',
        //             top: -app.els.$window.scrollTop(),
        //             left: 0,
        //             right: 0
        //         });
        //         console.log('Page playOut promise resolving');
        //     }.bind(this)
        // );
    },

    onKeyDown: function (e) {
        if (e.keyCode === 27 && !!app.state.popup && !!this.closePopup) {
            this.closePopup();
        }
    },

    onFontsLoaded: function () {
        this.readyForAnimation = true;

        this.onResizeForAnimation && this.onResizeForAnimation();
    },


    onResizeForAnimation: function () {
        if (!this.readyForAnimation) return;

        this.updateAnimationsList();

        this.updateAnimationsMetrics();

        this.updateAnimationsState();
    },


    onScrollForAnimation: function () {
        if (!this.readyForAnimation) return;

        this.updateAnimationsState && this.updateAnimationsState();
    },


    //update list on animation block on page
    updateAnimationsList: function () {
        if (this.isSubSectionDummy) return;

        //update page or popup only if it is visible|active right now
        if ((this.isSection && app.state.popupShown) || (this.isSubSection && !app.state.popupShown)) return;

        this.$('[data-animation="1"]').each(function (ind, item) {
            var $item = $(item);

            if ($item.attr('data-animation-accounted') == 1) return;

            this.animationsList.push({
                $item: $item,
                tp: $item.attr('data-animation-type')
            });

            $item.attr('data-animation-accounted', 1);
        }.bind(this));
    },


    //calc all animations block position on page relative (to compute fast their visibility on scrolling)
    updateAnimationsMetrics: function () {
        if (this.isSubSectionDummy) return;

        //update page or popup only if it is visible|active right now
        if ((this.isSection && app.state.popupShown) || (this.isSubSection && !app.state.popupShown)) return;

        _.each(this.animationsList, function (block) {
            block.height = block.$item.height();
            block.top = block.$item.offset().top;
        }.bind(this));

        this.windowHeight = window.innerHeight;
        this.contentHeight = $('body').outerHeight(true);
    },


    //react animations on scroll (trigger or continuous)
    updateAnimationsState: function () {
        if (this.isSubSectionDummy) return;

        //update page or popup only if it is visible|active right now
        if ((this.isSection && app.state.popupShown) || (this.isSubSection && !app.state.popupShown)) return;

        var scrollTop = $(window).scrollTop();

        //if page is section instance, not subsection (popup)
        if (this.isSection) {
            var headerOpacity = 1 - Math.max(Math.min(scrollTop / SCROLL_DISTANCE_TO_FADE_HEADER, 1), 0);

            if (headerOpacity !== this.lastHeaderOpacity) {
                this.$('.Section-fixedCaption').css('opacity', headerOpacity);
            }

            this.lastHeaderOpacity = headerOpacity;
        }

        _.each(this.animationsList, function (block, ind) {
            if (block.triggered) return;

            var screenPosition = (scrollTop - (block.top - this.windowHeight)) / (block.height + this.windowHeight);

            if (screenPosition > 0.1) {
                block.$item.attr('data-animation-state', 'triggered');
                block.triggered = true;
            }
        }.bind(this));
    }
});
