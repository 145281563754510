//грамотный код ожидания transition end
//учитывает:
//-что событий окончания несколько с вендорными префиксами, навешивает только на один
//-что собыие баблится и может придти от чайлда, чего нам не надо
//-что событие может не выстрелить, тогда вызовет по таймеру безопасности
//-что для мультипл транзишенов нам надо отловить окончание тодько нашего транзишена, а не всех подряд свойств
module.exports = function ($obj, property, cb, safeTimeout) {
    //это код который рекомендует использовать сам модернизр
    var transEndEventNames = {
            transition: 'transitionend',
            WebkitTransition: 'webkitTransitionEnd',
            MozTransition: 'transitionend',
            OTransition: 'oTransitionEnd',
            msTransition: 'MSTransitionEnd'
        },
        getTransitionEndName = function () {
            if (!window.getComputedStyle || !document.documentElement) {
                return 'transitionend';
            }

            var styles = window.getComputedStyle(document.documentElement, '');

            for (var i in transEndEventNames) {
                if (styles[i] != undefined) {
                    return transEndEventNames[i];
                }
            }

            return 'transitionend';
        },
        transEndEventName = getTransitionEndName(),
        transitionEndCallback = function (e) {
            //проверяем что событие пришло непосредственно от того, на кого навешивали
            //transition end срабатываем в конце КАЖДОГО анимируемого свойства, а также для чайлдов элемента
            //если это мультипл событие то нам надо сомтреть какое именно свойство закончило анимироваться
            //и ждать только того которое нам нужно
            if (e) {
                var prop = e.originalEvent.propertyName.toLowerCase();
                if (!$(e.target).is($obj)) return;
                if (prop.indexOf(property) == -1) return;
            }

            //сбрасываем обработчики transition end их много может идти еще
            $obj.off(transEndEventName, transitionEndCallback);

            //сбрасываем таймер безопасности
            clearTimeout(transitionSafeTimeout);

            cb();
        },
        resetAllHandlers = function () {
            //сбрасываем обработчики transition end
            $obj.off(transEndEventName, transitionEndCallback);

            //сбрасываем таймер безопасности
            clearTimeout(transitionSafeTimeout);
        };

    //ожидаем событие окончания анимации, его надо навесить обязательно до applyTransform && applyTransition
    //навешивать на все события сразу (как делают многие в этих ваших энторнетах) не стоит
    //например на iOS выстреливают сразу два события webkitTransitionEnd и transitionend
    //а .on вызовется для обоих, и так хватает проблем с тем, что Transition End вызывается отдельно для каждого анимируемого свойства
    $obj.on(transEndEventName, transitionEndCallback);

    //таймер безопасности, на случай если transitionend вообще не выстрелит (есть условия при которых это происходит)
    var transitionSafeTimeout = setTimeout(
        transitionEndCallback,
        safeTimeout == undefined ? this.getTransitionDuration($obj[0], property) + 100 : safeTimeout
    );

    return resetAllHandlers;
};
