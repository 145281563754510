var _ = require('underscore');

module.exports = function (env) {
    var staticPath = '/static/';

    if (typeof window !== 'undefined') {
        staticPath = window.app.settings.staticUrl;
    }

    env.addFilter('hexToRGBA', function (color, opacity) {
        var red = parseInt(color.slice(1, 3), 16);
        var green = parseInt(color.slice(3, 5), 16);
        var blue = parseInt(color.slice(5, 7), 16);

        return 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + opacity + ')';
    });

    env.addFilter('map', function (items, properties) {
        return items.map(function (item) {
            return item[properties.attribute];
        });
    });

    env.addGlobal(
        'static',
        function (file) {
            return staticPath + file;
        },
        true
    );

    env.addGlobal(
        'url',
        function (name, params) {
            var params = params || {};

            return Urls[name].apply(null, params.args);
        },
        true
    );

    //multilang support on client
    env.addGlobal(
        '_',
        function (text) {
            return window.gettext(text);
        },
        true
    );

    env.addGlobal(
        'active',
        function (url, pattern) {
            var regExp = new RegExp(pattern);

            if (regExp.test(url)) {
                return 'isActive';
            }

            return '';
        },
        true
    );
};
