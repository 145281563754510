var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Button/Button.jinja"] = require( "front/components/Button/Button.jinja" );
dependencies["front/components/TopNav/TopNav.jinja"] = require( "front/components/TopNav/TopNav.jinja" );
dependencies["front/components/TagList/TagList.jinja"] = require( "front/components/TagList/TagList.jinja" );
dependencies["front/components/SectionBadge/SectionBadge.jinja"] = require( "front/components/SectionBadge/SectionBadge.jinja" );
dependencies["front/components/PostGrid/PostGrid.jinja"] = require( "front/components/PostGrid/PostGrid.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/PostList/PostList.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/PostList/PostList.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/PostList/PostList.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("front/components/Button/Button.jinja", false, "assets/app/front/pages/PostList/PostList.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "Button")) {
var t_11 = t_8.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_11);
output += "\n";
env.getTemplate("front/components/TopNav/TopNav.jinja", false, "assets/app/front/pages/PostList/PostList.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "TopNav")) {
var t_15 = t_12.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_15);
output += "\n";
env.getTemplate("front/components/TagList/TagList.jinja", false, "assets/app/front/pages/PostList/PostList.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "TagList")) {
var t_19 = t_16.TagList;
} else {
cb(new Error("cannot import 'TagList'")); return;
}
context.setVariable("TagList", t_19);
output += "\n";
env.getTemplate("front/components/SectionBadge/SectionBadge.jinja", false, "assets/app/front/pages/PostList/PostList.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "SectionBadge")) {
var t_23 = t_20.SectionBadge;
} else {
cb(new Error("cannot import 'SectionBadge'")); return;
}
context.setVariable("SectionBadge", t_23);
output += "\n";
env.getTemplate("front/components/PostGrid/PostGrid.jinja", false, "assets/app/front/pages/PostList/PostList.jinja", false, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
t_24.getExported(function(t_26,t_24) {
if(t_26) { cb(t_26); return; }
if(Object.prototype.hasOwnProperty.call(t_24, "PostGrid")) {
var t_27 = t_24.PostGrid;
} else {
cb(new Error("cannot import 'PostGrid'")); return;
}
context.setVariable("PostGrid", t_27);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
output += t_28;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_31,t_30) {
if(t_31) { cb(t_31); return; }
output += t_30;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
output += t_32;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_35,t_34) {
if(t_35) { cb(t_35); return; }
output += t_34;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_37,t_36) {
if(t_37) { cb(t_37); return; }
output += t_36;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("data"))(env, context, frame, runtime, function(t_39,t_38) {
if(t_39) { cb(t_39); return; }
output += t_38;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 9, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 10;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 10, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 12;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    ";
output += runtime.suppressValue((lineno = 13, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--posts","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path")})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 16;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "PostListPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 18;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"PostList\">\n        <div class=\"PostList-sectionHeader\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"0\">\n            <h1 class=\"PostList-header\">";
output += runtime.suppressValue((lineno = 21, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
output += "</h1>\n\n            <div class=\"PostList-sectionList\">\n                ";
frame = frame.push();
var t_42 = runtime.contextOrFrameLookup(context, frame, "sections");
if(t_42) {t_42 = runtime.fromIterator(t_42);
var t_41 = t_42.length;
for(var t_40=0; t_40 < t_42.length; t_40++) {
var t_43 = t_42[t_40];
frame.set("s", t_43);
frame.set("loop.index", t_40 + 1);
frame.set("loop.index0", t_40);
frame.set("loop.revindex", t_41 - t_40);
frame.set("loop.revindex0", t_41 - t_40 - 1);
frame.set("loop.first", t_40 === 0);
frame.set("loop.last", t_40 === t_41 - 1);
frame.set("loop.length", t_41);
output += "\n                    <a href=\"";
output += runtime.suppressValue((lineno = 25, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:section",runtime.makeKeywordArgs({"args": [runtime.memberLookup((t_43),"slug")]})])), env.opts.autoescape);
output += "\"\n                       data-section=\"";
output += runtime.suppressValue(runtime.memberLookup((t_43),"slug"), env.opts.autoescape);
output += "\"\n                       class=\"PostList-sectionItem u-Route ";
output += runtime.suppressValue((runtime.memberLookup((t_43),"slug") == runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "section")),"slug")?"isActive":""), env.opts.autoescape);
output += "\" >";
output += runtime.suppressValue(runtime.memberLookup((t_43),"title"), env.opts.autoescape);
output += "</a>\n                ";
;
}
}
frame = frame.pop();
output += "\n                <a href=\"";
output += runtime.suppressValue((lineno = 29, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:subscribe"])), env.opts.autoescape);
output += "\" class=\"PostList-sectionItem\">Наша рассылка</a>\n            </div>\n\n            <div class=\"PostList-socialList\">\n                <a class=\"PostList-socialItem PostList-socialItem--telegram\" href=\"https://t.me/mosbrew\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">";
output += runtime.suppressValue((lineno = 33, colno = 168, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["tg"])), env.opts.autoescape);
output += "</a>\n                <a class=\"PostList-socialItem PostList-socialItem--vk\" href=\"https://vk.com/territorija_mosbrew\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">";
output += runtime.suppressValue((lineno = 34, colno = 176, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["vk"])), env.opts.autoescape);
output += "</a>\n                <a class=\"PostList-socialItem PostList-socialItem--dzen\" href=\"https://dzen.ru/mosbrew\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">";
output += runtime.suppressValue((lineno = 35, colno = 167, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["dzen-white"])), env.opts.autoescape);
output += "</a>\n                ";
output += "\n            </div>\n        </div>\n\n        <div class=\"PostList-description";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "section")),"description")) {
output += " isActive";
;
}
output += "\">\n            ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "section")),"description")), env.opts.autoescape);
output += "\n        </div>\n\n        <div class=\"PostList-tags\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"200\">\n            ";
output += runtime.suppressValue((lineno = 49, colno = 22, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TagList"), "TagList", context, [runtime.makeKeywordArgs({"tags": runtime.contextOrFrameLookup(context, frame, "tags"),"activeTags": runtime.contextOrFrameLookup(context, frame, "activeTags")})])), env.opts.autoescape);
output += "\n        </div>\n\n        <div class=\"PostList-main\" ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "activeTags")) || runtime.contextOrFrameLookup(context, frame, "section")) {
output += "style=\"display: none;\"";
;
}
output += ">\n            <article class=\"PostList-favorite\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"400\">\n                ";
if(runtime.contextOrFrameLookup(context, frame, "favorite")) {
output += "\n                    <div class=\"PostList-favoriteMeta\">\n                        <div class=\"PostList-favoriteBadge\">\n                            ";
output += runtime.suppressValue((lineno = 57, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SectionBadge"), "SectionBadge", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"section")])), env.opts.autoescape);
output += "\n                        </div>\n                        <div class=\"PostList-favoriteDate\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"dateText"), env.opts.autoescape);
output += "</div>\n                        <a class=\"PostList-favoriteExternalLink\"\n                            href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"externalLink"), env.opts.autoescape);
output += "\"\n                            target=\"_blank\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"externalLinkTitle"), env.opts.autoescape);
output += "</a>\n                    </div>\n\n                    <a href=\"";
output += runtime.suppressValue((lineno = 65, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:post",runtime.makeKeywordArgs({"args": [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"id")]})])), env.opts.autoescape);
output += "\" class=\"PostList-favoriteContent u-Route\">\n                        <div class=\"PostList-favoriteTitle\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"title"), env.opts.autoescape);
output += "</div>\n                        <div class=\"PostList-favoriteSubtitle\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"subtitle"), env.opts.autoescape);
output += "</div>\n\n                        <div class=\"PostList-favoriteImg PostList-favoriteImg--align-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"imgAlign"), env.opts.autoescape);
output += "\">\n                            <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"img")),"w1024"), env.opts.autoescape);
output += "\"\n                                 srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"img")),"w640"), env.opts.autoescape);
output += " 640w,\n                                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"img")),"w768"), env.opts.autoescape);
output += " 768w,\n                                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"img")),"w1024"), env.opts.autoescape);
output += " 1024w,\n                                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"img")),"w1280"), env.opts.autoescape);
output += " 1280w,\n                                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"img")),"w1440"), env.opts.autoescape);
output += " 1440w\"\n                                 sizes=\"calc((912 / 1440) * 100vw)\"\n                                 alt=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "favorite")),"title"), env.opts.autoescape);
output += "\">\n                        </div>\n                    </a>\n                ";
;
}
output += "\n            </article>\n\n            <div class=\"PostList-news\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"400\">\n                <div class=\"PostList-newsHeader\">";
output += runtime.suppressValue((lineno = 84, colno = 53, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Новости"])), env.opts.autoescape);
output += "</div>\n                <div class=\"PostList-newsList\">\n                    ";
frame = frame.push();
var t_46 = runtime.contextOrFrameLookup(context, frame, "news");
if(t_46) {t_46 = runtime.fromIterator(t_46);
var t_45 = t_46.length;
for(var t_44=0; t_44 < t_46.length; t_44++) {
var t_47 = t_46[t_44];
frame.set("n", t_47);
frame.set("loop.index", t_44 + 1);
frame.set("loop.index0", t_44);
frame.set("loop.revindex", t_45 - t_44);
frame.set("loop.revindex0", t_45 - t_44 - 1);
frame.set("loop.first", t_44 === 0);
frame.set("loop.last", t_44 === t_45 - 1);
frame.set("loop.length", t_45);
output += "\n                        <article class=\"PostList-newsItem\">\n                            <a class=\"PostList-newsLink u-Route\" href=\"";
output += runtime.suppressValue((lineno = 88, colno = 77, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:post",runtime.makeKeywordArgs({"args": [runtime.memberLookup((t_47),"id")]})])), env.opts.autoescape);
output += "\">\n                                <div class=\"PostList-newsNumber\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "</div>\n                                <div class=\"PostList-newsContent\">\n                                    <div class=\"PostList-newsText\">\n                                        <div class=\"PostList-newsTitle\">";
output += runtime.suppressValue(runtime.memberLookup((t_47),"title"), env.opts.autoescape);
output += "</div>\n                                        <div class=\"PostList-newsDate\">";
output += runtime.suppressValue(runtime.memberLookup((t_47),"dateText"), env.opts.autoescape);
output += "</div>\n                                    </div>\n\n                                    <div class=\"PostList-newsImage PostList-newsImage--align-";
output += runtime.suppressValue(runtime.memberLookup((t_47),"imgAlign"), env.opts.autoescape);
output += "\">\n                                        <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_47),"preview")),"w88"), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_47),"preview")),"w176"), env.opts.autoescape);
output += " 2x\" alt=\"";
output += runtime.suppressValue(runtime.memberLookup((t_47),"title"), env.opts.autoescape);
output += "\">\n                                    </div>\n                                </div>\n                            </a>\n                        </article>\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n\n                ";
if(runtime.contextOrFrameLookup(context, frame, "newsMore")) {
output += "\n                    <div class=\"PostList-newsButton\">\n                        <a href=\"";
output += runtime.suppressValue((lineno = 107, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:section",runtime.makeKeywordArgs({"args": ["news"]})])), env.opts.autoescape);
output += "\" class=\"Button Button--small2 Button--grow u-Route\">";
output += runtime.suppressValue((lineno = 107, colno = 131, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Больше новостей"])), env.opts.autoescape);
output += "</a>\n                    </div>\n                ";
;
}
output += "\n            </div>\n        </div>\n\n        <div class=\"PostList-grid\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"0\">\n            ";
output += runtime.suppressValue((lineno = 114, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "PostGrid"), "PostGrid", context, [runtime.makeKeywordArgs({"posts": runtime.contextOrFrameLookup(context, frame, "posts")})])), env.opts.autoescape);
output += "\n        </div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_data(env, context, frame, runtime, cb) {
var lineno = 119;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "data", b_data, frame, runtime, function(t_48,hole_30) {
if(t_48) { cb(t_48); return; }
hole_30 = runtime.markSafe(hole_30);
output += "\n    ";
output += runtime.suppressValue(hole_30, env.opts.autoescape);
output += "\n\n    <script>\n        app.data.tags = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "tags"))), env.opts.autoescape);
output += ";\n        app.data.activeTags = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "activeTags"))), env.opts.autoescape);
output += ";\n        app.data.activeSection = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "section"))), env.opts.autoescape);
output += ";\n        app.data.sections = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "sections"))), env.opts.autoescape);
output += ";\n        app.data.hasMore = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "hasMore"))), env.opts.autoescape);
output += ";\n    </script>\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_body_class: b_body_class,
b_content: b_content,
b_data: b_data,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/PostList/PostList.jinja"] , dependencies)